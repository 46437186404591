import queryString from "query-string"

import {
  AudiobookResponse,
  AudiobookChapterResponse,
  MediaAudioSource,
  MediaVideoSource,
  PodcastEpisodeResponse,
  PodcastResponse,
  VideoResponse,
  VideoSeriesResponse,
  EbookResponse,
} from "@treefort/api-spec"
import { getAvailableData } from "@treefort/lib/availability"
import { EventEmitter } from "@treefort/lib/event-emitter"
import { getFileExtensionFromUrl } from "@treefort/lib/get-file-extension-from-url"
import { getOptimizedImageSource } from "@treefort/lib/get-optimized-image-source"
import {
  getBestMediaSourceForDownloadingAudio,
  getBestMediaSourceForDownloadingVideo,
} from "@treefort/lib/media"
import merge from "@treefort/lib/merge"
import { SettingStoreItem } from "@treefort/lib/settings"
import tokens from "@treefort/tokens/app"

import {
  getKeyFromConsumableContent,
  ConsumableContent,
} from "./consumable-content"
import manager, {
  Download,
  DownloadFailed,
  Event as ManagerEvent,
  EventMap as ManagerEventMap,
} from "./download-manager"
import { logError } from "./logging"
import { settingsStore } from "./settings"
import { Store } from "./store"

export type State =
  | { type: "notDownloaded" | "downloaded" }
  | { type: "partiallyDownloaded" | "downloading"; progress: number }
  | { type: "failed"; error: unknown }

export type Content =
  | AudiobookResponse
  | PodcastResponse
  | VideoResponse
  | VideoSeriesResponse

type Source = {
  uri: string
  key: string | undefined
  headers?: Record<string, string>
  query?: Record<string, string>
  type: "audio" | "video" | "ebook" | "image"
}

export enum Event {
  State = "STATE",
  WillDelete = "WILL_DELETE",
}

interface EventMap {
  [Event.State]: State
  [Event.WillDelete]: undefined
}

type Downloads = Record<string, Download>

type StoreItem = {
  consumableContent: ConsumableContent
  requestedAt?: number
}

type StoreData = Record<string, StoreItem>

const store = new Store({
  key: "downloadItem",
  migrations: [
    {
      name: "seedFromSettings",
      migrate: async (store) => {
        const data = await settingsStore.get<SettingStoreItem<unknown>>(
          DownloadItem.STORE_KEY,
        )
        if (data) {
          await store.set(DownloadItem.STORE_KEY, data)
          await settingsStore.remove(DownloadItem.STORE_KEY)
        }
      },
    },
  ],
})

const getDownloadableArtworkUrlForConsumableContent = (
  consumableContent: ConsumableContent,
): string | undefined =>
  getOptimizedImageSource(
    consumableContent.content.artworkMedia?.original.url,
    tokens.audioPlayerFullscreen.maxWidth,
  )

function getDownloadableMediaForAudiobookChapter(
  chapter: AudiobookChapterResponse,
): MediaAudioSource | undefined {
  const media = getAvailableData(chapter.audioMedia)
  const source = media && getBestMediaSourceForDownloadingAudio(media)

  if (!media || !source) {
    return undefined
  }

  switch (media.type) {
    case "audioFile":
      return source
    default:
      return undefined
  }
}

function getDownloadableMediaForEbook(ebook: EbookResponse):
  | {
      url: string
      headers?: Record<string, string>
      query?: Record<string, string>
    }
  | undefined {
  const media = getAvailableData(ebook.details.ebookMedia)

  if (!media) {
    return undefined
  }

  switch (media.type) {
    case "epub":
      return {
        url: media.url,
        headers: media.headers,
        query: media.query,
      }
    default:
      return undefined
  }
}

function getDownloadableMediaForPodcastEpisode(
  podcastEpisode: PodcastEpisodeResponse,
): { url: string } | undefined {
  const media = getAvailableData(podcastEpisode.audioMedia)

  if (!media) {
    return undefined
  }

  switch (media.type) {
    case "url":
      return { url: media.url }
    default:
      return undefined
  }
}

function getDownloadableMediaForVideo(
  video: VideoResponse,
): MediaVideoSource | undefined {
  const media = getAvailableData(video.details.videoMedia)
  const source = media && getBestMediaSourceForDownloadingVideo(media)

  if (!media || !source) {
    return undefined
  }

  switch (media.type) {
    case "videoFile":
      return source
    default:
      return undefined
  }
}

function isFileUri(url: string) {
  return url.startsWith("file://")
}

/**
 * This class builds on the low-level DownloadManager to make it easier to
 * download content and manage existing downloads.
 */
export default class DownloadItem<
  T extends ConsumableContent = ConsumableContent,
> extends EventEmitter<EventMap> {
  static VERSION = 6
  static STORE_KEY = `downloadItem.${DownloadItem.VERSION}`

  private consumableContent: T
  private requestedAt?: number
  private sources: Source[] = []
  private listeners: Array<() => void> = []
  private downloads?: Downloads
  private initialized: Promise<void>
  // When we start deleting all files for a DownloadItem we make note of that
  // here. Doing this allows us to optimistically show the final deleted state
  // instead of showing the download item's progress move in reverse until all
  // files are gone (which is technically what happens, but looks pretty awkward
  // in the UI).
  private deleting = false

  /**
   * Fetch all download items out of the store. This list includes any download
   * item for which file downloads were requested. It is not guaranteed that
   * file downloads will be complete for all items in this list. If a download
   * item is in the "partiallyDownloaded" or "failed" state it will still be
   * included in this list until the deleteDownload method is called on it.
   */
  static getAll = async (): Promise<DownloadItem[]> => {
    const items = await store.get<StoreData>(DownloadItem.STORE_KEY)
    return items
      ? Object.values(items).map((item) => {
          const migrated = migrateStoreItem(item)
          return new DownloadItem(item, !migrated)
        })
      : []
  }

  /**
   * Delete all download metadata and associated files. This calls
   * DownloadManager.deleteAllDownloads(), so this will also delete any orphaned
   * download files or fragments that we may have lost track of due to error.
   */
  static deleteAll = async (): Promise<void> => {
    await manager.deleteAllDownloads()
    await store.remove(DownloadItem.STORE_KEY)
  }

  /**
   * If a download was attempted for a piece of content then this will return
   * the associated DownloadItem. Otherwise this will return undefined.
   */
  static fromContentId = async (contentId: number) => {
    const items = await store.get<StoreData>(DownloadItem.STORE_KEY)
    return items
      ? Object.values(items).flatMap((item) => {
          migrateStoreItem(item)
          return item.consumableContent.content.id === contentId
            ? new DownloadItem(item, true)
            : []
        })[0]
      : undefined
  }

  /**
   * Instantiate a download item for a piece of content
   */
  constructor(
    {
      consumableContent,
      requestedAt,
    }: {
      consumableContent: T
      requestedAt?: number
    },
    // For internal use. This is set to true when the DownloadItem is extracted
    // from the store via getAll. This flag prevents the DownloadItem
    // from updating the store when being initialized with data from the store
    // (which would be a pointless waste of resources).
    fromStore?: boolean,
  ) {
    super()
    this.requestedAt = requestedAt
    this.consumableContent = consumableContent

    this.initialized = this.initialize()

    // Keep our cached content up-to-date with the latest content passed to us.
    // This could result in a broken download, but that's the desired effect.
    // For example, if an admin adds a chapter to an audiobook that the user has
    // already downloaded then we want the download to show in an error state
    // until the user re-downloads and gets the new chapter.
    if (!fromStore) {
      this.updateInStore().catch(logError)
    }
  }

  private initialize = async () => {
    // Extract media URLs to download
    switch (this.consumableContent.type) {
      case "podcastEpisode": {
        const media = getDownloadableMediaForPodcastEpisode(
          this.consumableContent.podcastEpisode,
        )
        if (media) {
          this.sources.push({
            type: "audio",
            uri: media.url,
            key: await this.getKeyFromUri(media.url),
          })
        }
        break
      }
      case "book": {
        await Promise.all(
          this.consumableContent.content.details.chapters.map(
            async (chapter) => {
              const media = getDownloadableMediaForAudiobookChapter(chapter)
              if (media) {
                this.sources.push({
                  type: "audio",
                  uri: media.url,
                  key: await this.getKeyFromUri(media.url),
                  headers: media.headers,
                  query: media.query,
                })
              }
            },
          ),
        )
        break
      }
      case "ebook": {
        const media = getDownloadableMediaForEbook(
          this.consumableContent.content,
        )
        if (media) {
          this.sources.push({
            type: "ebook",
            uri: media.url,
            key: await this.getKeyFromUri(media.url),
            headers: media.headers,
            query: media.query,
          })
        }
        break
      }
      case "video": {
        const media = getDownloadableMediaForVideo(
          this.consumableContent.content,
        )
        if (media) {
          this.sources.push({
            type: "video",
            uri: media.url,
            key: await this.getKeyFromUri(media.url),
            headers: media.headers,
            query: media.query,
          })
        }

        break
      }
    }

    // Get an optimized artwork URL. Limit the size of the file to the maximum
    // size it will be displayed in the fullscreen audio player. Feels a bit odd
    // to reference UI dimensions in here, but this will fetch the optimal
    // image.
    const artworkUrl = getDownloadableArtworkUrlForConsumableContent(
      this.consumableContent,
    )
    if (artworkUrl) {
      this.sources.push({
        type: "image",
        uri: artworkUrl,
        key: await this.getKeyFromUri(artworkUrl),
      })
    }
  }

  /**
   * Get an object of all downloads that have been requested for this item.
   */
  private getRequestedDownloads = async (): Promise<Downloads> => {
    await this.initialized
    // If we've got a list of cached downloads (because `enableEvents` has been
    // called) then return it. This is more efficient that extracting the entire
    // list of downloads out of the store.
    if (this.downloads) {
      return this.downloads
    }
    // If we don't have a list of cached downloads (because `enableEvents` has
    // not been called or has been cleaned up) then ask the download manager to
    // extract the list out of the store.
    else {
      const sourcesWithKeys = this.sources.filter(
        (source): source is Source & { key: string } => Boolean(source.key),
      )
      const downloads = await manager.getDownloads(
        sourcesWithKeys.map((source) => source.key),
      )
      return Object.fromEntries(
        sourcesWithKeys.flatMap((source, index) => {
          const download = downloads[index]
          return download ? [[source.key, download]] : []
        }),
      )
    }
  }

  /**
   * Pass this an object containing all requested downloads for the download
   * item (e.g. the result of `getRequestedDownloads`) and the return value will
   * be an array of all the downloads for the item's urls. The download at each
   * index in the returned array will correspond to the URL at the same index in
   * the `urls` array. If no download exists for a particular URL then the
   * element at that index will be undefined.
   */
  private getAllDownloads = (
    downloads: Downloads,
  ): Array<Download | undefined> =>
    this.sources.map(({ key }) => (key ? downloads[key] : undefined))

  /**
   * Get the overall progress for all of the media downloads.
   *
   * NOTE: This gives each download in the list equal weight. For example, when
   * downloading a 10MB file and a 100MB file the aggregate progress would be
   * 50% complete if the 10MB file is finished and the 100MB has not started.
   * This is obviously not ideal, but it's good enough for our purposes (to
   * display a download progress indicator). To get the true progress the size
   * of _all_ downloads in the list would have to be known up front and that's
   * not information that we can easily/efficiently obtain. Artwork is excluded
   * from this calculation because its size is likely to be insignificant
   * compared to the size of the media and would throw off our rough
   * estimatation of progress (e.g. instead of 10MB vs 100MB you could have
   * 0.1MB vs 100MB).
   */
  private getDownloadProgress = (downloads: Downloads): number => {
    // Filter out images from the progress calculation (see comment above).
    const nonImageDownloads = this.getAllDownloads(downloads).filter(
      (_download, index) => this.sources[index].type !== "image",
    )

    const totalCount = nonImageDownloads.length
    const totalProgress = nonImageDownloads.reduce(
      (result, download) =>
        result +
        (download?.state === "successful"
          ? 1
          : download?.state === "downloading" ||
              download?.state === "queued" ||
              download?.state === "paused"
            ? download.progress
            : 0),
      0,
    )

    return totalCount > 0 ? totalProgress / totalCount : 0
  }

  /**
   * Get the overall state of the download item from a list of all the downloads
   * associated with the item (i.e. the result of `getRequestedDownloads`).
   */
  private getStateFromDownloads = (downloads: Downloads): State => {
    const allDownloads = this.getAllDownloads(downloads)

    // If any individual download failed
    const failedDownload = allDownloads.find(
      (d): d is DownloadFailed => d?.state === "failed",
    )
    if (failedDownload) {
      return { type: "failed", error: failedDownload.error }
    }

    // If all sources that can be downloaded are
    if (
      allDownloads.length &&
      allDownloads.every((d) => d?.state === "successful")
    ) {
      return { type: "downloaded" }
    }

    // If any downloads are in progress
    if (
      allDownloads.some(
        (d) => d?.state === "queued" || d?.state === "downloading",
      )
    ) {
      const progress = this.getDownloadProgress(downloads)
      return { type: "downloading", progress }
    }

    // If any downloads are at least partially downloaded
    if (
      allDownloads.some(
        (d) => d?.state === "successful" || d?.state === "paused",
      )
    ) {
      const progress = this.getDownloadProgress(downloads)
      return { type: "partiallyDownloaded", progress }
    }

    // If nothing was even requested
    return { type: "notDownloaded" }
  }

  /**
   * This binds an event listener to the manager that will only be called if
   * the event from the manager is relevant to this DownloadItem (i.e. the event
   * is associated with a download that we're tracking). An unsubscribe function
   * is returned.
   */
  private addEventListenerToManager = <T extends ManagerEvent>(
    event: T,
    handler: (event: ManagerEventMap[T]) => unknown,
  ): (() => void) =>
    manager.on(event, (event) => {
      if (this.sources.some(({ key }) => key === event.key)) {
        handler(event)
      }
    })

  /**
   * If we detect that we're starting to delete the downloads associated with
   * this item then we optimistically report a "notDownloaded" state and ignore
   * any future "willDelete" events (to avoid performance issues when deleting
   * many files very rapidly).
   */
  private handleManagerWillDeleteEvent = async (): Promise<void> => {
    if (!this.deleting) {
      this.deleting = true
      await this.emitter.emit(Event.WillDelete)
      this.emitter.emit(Event.State, { type: "notDownloaded" })
    }
  }

  /**
   * Remove downloads from our internal list in response to "deleted" events.
   * Don't bother emitting events for individual deletions - see the comment on
   * the DownloadItem.deleted private property.
   */
  private handleManagerDeletedEvent = (
    event: ManagerEventMap[ManagerEvent.DownloadDeleted],
  ): void => {
    // Remove the download from our list
    delete this.downloads?.[event.key]
    // Clear this.deleting once we're finished deleting
    if (
      this.deleting &&
      (!this.downloads || !Object.keys(this.downloads).length)
    ) {
      this.deleting = false
    }
  }

  /**
   * This processes events from the download manager, updates the download
   * item's cached downloads list, and emits a `State` event if the state
   * derived from that list of downloads has changed.
   */
  private handleManagerDownloadEvents = async (
    event:
      | ManagerEventMap[ManagerEvent.DownloadSuccess]
      | ManagerEventMap[ManagerEvent.DownloadProgress]
      | ManagerEventMap[ManagerEvent.DownloadFailure],
  ): Promise<void> => {
    const downloads = this.downloads

    // Bail if we don't have any downloads to track
    if (downloads === undefined) return

    // Bail if we're in the process of deleting all downloads
    if (this.deleting) return

    // Queued events can come fast and furious if we're downloading lots of
    // tracks in one go. To avoid performance problems we skip all of the logic
    // below for queued events and simply log the downloads in our cache.
    if (event.state === "queued") {
      downloads[event.key] = event
      return
    }

    // Calculate the previous state, upate our list of downloads, and calculate
    // the next state
    const prevState = this.getStateFromDownloads(downloads)
    downloads[event.key] = event
    const nextState = this.getStateFromDownloads(downloads)

    // Check if the state has changed
    if (
      prevState.type !== nextState.type ||
      ("progress" in nextState &&
        "progress" in prevState &&
        nextState.progress !== prevState.progress)
    ) {
      // Pause everything if we get an error on any individual download.
      // There's no point in continuing to use up bandwidth for a lost cause.
      if (nextState.type === "failed") {
        this.pauseDownload().catch(logError)
      }

      // Emit a state update
      this.emitter.emit(Event.State, nextState)
    }
  }

  /**
   * This adds data to the store that can be used to re-initialize the download
   * item later. This does not trigger any file downloads - see the
   * requestDownload method for that. This is safe to call more than once - the
   * item will only be included in the store once.
   */
  private addToStore = (): Promise<void> =>
    store.update<StoreData>(DownloadItem.STORE_KEY, (items) => ({
      ...items,
      [this.getKey()]: {
        consumableContent: this.consumableContent,
        requestedAt: this.requestedAt,
      },
    }))

  /**
   * This removes the download item's data from the store. This does not delete
   * any file downloads - see the deleteDownload method for that.
   */
  private removeFromStore = (): Promise<void> =>
    store.update<StoreData>(DownloadItem.STORE_KEY, (items) => {
      if (items) {
        delete items[this.getKey()]
        return items
      }
    })

  /**
   * This updates the download item's entry in the store, but only if it is
   * already saved there.
   */
  private updateInStore = (): Promise<void> =>
    store.update<StoreData>(DownloadItem.STORE_KEY, (items) => {
      const key = this.getKey()
      if (items && key in items) {
        items[key] = {
          consumableContent: this.consumableContent,
          requestedAt: this.requestedAt || items[key].requestedAt,
        }
        return items
      }
    })

  /**
   * Take's in a media item's URL and returns the download for it (if one has
   * been requested).
   */
  private getDownloadFromUri = async (
    url: string | undefined,
    downloads: Downloads,
  ) => {
    if (url === undefined) return undefined
    const key = await this.getKeyFromUri(url)
    return key ? downloads[key] : undefined
  }

  /**
   * Returns a unique key for a download given given a URI. If the URI points to
   * a file then the download manager is queried for the key. Otherwise we
   * generate a deterministic key from the URI. Note that the key may be
   * undefined if we were given a file URI that the download manager doesn't
   * know about (unlikely but theoretically possible).
   */
  private getKeyFromUri = (uri: string) => {
    if (isFileUri(uri)) {
      return manager.getDownloadKey(uri)
    } else {
      // Generate a key that is unique to this DownloadItem instance. This
      // ensures that even if the same URL is shared between multiple items
      // (e.g. shared artwork or maybe an outro chapter added to multiple
      // audiobooks) downloading or deleting URLs for one item will not affect
      // other items. The downside to qualifying URLs per download item is that
      // we could end up downloading the same URLs multiple times, but that's
      // unlikely to account for a significant portion of downloads.
      return queryString.stringifyUrl({
        url: uri,
        query: { treefortDownload: this.getKey() },
      })
    }
  }

  /**
   * Get a key for the download item that is unique to the content the item
   * holds.
   */
  getKey = (): string => getKeyFromConsumableContent(this.consumableContent)

  /**
   * Returns the original playable content that the download item was
   * instantiated with.
   */
  getConsumableContent = (): T => this.consumableContent

  /**
   * Returns the timestamp (in milliseconds) when the download was _first_
   * requested. Will be undefined if the download was never requested.
   */
  getRequestedAt = (): number | undefined => this.requestedAt

  /**
   * Call this to start listening to download manager events and enable the
   * download item's event emitter. Once this is called the download item will
   * emit events related to download state changes. A cleanup function is
   * returned that should be called when this functionality is no longer
   * needed/wanted (e.g. when a React component listening to the download item's
   * events is being unmounted).
   *
   * NOTE: Calling this will enable events for all listeners attached to the
   * same DownloadItem instance.
   */
  enableEvents = (): (() => void) => {
    // Start listening to the download manager if we aren't already
    if (this.listeners.length === 0) {
      this.listeners = [
        this.addEventListenerToManager(
          ManagerEvent.DownloadWillDelete,
          this.handleManagerWillDeleteEvent,
        ),
        this.addEventListenerToManager(
          ManagerEvent.DownloadDeleted,
          this.handleManagerDeletedEvent,
        ),
        this.addEventListenerToManager(
          ManagerEvent.DownloadProgress,
          this.handleManagerDownloadEvents,
        ),
        this.addEventListenerToManager(
          ManagerEvent.DownloadSuccess,
          this.handleManagerDownloadEvents,
        ),
        this.addEventListenerToManager(
          ManagerEvent.DownloadFailure,
          this.handleManagerDownloadEvents,
        ),
      ]

      // Fetch downloads from the manager to initialize our cached downloads
      // array. Do this after we've bound events to make sure we don't miss
      // anything.
      this.getRequestedDownloads().then((downloads) => {
        // Make sure that events are still enabled
        if (this.listeners.length > 0) {
          this.downloads = downloads
        }
      })
    }

    // Return a cleanup function
    return this.disableEvents
  }

  /**
   * Turn off event emitting from the DownloadItem.
   *
   * NOTE: Calling this will disable events for all listeners attached to the
   * same DownloadItem instance.
   */
  disableEvents = (): void => {
    this.listeners.map((cleanup) => cleanup())
    this.listeners = []
    this.downloads = undefined
  }

  /**
   * This downloads all files needed to access the download item's content
   * offline. This should be called to initiate a new download, retry a failed
   * download, or resume a paused/partial download. After calling this the
   * download item will be persisted to the store. Without calling this a
   * download item instance will not be included in the list returned by
   * DownloadItem.getAll().
   */
  requestDownload = async (options?: {
    allowOverCellular?: boolean
  }): Promise<void> => {
    await this.initialized
    if (this.sources.length === 0) {
      throw new Error(
        `Couldn't find anything to download for content ${this.consumableContent.content.id}`,
      )
    }
    this.requestedAt = Date.now()
    await this.addToStore()
    await Promise.all(
      this.sources.map(({ uri, key, headers, query }) => {
        if (!isFileUri(uri) && key) {
          return manager.requestDownload(uri, {
            key,
            allowOverCellular: options?.allowOverCellular,
            headers,
            query,
          })
        }
      }),
    )
  }

  /**
   * This delete's the download item's data from the store as well as all
   * downloaded files that are referenced exclusively by this download item. Any
   * files that are referenced by other download items as well as this download
   * item will be left on disk.
   */
  deleteDownload = async (): Promise<void> => {
    await this.initialized
    try {
      await Promise.all(
        this.sources.map(({ key }) =>
          key ? manager.deleteDownload(key) : undefined,
        ),
      )
    } catch (_) {
      // Failure here is fine... most likely caused by the file being gone
      // already
    }
    await this.removeFromStore()
  }

  /**
   * This pauses all current downloads.
   */
  pauseDownload = async (): Promise<void> => {
    await this.initialized
    await Promise.all(
      this.sources.map(({ key }) =>
        key ? manager.pauseDownload(key) : undefined,
      ),
    )
  }

  /**
   * This returns the original playable content that was passed to the download
   * item, but with all media URLs replaced with their corresponding offline
   * URIs. This method does not care about the state of the download item. If
   * any URLs within the content have not been downloaded then they simply won't
   * be replaced.
   */
  getOfflineConsumableContent = async (): Promise<T> => {
    await this.initialized

    // Clone this.consumableContent so it's safe to modify below
    const consumableContent = merge(true, this.consumableContent)
    const downloads = await this.getRequestedDownloads()

    // Use offline artwork if it's downloaded
    const artworkMedia = consumableContent.content.artworkMedia
    if (artworkMedia) {
      const artworkDownload = await this.getDownloadFromUri(
        getDownloadableArtworkUrlForConsumableContent(consumableContent),
        downloads,
      )
      if (artworkDownload?.state === "successful") {
        artworkMedia.original.url = artworkDownload.data.fileUri
      }
    }

    // Use offline media if it's downloaded
    switch (consumableContent.type) {
      case "podcastEpisode": {
        const audioMedia = getAvailableData(
          consumableContent.podcastEpisode.audioMedia,
        )
        if (audioMedia) {
          const media = getDownloadableMediaForPodcastEpisode(
            consumableContent.podcastEpisode,
          )
          const download = await this.getDownloadFromUri(media?.url, downloads)
          if (download?.state === "successful") {
            audioMedia.url = download.data.fileUri
          }
        }
        break
      }
      case "video": {
        const videoMedia = getAvailableData(
          consumableContent.content.details.videoMedia,
        )
        if (videoMedia) {
          const media = getDownloadableMediaForVideo(consumableContent.content)
          const download = await this.getDownloadFromUri(media?.url, downloads)
          if (media && download?.state === "successful") {
            const { fileUri } = download.data
            videoMedia.processed ||= []
            videoMedia.processed.push({
              ...media,
              url: fileUri,
              headers: undefined,
              query: undefined,
            })
          }
        }
        break
      }
      case "book": {
        await Promise.all(
          consumableContent.content.details.chapters.map(async (chapter) => {
            const audioMedia = getAvailableData(chapter.audioMedia)
            if (audioMedia) {
              const media = getDownloadableMediaForAudiobookChapter(chapter)
              const download = await this.getDownloadFromUri(
                media?.url,
                downloads,
              )
              if (media && download?.state === "successful") {
                const { fileUri } = download.data
                audioMedia.processed ||= []
                audioMedia.processed.push({
                  ...media,
                  url: fileUri,
                  headers: undefined,
                  query: undefined,
                })
              }
            }
          }),
        )
        break
      }
      case "ebook": {
        const ebookMedia = getAvailableData(
          consumableContent.content.details.ebookMedia,
        )
        if (ebookMedia) {
          const media = getDownloadableMediaForEbook(consumableContent.content)
          const download = await this.getDownloadFromUri(media?.url, downloads)
          if (download?.state === "successful") {
            ebookMedia.url = download.data.fileUri
            ebookMedia.headers = undefined
            ebookMedia.query = undefined
          }
        }
      }
    }

    return consumableContent
  }

  /**
   * This returns the current state of the download item. To subscribe to state
   * updates, instead of using this function call `enableEvents` and subscribe
   * to the `State` event.
   */
  getState = async (): Promise<State> => {
    await this.initialized
    const downloads = await this.getRequestedDownloads()
    return this.getStateFromDownloads(downloads)
  }
}

/**
 * This migrates deprecated store items to the latest and greatest format.
 *
 * If any migration changes are necessary then this mutates the object passed in
 * and returns true, otherwise the object is left untouched and false is
 * returned.
 */
const migrateStoreItem = (
  item: StoreItem & { playableContent?: ConsumableContent },
): boolean => {
  let migrated = false

  // Move playableContent to consumableContent
  if (item.playableContent) {
    item.consumableContent = item.playableContent
    delete item.playableContent
    migrated = true
  }

  type WithDeprecatedArtwork = { artwork?: string }
  // Update artwork
  const deprecatedArtworkUrl = (
    item.consumableContent.content as WithDeprecatedArtwork
  ).artwork
  if (
    deprecatedArtworkUrl &&
    (!item.consumableContent.content.artworkMedia ||
      !item.consumableContent.content.artworkMedia.original)
  ) {
    item.consumableContent.content.artworkMedia = {
      type: "imageFile",
      original: {
        url: deprecatedArtworkUrl,
        format:
          // These legacy images were all either pngs or jpegs, so the
          // assumption in this conditional is safe
          getFileExtensionFromUrl(deprecatedArtworkUrl) === "png"
            ? "png"
            : "jpeg",
      },
    }
    migrated = true
  }

  switch (item.consumableContent.type) {
    // Update audiobook chapter audio
    case "book": {
      type WithDeprecatedFields = {
        audioDuration?: number
        restrictedAudioUrl?: string
      }
      item.consumableContent.content.details.chapters.forEach((chapter) => {
        const { restrictedAudioUrl: url, audioDuration } =
          chapter as WithDeprecatedFields
        if (url && audioDuration && !chapter.audioMedia) {
          chapter.audioMedia = {
            status: "available",
            data: {
              type: "audioFile",
              original: { url, format: "mp3", duration: audioDuration },
            },
          }
          migrated = true
        }
      })
      break
    }
    // Update podcast episode audio
    case "podcastEpisode": {
      type WithDeprecatedFields = { restrictedAudioUrl?: string }
      const url = (
        item.consumableContent.podcastEpisode as WithDeprecatedFields
      ).restrictedAudioUrl
      if (url && !item.consumableContent.podcastEpisode.audioMedia) {
        item.consumableContent.podcastEpisode.audioMedia = {
          status: "available",
          data: { type: "url", url },
        }
        migrated = true
      }
      break
    }
  }

  // Make sure the availability field is set
  if (!item.consumableContent.content.availability) {
    item.consumableContent.content.availability = { status: "available" }
    migrated = true
  }

  return migrated
}
