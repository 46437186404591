import React, { useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { Platform, View } from "react-native"

import styled from "styled-components/native"

import { formatMoney } from "@treefort/lib/money"
import icons from "@treefort/tokens/app/icons"

import { useCheckoutSession } from "../hooks/use-checkout-session"
import { Event, checkoutSessionManager } from "../lib/checkout"
import { SignUpOption } from "../lib/sign-up-options"
import {
  formatPlanIntervalLong,
  formatPlanIntervalShort,
} from "../lib/subscription-plans"
import { getTextStyleObject } from "../lib/text-style"
import ButtonView from "./button-view"
import Column from "./column"
import Icon from "./icon"
import Row from "./row"
import Spacer from "./spacer"
import Text from "./text"
import { DisplayMode, useTokens } from "./tokens-provider"
import Touchable from "./touchable"

type Layout = "narrow" | "wide"

export const SIGN_UP_CARD_MAX_WIDTH_PX = 360

const BADGE_HEIGHT_PX = 24

const LINE_ICON_SIZE = "mediumsmall"

const Container = styled.View<{ layout: Layout }>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  max-width: 100%;
  width: ${SIGN_UP_CARD_MAX_WIDTH_PX}px;
`

const Card = styled.View<{
  highlighted?: boolean
  displayMode: DisplayMode
}>`
  ${({ theme, displayMode }) =>
    displayMode === "dark"
      ? ""
      : Platform.OS === "android"
        ? // Box shadows don't work on android so we have to use the elevation api
          // instead
          `elevation: 18; shadow-color: ${theme.colors.gray500};`
        : `box-shadow: ${theme.planCard.card.boxShadow};`}
  overflow: visible;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  max-width: 100%;
  width: ${SIGN_UP_CARD_MAX_WIDTH_PX}px;
  background-color: ${({ theme }) => theme.planCard.card.backgroundColor};
  border-radius: ${({ theme }) => theme.planCard.card.borderRadius}px;
  padding: ${({ theme, highlighted }) =>
    theme.planCard.card.padding -
    theme.planCard.card.borderWidth[highlighted ? "highlighted" : "default"]}px;
  border: ${({ highlighted, theme }) =>
    `${
      theme.planCard.card.borderWidth[highlighted ? "highlighted" : "default"]
    }px solid ${
      theme.planCard.card.borderColor[highlighted ? "highlighted" : "default"]
    }`};
`

const Title = styled(Text)`
  flex: 1;
`

const BenefitDescription = styled(Text)`
  flex: 1;
  margin-top: ${({ theme, textStyle }) =>
    // Offset text so that its centered relative to the check icon
    (theme.icon.size[LINE_ICON_SIZE] -
      getTextStyleObject(textStyle, theme).lineHeight) /
    2}px;
`

const BadgeContainer = styled.View<{ layout: Layout }>`
  position: absolute;
  z-index: 1;
  top: -${BADGE_HEIGHT_PX / 2}px;
  right: ${({ theme, layout }) =>
    theme.planCard.card.padding +
    theme.planCard.cardContent.paddingHorizontal[layout]}px;
  left: ${({ theme, layout }) =>
    theme.planCard.card.padding +
    theme.planCard.cardContent.paddingHorizontal[layout]}px;
  flex-direction: row;
  justify-content: flex-end;
`

export function Benefit({
  checked,
  description,
}: {
  checked: boolean
  description: string
}) {
  const { tokens } = useTokens()
  return (
    <Row alignItems="flex-start">
      <View>
        <Icon
          size={LINE_ICON_SIZE}
          source={
            checked ? icons.checkCircle.outline : icons.checkboxBlankCircle
          }
          color={
            tokens.planCard.lineItem.iconColor[checked ? "checked" : "default"]
          }
        />
      </View>
      <Spacer horizontal size="small" />
      <BenefitDescription
        textStyle="caption"
        color={
          tokens.planCard.lineItem.textColor[checked ? "checked" : "default"]
        }
      >
        {description}
      </BenefitDescription>
    </Row>
  )
}

export function SignUpOptionCard({
  layout,
  option,
}: {
  layout: Layout
  option: SignUpOption
}) {
  const { i18n, t } = useTranslation()
  const { tokens, displayMode } = useTokens()
  const [isLoading, setIsLoading] = useState(false)
  const checkoutSession = useCheckoutSession()

  useEffect(() => {
    return checkoutSessionManager.on(Event.CheckoutSessionEnded, () =>
      setIsLoading(false),
    )
  }, [])

  const amount = option.terms
    ? `${formatMoney(option.terms.price, i18n.language)}${formatPlanIntervalShort(option.terms)}`
    : undefined

  const trialLengthDays = option.terms?.trialLengthDays

  return (
    <Container layout={layout}>
      {option.badgeText || trialLengthDays ? (
        <BadgeContainer layout={layout}>
          <ButtonView type="primary" size="tiny">
            {option.badgeText ||
              t("{{trialLengthDays}} day free trial", { trialLengthDays })}
          </ButtonView>
        </BadgeContainer>
      ) : null}

      <Card
        as={option.button ? Touchable : undefined}
        highlighted={option.highlighted}
        displayMode={displayMode}
        onPress={
          option.button
            ? async () => {
                if (!isLoading) {
                  setIsLoading(true)
                  await option.button?.onPress()
                  setIsLoading(false)
                }
              }
            : undefined
        }
      >
        <Column
          gap="medium"
          alignItems="stretch"
          paddingTop={tokens.planCard.cardContent.paddingTop}
          paddingBottom={tokens.planCard.cardContent.paddingBottom[layout]}
          paddingHorizontal={
            tokens.planCard.cardContent.paddingHorizontal[layout]
          }
        >
          <Row
            justifyContent="space-between"
            alignItems="flex-start"
            gap="small"
          >
            <Title textStyle="headingLarge">{option.title}</Title>
            {option.terms?.priceDisplayInterval &&
            option.terms.priceDisplayInterval !== option.terms.interval ? (
              <Column alignItems="flex-end">
                <Row alignItems="baseline">
                  <Text textStyle="headingLarge">
                    {formatMoney(
                      {
                        ...option.terms.price,
                        amount:
                          // Converting yearly prices to monthly
                          option.terms.priceDisplayInterval === "month"
                            ? option.terms.price.amount /
                              (12 * option.terms.intervalCount)
                            : // Converting monthly prices to yearly (?)
                              (option.terms.price.amount /
                                option.terms.intervalCount) *
                              12,
                      },
                      i18n.language,
                    )}
                  </Text>
                  <Text textStyle="body">
                    {formatPlanIntervalShort({
                      interval: option.terms.priceDisplayInterval,
                      intervalCount: 1,
                    })}
                  </Text>
                </Row>
                <Text textStyle="captionStrong" color="secondary">
                  {formatPlanIntervalLong(option.terms)}
                </Text>
              </Column>
            ) : option.terms ? (
              <Row alignItems="baseline">
                <Text textStyle="headingLarge">
                  {formatMoney(option.terms.price, i18n.language)}
                </Text>
                <Text textStyle="body">
                  {formatPlanIntervalShort(option.terms)}
                </Text>
              </Row>
            ) : null}
          </Row>
          {option.description ? (
            <Text textStyle="caption">{option.description}</Text>
          ) : null}
          <Column gap="small" alignItems="stretch">
            {option.benefits?.map((lineItem, i) => (
              <Benefit
                key={`${lineItem.checked}-${lineItem.description}-${i} `}
                {...lineItem}
              />
            ))}
          </Column>
        </Column>
        {option.statusText ? (
          <Row
            alignItems="center"
            justifyContent="center"
            height={tokens.button.height.medium}
          >
            <Text textStyle="button" color={tokens.colors.accent}>
              {option.statusText}
            </Text>
          </Row>
        ) : null}
        {option.button ? (
          <Column gap="small" alignItems="stretch">
            {trialLengthDays && amount ? (
              <Text textStyle="caption" color="secondary">
                <Trans
                  shouldUnescape
                  i18nKey="After {{trialLengthDays}} days your subscription will renew automatically at <strong>{{amount}}</strong> unless you cancel. You may cancel anytime."
                  values={{ trialLengthDays, amount }}
                  components={{
                    strong: <Text textStyle="captionStrong" color="primary" />,
                  }}
                />
              </Text>
            ) : amount ? (
              <Text textStyle="caption" color="secondary">
                <Trans
                  shouldUnescape
                  i18nKey="Your subscription will renew automatically at <strong>{{amount}}</strong> until you cancel. You may cancel anytime."
                  values={{ amount }}
                  components={{
                    strong: <Text textStyle="captionStrong" color="primary" />,
                  }}
                />
              </Text>
            ) : null}
            <ButtonView
              type={option.button.type}
              loading={isLoading || option.button.isLoading(checkoutSession)}
            >
              {option.button.label}
            </ButtonView>
          </Column>
        ) : null}
      </Card>
    </Container>
  )
}
