import { matchPath, generatePath } from "react-router"

import memoizeOne from "memoize-one"
import memoize from "nano-memoize"
import queryString from "query-string"

import {
  AppLinkInternal,
  AppLinkPath,
  AppTab,
  ContentType,
} from "@treefort/api-spec"
import { getAppManifestReferences } from "@treefort/lib/app-manifest-references"
import filter from "@treefort/lib/filter"
import mapValues from "@treefort/lib/map-values"

import { AppManifest, getPage } from "../lib/app-manifest"
import { ConsumableContent } from "../lib/consumable-content"

/**
 * TYPES
 */

export type Params<Key extends string = string> = {
  readonly [key in Key]: string | undefined
}

export type Route = {
  path: string
  parents: string[]
  component: string
  title?: string
  authRequired?: boolean
  // Indicates whether the route points to the root of a native react-navigation
  // navigator. We need to know about this so that we can route to screens
  // nested within multiple native navigators.
  nativeNavigator?: boolean
}

export type RouteWithParams<Key extends string = string> = Route & {
  params: Params<Key>
  alias?: string
}

export type SearchTab = typeof SEARCH_TAB

export type MenuTab = typeof MENU_TAB

export type NoTab = typeof NO_TAB

export type AnyTab = AppTab | SearchTab | MenuTab | NoTab

type PathAlias = { alias: string; path: string }

type RouteConfig = {
  path: string
  children?: RouteConfig[]
  component: string
  title?: string
  authRequired?: boolean
  nativeNavigator?: boolean
}

/**
 * ROUTES
 */

const PATH_ALIASES: PathAlias[] = [
  { alias: "/redeem/:promoCode?", path: "/menu/checkout/:promoCode?" },
  { alias: "/join/:promoCode?", path: "/menu/checkout/:promoCode?" },
  // DEPRECATED ROUTES
  {
    alias: "/tabs/:tabSlug/books/:contentId",
    path: "/tabs/:tabSlug/audiobooks/:contentId",
  },
  { alias: "/sign-up/:promoCode?", path: "/menu/checkout/:promoCode?" },
  { alias: "/menu/sign-up/:promoCode?", path: "/menu/checkout/:promoCode?" },
  { alias: "/menu/profile/subscription", path: "/menu/subscription" },
  { alias: "/menu/profile/delete-account", path: "/menu/account/delete" },
  { alias: "/menu/profile/verify-email", path: "/menu/account/verify-email" },
  { alias: "/menu/profile", path: "/menu" },
]

const ROUTE_CONFIG: RouteConfig = {
  path: "/",
  component: "AppTabScreen",
  nativeNavigator: true,
  children: [
    {
      path: "/tabs",
      component: "AppTabScreen",
      nativeNavigator: true,
      children: [
        {
          path: "/search",
          component: "SearchDesktopScreen",
          nativeNavigator: true,
        },
        {
          path: "/:tabSlug",
          component: "AppTabScreen",
          nativeNavigator: true,
          children: [
            {
              path: "/pages/:pageSlug",
              component: "AppPageScreen",
            },
            {
              path: "/podcasts/:contentId",
              component: "ContentPodcastScreen",
            },
            {
              path: "/podcasts/:contentId/episodes/:episodeNumber",
              component: "ContentPodcastEpisodeScreen",
            },
            {
              path: "/audiobooks/:contentId",
              component: "ContentAudiobookScreen",
            },
            {
              path: "/ebooks/:contentId",
              component: "ContentEbookScreen",
            },
            {
              path: "/videos/:contentId",
              component: "ContentVideoScreen",
            },
            {
              path: "/web-embeds/:contentId",
              component: "ContentWebEmbedScreen",
            },
            {
              path: "/video-series/:contentId",
              component: "ContentVideoSeriesScreen",
            },
            {
              path: "/collections/:collectionId",
              component: "CollectionScreen",
            },
            {
              path: "/update",
              title: "Update Required",
              component: "UpdateScreen",
            },
            {
              path: "/not-found/:path",
              title: "Not Found",
              component: "NotFoundScreen",
            },
            {
              path: "/*",
              title: "Not Found",
              component: "NotFoundScreen",
            },
          ],
        },
      ],
    },
    {
      title: "Search",
      path: "/search",
      component: "SearchMobileScreen",
    },
    {
      path: "/menu",
      component: "MenuScreen",
      nativeNavigator: true,
      children: [
        {
          path: "/account",
          title: "Account",
          component: "AccountScreen",
          authRequired: true,
          children: [
            {
              path: "/delete",
              title: "Delete account",
              component: "DeleteAccountScreen",
              authRequired: true,
            },
            {
              path: "/verify-email",
              title: "Verify Email",
              component: "VerifyEmailScreen",
            },
          ],
        },
        {
          path: "/subscription",
          title: "Subscription",
          component: "SubscriptionScreen",
          authRequired: true,
          children: [
            {
              path: "/cancel",
              title: "Cancel subscription",
              component: "CancelSubscriptionScreen",
              authRequired: true,
            },
          ],
        },
        {
          path: "/settings",
          title: "Settings",
          component: "SettingsScreen",
          children: [
            {
              path: "/advanced",
              title: "Advanced Settings",
              component: "AdvancedSettingsScreen",
            },
          ],
        },
        {
          path: "/downloads",
          title: "Downloads",
          component: "DownloadsScreen",
        },
        {
          path: "/contact",
          title: "Contact",
          component: "ContactFormScreen",
        },
        {
          path: "/about",
          title: "About",
          component: "AboutScreen",
        },
        {
          path: "/checkout/:promoCode?",
          title: "Choose Plan",
          component: "CheckoutScreen",
        },
      ],
    },
    {
      path: "/auth/redirect",
      component: "AuthRedirectScreen",
    },
    {
      path: "/auth/register",
      component: "AuthRegisterScreen",
    },
    {
      path: "/auth/login",
      component: "AuthLoginScreen",
    },
    {
      path: "/checkout/redirect",
      component: "CheckoutRedirectScreen",
    },
    {
      path: "/update",
      title: "Update Required",
      component: "UpdateScreen",
    },
    {
      path: "/not-found/:path",
      title: "Not Found",
      component: "NotFoundScreen",
    },
    {
      path: "/*",
      title: "Not Found",
      component: "NotFoundScreen",
    },
  ],
}

const flattenRouteConfig = (
  {
    path,
    component,
    title,
    children,
    nativeNavigator,
    authRequired,
  }: RouteConfig,
  parent?: Route,
): Route[] => {
  const fullPath = ((parent?.path || "") + path).replace(/\/+/g, "/")
  const route: Route = {
    path: fullPath,
    component,
    title,
    parents: parent ? [parent.path, ...parent.parents] : [],
    nativeNavigator,
    authRequired,
  }
  return [
    route,
    ...(children
      ? children.flatMap((routes) => flattenRouteConfig(routes, route))
      : []),
  ]
}

export const ROUTES: Route[] = flattenRouteConfig(ROUTE_CONFIG)

/**
 * UTILITIES
 */

export const SEARCH_TAB = {
  id: "search",
  title: "Search",
  slug: "search",
} as const

export const MENU_TAB = {
  id: "menu",
  slug: "menu",
} as const

export const NO_TAB = {
  id: "none",
  slug: "none",
} as const

export const isAppTab = (tab: AnyTab): tab is AppTab =>
  typeof tab.id === "number"

export const isLibraryTab = (
  tab: AnyTab,
): tab is AppTab & { link: AppLinkPath & { path: "/tabs/library" } } =>
  tab &&
  "link" in tab &&
  tab.link.type === "path" &&
  tab.link.path === "/tabs/library"

export const isPageRoute = (
  route: RouteWithParams,
): route is RouteWithParams<"pageSlug"> & {
  params: { pageSlug: string }
} => "pageSlug" in route.params

export const isCollectionRoute = (
  route: RouteWithParams,
): route is RouteWithParams<"collectionId"> & {
  params: { collectionId: string }
} => "collectionId" in route.params

export const isContentRoute = (
  route: RouteWithParams,
): route is RouteWithParams<"contentId"> & { params: { contentId: string } } =>
  "contentId" in route.params

export const isInitialRouteInTab = (
  route: RouteWithParams,
  tab: AnyTab,
  manifest: AppManifest,
): boolean => {
  // If the route path is the same as the tab's path (e.g. /tabs/home) or the
  // tab's link's path (e.g. /tabs/home/pages/1) then we've got the initial
  // route
  const tabRoute = getRouteFromPath(getPathFromTab(tab))
  const tabPath = generatePath(tabRoute.path, tabRoute.params)
  const tabLinkPath =
    "link" in tab && getPathFromAppLink(tab.link, tab, manifest)
  const routePath = generatePath(route.path, route.params)
  if (tabPath === routePath || tabLinkPath === routePath) {
    return true
  }

  // If the route is "/" or "/tabs" then consider it the initial route for the
  // home tab
  const isHomeTab = manifest.tabs.findIndex(({ id }) => id === tab.id) === 0
  return isHomeTab && (route.path === "/" || route.path === "/tabs")
}

/**
 * This returns an array of path aliases along with the actual paths that they
 * point to. Path aliases are used to support features like tab-less page URLs
 * (e.g. /pages/my-page might alias /tabs/my-tab/pages/my-page) and custom
 * vanity URLs for the tenant (e.g. /audiobooks might alias
 * /tabs/discover/pages/all-audiobooks).
 */
export const getPathAliases = memoizeOne(
  (manifest: AppManifest): PathAlias[] => {
    return [
      // Load a predetermined list of static aliases
      ...PATH_ALIASES,
      // Load custom aliases from the tenant
      ...manifest.pathAliases,
      // Create an alias for every page so that it can be loaded without the tab
      // in the path
      ...manifest.pages.map((page) => {
        const tab = getTabFromPage(page.id, manifest) || NO_TAB
        return {
          alias: `/pages/${page.slug}`,
          path: `/tabs/${tab.slug}/pages/${page.slug}`,
        }
      }),
    ]
  },
)

/**
 * This takes in a path, determines if it is an alias to another path, and
 * returns the path referenced by the alias (if one exists) or the original path
 * (if it is not an alias). The resolution process is recursive up to a max
 * depth of 10, allowing aliases to reference other aliases.
 */
export const resolvePathAliases = memoize(
  (path: string, pathAliases: PathAlias[], maxDepth = 10): string => {
    if (maxDepth === 0) return path
    // We use matchPath below to determine if the provided path matches any of
    // our path aliases. If we find a match we extract the path parameters and
    // then mix them into the aliased path (which we return). For example given
    // the following path alias:
    //   { alias: "/redeem/:promoCode?", path: "/menu/checkout/:promoCode?" }
    // and the following input path:
    //   "/redeem/XYZ"
    // the return path would be:
    //   "/menu/checkout/XYZ"
    let pathAliasMatch: ReturnType<typeof matchPath> | undefined
    const pathAlias = pathAliases.find((pathAlias) => {
      pathAliasMatch = matchPath(path, pathAlias.alias)
      return pathAliasMatch
    })
    const aliasedPath =
      pathAliasMatch?.params && pathAlias
        ? generatePath(pathAlias.path, pathAliasMatch.params)
        : pathAlias?.path
    return aliasedPath
      ? resolvePathAliases(aliasedPath, pathAliases, maxDepth - 1) ||
          aliasedPath
      : path
  },
)

/**
 * This parses a raw path from react navigation or the browser's location
 * object, extracts query parameters, trims the path, and resolves path
 * aliases (if the manifest is provided).
 */
export const parseRawPath = (
  rawPath: string,
  manifest?: AppManifest,
): { path: string; queryParams: Params; alias?: string } => {
  const [splitPath, query] = decodeURI(rawPath).split("?")

  // Make sure the path starts with a slash and does not end with a slash or
  // "index"
  const trimmedPath =
    "/" + splitPath.replace(/^\/+|\/+$/g, "").replace(/\/index$/, "")

  // If a manifest was provided then we can check if the path is an alias and
  // return the original path that the alias points to.
  const path = manifest
    ? resolvePathAliases(trimmedPath, getPathAliases(manifest))
    : trimmedPath
  const alias = path !== trimmedPath ? trimmedPath : undefined
  const queryParams = query
    ? (filter(
        queryString.parse(query),
        (value) => typeof value === "string",
      ) as Params)
    : {}

  return { path, queryParams, alias }
}

/**
 * This takes in a raw path and returns the best route for the path. A route
 * will always be returned, but it may be the root "Not Found" route if there is
 * no better match.
 *
 * If the path may be an alias then the manifest argument must be provided so
 * that the alias can be resolved, otherwise the result will be the "Not Found"
 * route. If the path is known to be an actual non-alias path then the manifest
 * argument can be safely omitted.
 */
export const getRouteFromPath = memoize(
  (rawPath: string, manifest?: AppManifest): RouteWithParams<string> => {
    const { path, queryParams, alias } = parseRawPath(rawPath, manifest)
    const routeMatches = ROUTES.map((route) => ({
      match: matchPath(path, {
        path: route.path,
        exact: true,
      }),
      route,
    }))
    // We will always find a match because of the "Not Found" catch-all route
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const routeMatch = routeMatches.find(({ match }) => match)!
    return {
      ...routeMatch.route,
      params: { ...routeMatch.match?.params, ...queryParams },
      alias,
    }
  },
)

export const getRouteFromLocation = memoize(
  <Key extends string = string>(
    location: {
      pathname: string
      search: string
    },
    manifest: AppManifest,
  ): RouteWithParams<Key> => {
    const route = getRouteFromPath(location.pathname, manifest)
    const tab = getTabFromPath(location.pathname, manifest)
    const params = {
      ...(tab && isInitialRouteInTab(route, tab, manifest)
        ? getInitialRouteParamsFromTab(tab)
        : null),
      ...route.params,
      ...queryString.parse(location.search),
    }
    return { ...route, params } as RouteWithParams<Key>
  },
)

export const getPathFromRoute = <Key extends string = string>(
  route: RouteWithParams<Key>,
): string => generatePath(route.alias || route.path, route.params)

/**
 * This returns an array containing the path for every route upstream from the
 * provided route excluding the root. For example, given a route at
 * /tabs/home/pages/my-page/audiobooks/1 the parent paths would be
 * ["/tabs/home/pages/my-page", "/tabs/home"]).
 */
export const getParentPathsFromRoute = (route: RouteWithParams): string[] =>
  route.parents
    .filter((parent) => parent !== "/")
    .map((path) => generatePath(path, route.params))

/**
 * This returns the tab that will be rendered by default when the app is loaded.
 */
export const getHomeTab = (manifest: AppManifest): AppTab => manifest.tabs[0]

/**
 * This returns the tab that "contains" the given path. For example, given the
 * path /tabs/home/pages/my-page the AppTab with the slug "home" would be
 * returned.
 */
export const getTabFromPath = (
  rawPath: string,
  manifest: AppManifest,
): AnyTab | undefined => {
  const { path } = parseRawPath(rawPath, manifest)

  // If the path starts with "/menu" select the menu tab
  if (path.match(/^\/menu/)) {
    return MENU_TAB
  }

  // Return the first tab from the manifest for the root path
  if (path === "/") {
    return manifest.tabs[0]
  }

  // If the path explictly references a tab then return it
  const slug = path.match(/^\/tabs\/([^/]+)/)?.[1]
  return slug ? getTabFromSlug(slug, manifest) : undefined
}

/**
 * Look up a tab given a slug from a path (i.e. /tabs/:tabSlug)
 */
export const getTabFromSlug = (
  slug: string,
  manifest: AppManifest,
): AnyTab | undefined => {
  switch (slug) {
    case "search":
      return SEARCH_TAB
    case "none":
      return NO_TAB
    default: {
      const matchingTab = manifest.tabs.find((tab) => tab.slug === slug)
      return matchingTab
    }
  }
}

/**
 * Returns the best tab for the given route, or NO_TAB if a tab can't be
 * identified.
 */
export const getTabFromRoute = (
  route: RouteWithParams,
  manifest: AppManifest,
) => getTabFromPath(getPathFromRoute(route), manifest) || NO_TAB

/**
 * This determines the "containing" tab for every page in the manifest. The
 * containing tab for a page is the tab that links to the page with the least
 * indirection. This function is memoized as it requires a relatively expensive
 * walk through the entire manifest.
 */
const getTabForEachPageInManifest = memoizeOne(
  (manifest: AppManifest): Record<number, AppTab | null> => {
    const { pageReferences } = getAppManifestReferences({
      manifest,
      includePageReferences: true,
    })
    return mapValues(pageReferences, ({ parentTabIds }) => {
      const tabId = parentTabIds[0]
      const tab = manifest.tabs.find((tab) => tab.id === tabId)
      return tab ?? null
    })
  },
)

/**
 * Takes in a pageId and a manifest and returns the AppTab that "contains" the
 * page. See getTabForEachPageInManifest for details.
 */
export const getTabFromPage = (
  pageId: number,
  manifest: AppManifest,
): AppTab | null => getTabForEachPageInManifest(manifest)[pageId]

/**
 * Returns the page that the given route points to, or null if the route doesn't
 * point to a page.
 */
export const getPageFromRoute = (
  route: RouteWithParams,
  manifest: AppManifest,
) => {
  // First attempt to match the page with the route's path
  const pathMatch = manifest.pages.find(
    (page) => page.slug === route.params.pageSlug,
  )
  if (pathMatch) return pathMatch

  // Next attempt to match the page with the current tab's link (assuming we're
  // on the initial page for the tab)
  const tab = getTabFromRoute(route, manifest)
  const tabPageId = "link" in tab && tab.link.type === "page" && tab.link.pageId
  const tabMatch =
    tabPageId &&
    isInitialRouteInTab(route, tab, manifest) &&
    manifest.pages.find((page) => tabPageId === page.id)
  if (tabMatch) return tabMatch

  // Otherwise we got nothing
  return null
}
/**
 * This returns the page that the given path points to, or null if the path
 * doesn't point to a page in the manifest (e.g. if it points to a piece of
 * content directly instead).
 */
export const getPageFromPath = (path: string, manifest: AppManifest) => {
  const route = getRouteFromPath(path, manifest)
  return getPageFromRoute(route, manifest)
}

export const getInitialRouteParamsFromTab = (
  tab: AnyTab,
): Record<string, unknown> | undefined =>
  "link" in tab && tab.link.type === "content"
    ? { contentId: tab.link.contentId }
    : "link" in tab && tab.link.type === "collection"
      ? { collectionId: tab.link.collectionId }
      : "link" in tab && tab.link.type === "podcastEpisode"
        ? {
            contentId: tab.link.podcastId,
            episodeNumber: tab.link.episodeNumber,
          }
        : undefined

export const getPathFromTab = (tab: AnyTab): string =>
  tab.id === "menu" ? `/menu` : `/tabs/${tab.slug}`

export const getPathFromContent = (
  id: number,
  type: ContentType,
  tab: AppTab | SearchTab | NoTab,
): string => {
  const tabPath = getPathFromTab(tab)
  switch (type) {
    case "book":
      return tabPath + "/audiobooks/" + id
    case "ebook":
      return tabPath + "/ebooks/" + id
    case "podcast":
      return tabPath + "/podcasts/" + id
    case "video":
      return tabPath + "/videos/" + id
    case "videoSeries":
      return tabPath + "/video-series/" + id
    case "webEmbed":
      return tabPath + "/web-embeds/" + id
    // If we don't recognize the content type, assume the client is out of date
    default:
      return tabPath + "/update"
  }
}

export const getPathFromPodcastEpisode = (
  podcastId: number,
  episodeNumber: number,
  tab: AppTab | SearchTab | NoTab,
): string =>
  `${getPathFromContent(podcastId, "podcast", tab)}/episodes/${episodeNumber}`

export const getPathFromConsumableContent = (
  consumableContent: ConsumableContent,
  tab: AppTab | SearchTab | NoTab,
) =>
  consumableContent.type === "podcastEpisode"
    ? getPathFromPodcastEpisode(
        consumableContent.content.id,
        consumableContent.podcastEpisode.episode,
        tab,
      )
    : getPathFromContent(
        consumableContent.content.id,
        consumableContent.content.type,
        tab,
      )

export const getPathFromCollection = (
  id: number,
  tab: AppTab | SearchTab | NoTab,
): string => getPathFromTab(tab) + "/collections/" + id

export const getPathFromPageWithSlug = (
  id: number,
  tab: AppTab | SearchTab | NoTab,
  slug: string,
): string => {
  return getPathFromTab(tab) + ("/pages/" + slug)
}

export const getPathFromPage = (
  id: number,
  tab: AppTab | SearchTab | NoTab,
  manifest: AppManifest,
): string => {
  const page = getPage(id, manifest)
  return getPathFromTab(tab) + (page ? "/pages/" + page.slug : "")
}

export const getPathFromAppLink = (
  link: AppLinkInternal,
  tab: AnyTab,
  manifest: AppManifest,
): string => {
  if (tab.id === MENU_TAB.id) {
    return link.type === "path" ? link.path : getPathFromTab(tab)
  }
  switch (link.type) {
    case "page":
      return getPathFromPage(link.pageId, tab, manifest)
    case "content":
      return getPathFromContent(link.contentId, link.contentType, tab)
    case "podcastEpisode":
      return getPathFromPodcastEpisode(link.podcastId, link.episodeNumber, tab)
    case "collection":
      return getPathFromCollection(link.collectionId, tab)
    case "path":
      return link.path
    // If we don't recognize the link type, assume the client is out of date
    default:
      return "/update"
  }
}
