export enum PlaybackState {
  Idle = "IDLE", // Nothing going on
  Paused = "PAUSED", // Media loaded, paused
  Playing = "PLAYING", // Playing media
  Loading = "LOADING", // Meida loading while paused
  Buffering = "BUFFERING", // Media loading while attempting to play
  Error = "ERROR", // Something went wrong
}

export type Track<Extra extends Record<string, unknown> | unknown = unknown> = {
  title: string
  url: string
  headers?: Record<string, string>
  query?: Record<string, string>
  cors?: "anonymous" | "use-credentials"
  artwork?: string
  duration?: number
  artist?: string
  album?: string
  extra: Extra
}

export enum Event {
  Tracks = "TRACKS", // Subscribe to the list of tracks
  Track = "TRACK", // Subscribe to the current track
  TrackIndex = "TRACK_INDEX", // Subscribe to the index of the current track
  Finished = "FINISHED", // Listen for when playback of all tracks has finished
  Progress = "PROGRESS", // Subscribe to the current progress
  PlaybackRate = "PLAYBACK_RATE", // Subscribe to the current playback state
  PlaybackState = "PLAYBACK_STATE", // Subscribe to the current playback state
  Seeked = "SEEKED", // Listen for when the player has finished seeking
  SeekCancelled = "SEEK_CANCELLED", // Triggered any time a seek is initiated but not completed
  Seekable = "SEEKABLE", // Listen for when the player becomes seekable (or not)
  WillStop = "WILL_STOP", // Fired via stop
  WillSuspend = "WILL_SUSPEND", // Fired before a player is suspended
  Suspended = "SUSPENDED", // Fired when a player is suspended or resumes
  Error = "ERROR", // Listen for errors
  PlayIntent = "PLAY_INTENT", // Fired as soon as we know that something should be played
  PlayRequest = "PLAY_REQUEST", // Fired when a play action is requested
  PauseRequest = "PAUSE_REQUEST", // Fired when a pause action is requested
  SeekRequest = "SEEK_REQUEST", // Fired when a seek action is requested
  SkipRequest = "SKIP_REQUEST", // Fired when a skip action is requested
}

export interface EventMap {
  [Event.Tracks]: Track[]
  [Event.Track]: Track | null
  [Event.TrackIndex]: number | null
  [Event.Finished]: undefined
  [Event.Progress]: Progress
  [Event.PlaybackState]: PlaybackState
  [Event.PlaybackRate]: number
  [Event.Seeked]: number
  [Event.SeekCancelled]: undefined
  [Event.Seekable]: boolean
  [Event.WillStop]: undefined
  [Event.WillSuspend]: undefined
  [Event.Suspended]: boolean
  [Event.Error]: unknown
  [Event.PlayIntent]: undefined
  [Event.PlayRequest]: undefined
  [Event.PauseRequest]: undefined
  [Event.SeekRequest]: number
  [Event.SkipRequest]: number
}

export type Progress = {
  position: number
  duration: number
  index?: number
}
