import React from "react"
import { AsyncViewOfflineProvider } from "../../components/async-view-offline-provider"
import { ContentLayout } from "../layouts/content"

export function ContentVideoSeriesScreen(): JSX.Element {
  return (
    <AsyncViewOfflineProvider>
      <ContentLayout contentType="videoSeries" />
    </AsyncViewOfflineProvider>
  )
}
