import { useCallback, useMemo } from "react"
import { useNavigate } from "../use-navigate"
import { useTokens } from "../../components/tokens-provider"
import { getHomeTab, getPathFromTab, SEARCH_TAB } from "../../navigation/routes"
import { useHistory } from "../use-history"
import useAppManifest from "../use-app-manifest"

export function useSearch(): { open: () => void; close: () => void } {
  const manifest = useAppManifest()
  const history = useHistory()
  const navigate = useNavigate()
  const { tokens } = useTokens()

  const open = useCallback(() => {
    navigate(
      tokens.appHeader.mode === "desktop"
        ? getPathFromTab(SEARCH_TAB)
        : "/search",
    )
  }, [navigate, tokens.appHeader.mode])

  const close = useCallback(() => {
    if (history.canGoBack()) {
      history.goBack()
    } else {
      navigate(getPathFromTab(getHomeTab(manifest)))
    }
  }, [history, navigate, manifest])

  return useMemo(() => ({ open, close }), [open, close])
}
