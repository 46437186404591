// TODO: Remove after 6/31/23 when Canon+ no longer needs these deprecated
// events
import {
  CommonPlayParameters,
  PlayableContentParameters,
  EventName,
  Parameter,
  PlayMilestoneParameters,
  AnalyticsLogger,
  AnalyticsPlugin,
} from "@treefort/lib/analytics"

import config from "../../config"
import {
  getKeyFromConsumableContent,
  getTracksFromConsumableContent,
  PlayableContent,
} from "../consumable-content"
import { logError } from "../logging"
import { PlayableProgressItem, VideoProgressItem } from "../progress-item"
import settings from "../settings"
import { Store } from "../store"
import { firebaseAnalyticsPlugin } from "./plugins/firebase"

/**
 * TYPES
 */

// The points at which we log progress milestones (e.g. 10%, 25%, etc.)
type MilestonePercent = PlayMilestoneParameters[Parameter.MilestonePercent]

// We log progress for playable items in a local store so that network
// connection issues don't cause us to lose the data.
type PlayProgressStoreItem = {
  playDuration: number
  playPosition?: number
  eventParameters: CommonPlayParameters & PlayableContentParameters
  mediaDuration?: number
}

// This setting contains data regarding how long a user has played a piece of
// playable content and how far they got through it.
type PlayProgressSettingStoreItem = {
  totalPlayDuration: number
  maxPlayPosition: number
}

/**
 * HELPERS
 */

const playProgressStore = new Store({ key: "analytics.playback" })

const getCommonPlayParameters = (progressItem: PlayableProgressItem) => ({
  [Parameter.PlayMode]:
    progressItem instanceof VideoProgressItem
      ? progressItem.getPlayMode()
      : "listen",
  [Parameter.PlayTrack]: progressItem.getProgress().index,
  [Parameter.PlayPosition]: progressItem.getProgress().position,
  [Parameter.PlaybackRate]: progressItem
    .getPlaybackRate()
    .toFixed(config.PLAYBACK_RATE_DECIMALS),
})

const getPlayableContentParameters = (consumableContent: PlayableContent) => ({
  [Parameter.ContentId]: consumableContent.content.id.toString(),
  [Parameter.ContentTitle]: consumableContent.content.title,
  [Parameter.ContentSku]: consumableContent.content.sku ?? undefined,
  ...(consumableContent.type === "podcastEpisode"
    ? {
        [Parameter.ContentType]: consumableContent.content.type,
        [Parameter.ContentPodcastEpisode]:
          consumableContent.podcastEpisode.episode,
      }
    : {
        [Parameter.ContentType]: consumableContent.content.type,
      }),
})

const logAnalyticsError = (description: string) => (error: unknown) =>
  logError(new Error(`[Deprecated Analytics] ${description}`, { cause: error }))

/**
 * MAIN
 */

class DeprecatedAnalytics extends AnalyticsLogger {
  constructor({ plugins }: { plugins: AnalyticsPlugin[] }) {
    super({ plugins, logError })

    // Attempt to log any milestones that we weren't able to log previously
    // (e.g. due to API issues, the device being offline, etc.)
    playProgressStore
      .keys()
      .then((keys) => Promise.all(keys.map(this.logPlayProgressMilestones)))
      .catch(logAnalyticsError("Failed to fetch play progress from store"))
  }

  /**
   * Log that the user played a particular piece of content for a certain
   * duration. This does not necessarily represent the total duration of the
   * user's play session - the event may be logged multiple times during a
   * single session and summed to come up with the total duration of the
   * session.
   */
  logPlayProgress = ({
    consumableContent,
    progressItem,
    duration,
  }: {
    consumableContent: PlayableContent
    progressItem: PlayableProgressItem
    duration: number
  }): void => {
    const eventParameters = {
      ...getCommonPlayParameters(progressItem),
      ...getPlayableContentParameters(consumableContent),
    }

    // Cache data locally and attempt to log milestones
    const key = getKeyFromConsumableContent(consumableContent)
    const tracks = getTracksFromConsumableContent({
      consumableContent,
      profileId: null,
    })
    const mediaDuration = progressItem.getTotalDuration(tracks)
    const playPosition = progressItem.getOverallPosition(tracks)
    playProgressStore
      .update<PlayProgressStoreItem>(key, (data) => ({
        playDuration: data ? data.playDuration + duration : duration,
        playPosition: playPosition
          ? Math.max(Math.round(playPosition), data?.playPosition || 0)
          : data?.playPosition,
        mediaDuration: mediaDuration || (data ? data.mediaDuration : undefined),
        eventParameters,
      }))
      .then(() => this.logPlayProgressMilestones(key))
      .catch(logAnalyticsError("Failed to update play progress store"))
  }

  /**
   * Log milestones (e.g. log tf_play_milestone_duration once the user plays a
   * piece of content for a certain percentage of its duration or
   * tf_play_milestone_position once the user plays past a certain percentage
   * mark in a piece of content's timeline).
   */
  private logPlayProgressMilestones = async (key: string): Promise<void> => {
    // Bail if we can't save remote settings - we need to do so in order to
    // coordinate duration accross devices.
    if (await settings.localOnly()) {
      return
    }

    // Fetch locally cached duration data (and bail if we can't find any)
    const { playDuration, playPosition, mediaDuration, eventParameters } =
      (await playProgressStore.get<PlayProgressStoreItem>(key)) || {}
    if (!mediaDuration || !eventParameters) {
      return
    }

    // Fetch remote data. The remote data is source-of-truth for the total
    // duration / max position across all devices.
    const remoteSettingKey = playProgressStore.key + "." + key
    const setting = await settings.getRemote<PlayProgressSettingStoreItem>(
      remoteSettingKey,
      { profileId: null },
    )
    const totalPlayDuration = setting.value?.totalPlayDuration || 0
    const maxPlayPosition = setting.value?.maxPlayPosition || 0

    // Calculate previous and next percentages so we know which milestone to log
    const prevDurationPercent = 100 * (totalPlayDuration / mediaDuration)
    const nextDuration = playDuration
      ? totalPlayDuration + playDuration
      : totalPlayDuration
    const nextDurationPercent = 100 * (nextDuration / mediaDuration)
    const prevPositionPercent = 100 * (maxPlayPosition / mediaDuration)
    const nextPosition = playPosition
      ? Math.max(playPosition, maxPlayPosition)
      : maxPlayPosition
    const nextPositionPercent = 100 * (nextPosition / mediaDuration)

    // If nothing changed, bail
    if (
      nextDurationPercent === prevDurationPercent &&
      nextPositionPercent === prevPositionPercent
    ) {
      return
    }

    // Update the remote data _before_ clearing the local cache and logging
    // events. If we called this after and it failed then we'd risk logging the
    // same milestones twice.
    await settings.saveRemote<PlayProgressSettingStoreItem>(
      remoteSettingKey,
      {
        totalPlayDuration: nextDuration,
        maxPlayPosition: nextPosition,
      },
      { profileId: null },
    )

    // Clear out our locally cached duration data so we don't count it twice.
    // Use the update function instead of the clear function to make sure this
    // change is coordinated with updates in logPlayProgress.
    await playProgressStore.update(key, () => null)

    const logs: Promise<void>[] = []

    // Log duration milestones
    if (playDuration) {
      const logMilestone = (percent: MilestonePercent) =>
        logs.push(
          this.logEvent({
            name: "tf_play_milestone_duration" as EventName.PlayTimeMilestone,
            parameters: {
              ...eventParameters,
              tf_play_milestone_percentage: percent,
            } as unknown as PlayMilestoneParameters,
          }),
        )
      if (prevDurationPercent < 10 && nextDurationPercent >= 10) {
        logMilestone(10)
      }
      if (prevDurationPercent < 25 && nextDurationPercent >= 25) {
        logMilestone(25)
      }
      if (prevDurationPercent < 50 && nextDurationPercent >= 50) {
        logMilestone(50)
      }
      if (prevDurationPercent < 75 && nextDurationPercent >= 75) {
        logMilestone(75)
      }
      if (prevDurationPercent < 90 && nextDurationPercent >= 90) {
        logMilestone(90)
      }
    }

    // Log position milestones
    if (playPosition) {
      const logMilestone = (percent: MilestonePercent) =>
        logs.push(
          this.logEvent({
            name: "tf_play_milestone_position" as EventName.PlayPositionMilestone,
            parameters: {
              ...eventParameters,
              tf_play_milestone_percentage: percent,
            } as unknown as PlayMilestoneParameters,
          }),
        )
      if (prevPositionPercent < 10 && nextPositionPercent >= 10) {
        logMilestone(10)
      }
      if (prevPositionPercent < 25 && nextPositionPercent >= 25) {
        logMilestone(25)
      }
      if (prevPositionPercent < 50 && nextPositionPercent >= 50) {
        logMilestone(50)
      }
      if (prevPositionPercent < 75 && nextPositionPercent >= 75) {
        logMilestone(75)
      }
      if (prevPositionPercent < 90 && nextPositionPercent >= 90) {
        logMilestone(90)
      }
    }

    await Promise.all(logs)
  }
}

export const deprecatedAnalytics = new DeprecatedAnalytics({
  plugins: [firebaseAnalyticsPlugin],
})
