import React from "react"
import styled from "styled-components/native"

const ProgressBarWrapper = styled.View`
  border-radius: ${(props) => props.theme.borderRadius.roundedFull}px;
  background: ${(props) => props.theme.progressBar.color.empty};
  overflow: hidden;
`

const ProgressBarFiller = styled.View<{ progress?: number }>`
  width: ${(props) => (props.progress ? props.progress * 100 : 0)}%;
  height: ${(props) => props.theme.progressBar.thickness}px;
  min-width: ${(props) => props.theme.progressBar.thickness}px;
  background: ${(props) => props.theme.progressBar.color.fill};
  border-radius: ${(props) => props.theme.borderRadius.roundedFull}px;
`

export default function ProgressBar({
  progress = 0,
}: {
  progress?: number
}): JSX.Element | null {
  return (
    <ProgressBarWrapper>
      <ProgressBarFiller progress={progress} />
    </ProgressBarWrapper>
  )
}
