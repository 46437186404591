import React, { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet } from "react-native"

import styled from "styled-components/native"

import { Result } from "@treefort/lib/result"
import icons from "@treefort/tokens/app/icons"

import { useActiveProfileId } from "../../hooks/use-active-profile-id"
import { useAppLinkHelpers } from "../../hooks/use-app-link-helpers"
import useAppManifest from "../../hooks/use-app-manifest"
import { useAsyncViewPropsForQueries } from "../../hooks/use-async-view-props-for-queries"
import useContent from "../../hooks/use-content"
import { useOpenCheckoutPage } from "../../hooks/use-open-checkout-page"
import { useProgressItem } from "../../hooks/use-progress-item"
import {
  canDownloadConsumableContent,
  getConsumableContentFromPodcastResponse,
} from "../../lib/consumable-content"
import { playContentAudio } from "../../lib/content-audio"
import { formatDate } from "../../lib/date"
import AddToLibraryButton, { canAddToLibrary } from "../add-to-library-button"
import { AppLink } from "../app-link"
import { AsyncButton } from "../async-button"
import { useAsyncViewProps } from "../async-view"
import { BoxPadding } from "../box"
import DownloadButton from "../download-button"
import { Heading } from "../heading"
import Icon from "../icon"
import LockedContentButton from "../locked-content-button"
import { MetadataSpacer, MetadataText } from "../metadata"
import ModuleArtworkLayout from "../module-artwork-layout"
import ProgressForConsumableContent from "../progress-for-consumable-content"
import Row from "../row"
import Spacer from "../spacer"
import Text from "../text"
import TextWithLinks from "../text-with-links"

const { buttonFlexContainerStyle } = StyleSheet.create({
  buttonFlexContainerStyle: { flex: 1 },
})

const PlayButton = styled(AsyncButton)`
  flex: ${(props) =>
    props.theme.audiobookModule.playButtonFlex ? "1" : "none"};
`

const LockedPlayButton = styled(LockedContentButton)`
  flex: ${(props) =>
    props.theme.audiobookModule.playButtonFlex ? "1" : "none"};
`

export function PodcastEpisodeModule({
  podcastId,
  episodeNumber,
  maxWidth,
  paddingTop = "medium",
  presentation = "solo",
}: {
  podcastId: number
  episodeNumber: number
  maxWidth?: number
  paddingTop?: BoxPadding
  presentation?: "solo" | "listItem"
}) {
  const profileId = useActiveProfileId()
  const podcast = useContent(podcastId, "podcast")
  const [artworkReady, setArtworkReady] = useState(false)
  const { t, i18n } = useTranslation()
  const podcastEpisode = podcast.data?.details.episodes.find(
    (episode) => episode.episode === episodeNumber,
  )

  const openCheckoutPage = useOpenCheckoutPage({
    availability: podcastEpisode?.audioMedia,
  })

  const consumableContent = Result.mapSuccess(podcast, (podcast) => {
    try {
      return Result.success(
        getConsumableContentFromPodcastResponse(podcast, episodeNumber),
      )
    } catch (error) {
      return Result.error(error)
    }
  })
  const showDownloadButton =
    consumableContent.isSuccess &&
    canDownloadConsumableContent(consumableContent.data)
  const isLocked = podcastEpisode?.audioMedia?.status === "notAvailable"
  const showAddToLibraryButton = canAddToLibrary({
    manifest: useAppManifest(),
    consumableContent: consumableContent.data,
  })
  const description = podcastEpisode?.description || podcast.data?.description
  const progressItem = useProgressItem({
    consumableContent: consumableContent.data,
    strategy: "localOrRemote",
    throttle: 1000,
  })
  const appLinkHelpers = useAppLinkHelpers()

  const playPodcastEpisode = useCallback(() => {
    if (isLocked) {
      openCheckoutPage()
    } else if (!isLocked && consumableContent.isSuccess) {
      return playContentAudio({
        consumableContent: consumableContent.data,
        profileId,
      })
    }
  }, [consumableContent, isLocked, openCheckoutPage, profileId])

  const asyncViewPropsForQuery = useAsyncViewPropsForQueries(podcast, {
    forceLoading: podcast.data?.artworkMedia ? !artworkReady : false,
  })

  useAsyncViewProps(
    consumableContent.error instanceof Error &&
      consumableContent.error.message.includes("not found")
      ? {
          state: "error",
          title: t("Not Found"),
          message: t("Sorry, that page doesn't exist!"),
        }
      : asyncViewPropsForQuery,
  )

  return podcast.data && podcastEpisode ? (
    <ModuleArtworkLayout
      paddingTop={paddingTop}
      onArtworkReady={setArtworkReady}
      artwork={
        podcastEpisode.artworkMedia?.url ||
        podcast.data?.artworkMedia?.original.url
      }
      maxWidth={maxWidth}
    >
      {({ layout }) => (
        <>
          <Heading
            level={2}
            textStyle="headingLarge"
            color="primary"
            maxWidth="title"
          >
            {podcastEpisode.title}
          </Heading>
          <Spacer size="small" />
          <AppLink
            to={{
              type: "content",
              contentId: podcast.data.id,
              contentType: "podcast",
            }}
            aria-label={podcast.data.title}
          >
            <Row>
              <Text textStyle="body" numberOfLines={1}>
                {podcast.data.title}
              </Text>
              <Spacer size="tiny" horizontal />
              <Icon source={icons.chevronRight} size="xsmall" />
            </Row>
          </AppLink>
          <Spacer size="small" />
          <Row>
            {podcast.data.details.host ? (
              <MetadataText>{podcast.data.details.host}</MetadataText>
            ) : null}
            {podcast.data.details.host && podcastEpisode.date ? (
              <MetadataSpacer />
            ) : null}
            {podcastEpisode.date ? (
              <MetadataText>
                {formatDate(new Date(podcastEpisode.date), {
                  strategy: "naturalDate",
                  i18n,
                })}
              </MetadataText>
            ) : null}
            {progressItem?.getPodcastEpisodeDuration() ? (
              <ProgressForConsumableContent
                flex={1}
                consumableContent={consumableContent.data}
                includeProgressLabel
                includeFinishedBadge
                childrenBefore={
                  podcast.data.details.host || podcastEpisode.date ? (
                    <MetadataSpacer />
                  ) : null
                }
              />
            ) : null}
          </Row>
          {podcast.data.publisher ? (
            <MetadataText>{podcast.data.publisher.name}</MetadataText>
          ) : null}
          <ProgressForConsumableContent
            consumableContent={consumableContent.data}
            includeProgressBar
            childrenBefore={<Spacer size="small" />}
          />
          <Spacer size="medium" />
          <Row>
            {podcastEpisode.audioMedia?.status === "notAvailable" ? (
              <LockedPlayButton
                openCheckoutPage={openCheckoutPage}
                availability={podcastEpisode.audioMedia}
                action="listen"
                containerStyle={buttonFlexContainerStyle}
              />
            ) : (
              <PlayButton
                icon={icons.headphones}
                type="primary"
                onPress={playPodcastEpisode}
                disabled={!podcast.isSuccess}
                containerStyle={buttonFlexContainerStyle}
              >
                {t("Listen")}
              </PlayButton>
            )}
            {showDownloadButton ? (
              <>
                <Spacer size={"large"} horizontal />
                <DownloadButton consumableContent={consumableContent.data} />
              </>
            ) : null}
            {showAddToLibraryButton ? (
              <>
                <Spacer
                  size={showDownloadButton ? "small" : "large"}
                  horizontal
                />
                <AddToLibraryButton
                  consumableContent={consumableContent.data}
                />
              </>
            ) : null}
          </Row>
          {description ? (
            <>
              <Spacer size="medium" />
              <TextWithLinks
                textStyle="body"
                maxWidth="description"
                numberOfLines={
                  presentation === "solo"
                    ? undefined
                    : layout === "portrait"
                      ? 2
                      : 6
                }
                getAppLinkProps={appLinkHelpers.getProps}
              >
                {description}
              </TextWithLinks>
            </>
          ) : null}
        </>
      )}
    </ModuleArtworkLayout>
  ) : null
}
