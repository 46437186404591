import React, { useState, useCallback, useEffect, useRef } from "react"
import { SafeAreaView } from "react-native-safe-area-context"

import styled from "styled-components/native"

import { getOptimizedImageSource } from "@treefort/lib/get-optimized-image-source"
import { useWillUnmount } from "@treefort/lib/use-will-unmount"

import { NavigationBar } from "../../components/system-bars/navigation-bar"
import { StatusBar } from "../../components/system-bars/status-bar"
import config from "../../config"
import { hideSplashScreen } from "../../lib/splash-screen"

const SPLASH_SCREEN_PADDING_PX = 24
const SPLASH_SCREEN_MESSAGE_MAX_WIDTH_PX = 320
const STATUS_BAR_STYLE =
  config.SPLASH_SCREEN_DISPLAY_MODE === "light"
    ? "dark-content"
    : "light-content"
const NAVIGATION_BAR_BUTTON_STYLE =
  config.SPLASH_SCREEN_DISPLAY_MODE === "light" ? "dark" : "light"

const View = styled.View`
  flex: auto;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: ${SPLASH_SCREEN_PADDING_PX}px;
  background-color: ${config.SPLASH_SCREEN_BACKGROUND_COLOR};
`

const LogoImage = styled.Image`
  width: ${config.SPLASH_SCREEN_FOREGROUND_IMAGE_WIDTH}px;
  flex: auto;
`

const MessageContainer = styled(SafeAreaView)`
  position: absolute;
  bottom: ${SPLASH_SCREEN_PADDING_PX}px;
  left: ${SPLASH_SCREEN_PADDING_PX}px;
  right: ${SPLASH_SCREEN_PADDING_PX}px;
  align-items: center;
`

const Message = styled.View`
  width: 100%;
  max-width: ${SPLASH_SCREEN_MESSAGE_MAX_WIDTH_PX}px;
  background-color: #fff;
  border-radius: 8px;
  padding: ${SPLASH_SCREEN_PADDING_PX}px;
`

const Title = styled.Text`
  color: #000000;
  margin-bottom: 8px;
  text-align: center;
  font-weight: bold;
`

const Description = styled.Text`
  margin-bottom: 16px;
  text-align: center;
  color: #000000;
`

const Button = styled.TouchableOpacity<{ disabled: boolean }>`
  background-color: #dddddd;
  border-radius: 4px;
  padding: 8px;
  ${(props) => (props.disabled ? "opacity: 0.5;" : "")}
`

const ButtonLabel = styled.Text`
  color: #000000;
  text-align: center;
`

export function SplashScreen({
  message,
}: {
  message?: {
    title: string
    description: string
    action?: {
      title: string
      waitingTitle: string
      callback: () => Promise<void>
    }
  }
}): JSX.Element {
  const [waiting, setWaiting] = useState(false)
  const prevWaiting = useRef<boolean>(waiting)
  const willUnmount = useWillUnmount()

  // When the action button is clicked trigger the action callback and indicate
  // visually that something is being done about the error.
  const onAction = useCallback(() => setWaiting(true), [])

  // If we moved into "waiting" mode, trigger the callback. This has to happen
  // in an effect so that we can cancel the `setWaiting(false)` state update
  // if the component is unmounted before the callback's promise resolves.
  useEffect(() => {
    if (waiting && !prevWaiting.current && message && message.action) {
      message.action
        .callback()
        .finally(() => !willUnmount.current && setWaiting(false))
    }
  }, [waiting, message, willUnmount])

  useEffect(() => {
    prevWaiting.current = waiting
  }, [waiting])

  // Make sure the native splash screen is hidden so the user can see the custom
  // message on this faux splash screen. Otherwise leave the native splash
  // screen alone to avoid flickering.
  useEffect(() => {
    if (message) {
      hideSplashScreen()
    }
  }, [message])

  return (
    <View>
      <LogoImage
        source={getOptimizedImageSource(
          { uri: config.SPLASH_SCREEN_FOREGROUND_IMAGE_URL },
          config.SPLASH_SCREEN_FOREGROUND_IMAGE_WIDTH,
        )}
        resizeMode="contain"
      />
      {message ? (
        <MessageContainer edges={["bottom"]} mode="margin">
          <Message>
            <Title>{message.title}</Title>
            <Description>{message.description}</Description>
            {message.action ? (
              <Button onPress={onAction} disabled={waiting}>
                <ButtonLabel>
                  {waiting ? message.action.waitingTitle : message.action.title}
                </ButtonLabel>
              </Button>
            ) : null}
          </Message>
        </MessageContainer>
      ) : null}
      <StatusBar barStyle={STATUS_BAR_STYLE} />
      <NavigationBar
        backgroundColor={config.SPLASH_SCREEN_BACKGROUND_COLOR}
        buttonStyle={NAVIGATION_BAR_BUTTON_STYLE}
      />
    </View>
  )
}
