import React, { useEffect } from "react"
import { Switch, Route, Router, Redirect } from "react-router-dom"

import { useAuth } from "@treefort/lib/auth-provider"

import { useTokens } from "../components/tokens-provider"
import useAppManifest from "../hooks/use-app-manifest"
import analytics from "../lib/analytics"
import { setLocalManifest } from "../lib/app-manifest"
import authenticator from "../lib/authenticator"
import { history } from "../lib/history.web"
import { logError } from "../lib/logging"
import { hideSplashScreen } from "../lib/splash-screen"
import {
  getRouteFromLocation,
  getRouteFromPath,
  getPathAliases,
  ROUTES,
  getPathFromRoute,
} from "./routes"
import { getScreenComponentFromRoute } from "./screens"
import { SplashScreen } from "./screens/splash"

export default function Navigation(): JSX.Element | null {
  const auth = useAuth()
  const { tokens } = useTokens()
  const manifest = useAppManifest()

  // Hide the splash screen and save the manifest to the local cache once we've
  // successfully rendered everything.
  useEffect(() => {
    if (auth.initialized) {
      hideSplashScreen()
      setLocalManifest(manifest)
    }
  }, [manifest, auth.initialized])

  // Update the document background color
  useEffect(() => {
    document.body.style.backgroundColor = tokens.colors.background.primary
  }, [tokens.colors.background.primary])

  useEffect(
    () =>
      history.listen(async (location) => {
        // If our access token could use a refresh then do so now. Doing this will
        // help avoid 401 errors related to using an expired access token (we can
        // recover from those, but they clog up the logs and add latency). Waiting
        // to attempt a refresh until when we know the user is interacting with
        // the app will avoid unnecessary network activity and reduce the chances
        // of a refresh request getting dropped in the background (which could
        // kill the user's session).
        if (await authenticator.shouldAttemptAccessTokenRefresh()) {
          // Don't await here - the refresh can safely happen in the background while
          // the app continues on as usual.
          authenticator.refreshAccessToken().catch(logError)
        }

        // Log the screen change
        analytics.logScreenView(
          getRouteFromLocation(location, manifest),
          manifest,
        )
      }),
    [manifest],
  )

  // Log the initial path
  useEffect(() => {
    analytics.logScreenView(
      getRouteFromLocation(window.location, manifest),
      manifest,
    )
  }, [manifest])

  return auth.initialized ? (
    <Router history={history}>
      <Switch>
        {getPathAliases(manifest).map((pathAlias) => {
          const route = getRouteFromPath(pathAlias.alias, manifest)
          return (
            <Route
              key={pathAlias.alias}
              path={pathAlias.alias}
              exact
              component={getScreenComponentFromRoute(route)}
            >
              {getRedirect({ route, auth })}
            </Route>
          )
        })}
        {ROUTES.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            exact
            component={getScreenComponentFromRoute(route)}
          >
            {getRedirect({ route, auth })}
          </Route>
        ))}
      </Switch>
    </Router>
  ) : (
    <SplashScreen />
  )
}

/**
 * Returns a redirect component for a route if a redirect is necessary
 */
function getRedirect({
  route,
  auth,
}: {
  route: (typeof ROUTES)[number]
  auth: ReturnType<typeof useAuth>
}) {
  const redirectRoute =
    route.authRequired && !auth.user
      ? route.parents
          .map((path) => ROUTES.find((route) => route.path === path))
          .find((route) => route && !route.authRequired)
      : undefined
  return redirectRoute ? (
    <Redirect to={getPathFromRoute({ ...redirectRoute, params: {} })} />
  ) : undefined
}
