import { Platform } from "react-native"

import { Event } from "@treefort/lib/settings"

import { useSetting } from "../hooks/use-setting"
import { AppManifest } from "./app-manifest"
import settings from "./settings"

type ConsentOptionName = keyof typeof CONSENT_OPTION_DEFAULTS

export type ConsentOptionValue = "granted" | "denied"

const CONSENT_OPTION_DEFAULTS = {
  analyticsCookies: "granted",
  advertisingCookies: "granted",
} as const

function getConsentSettingKey(name: ConsentOptionName) {
  return `consent.${name}`
}

export function consentSettingsEnabled(manifest: AppManifest) {
  // Currently the only consent settings we have are for cookies on the web
  return (
    manifest.externalLinks.cookiePolicy !== undefined && Platform.OS === "web"
  )
}

export async function checkConsent(name: ConsentOptionName) {
  const setting = await settings.getLocalOrRemote<ConsentOptionValue>(
    getConsentSettingKey(name),
    { profileId: null },
  )

  return setting.value ?? CONSENT_OPTION_DEFAULTS[name]
}

export async function updateConsent(
  name: ConsentOptionName,
  value: ConsentOptionValue,
) {
  await settings.saveLocalAndRemote(getConsentSettingKey(name), value, {
    profileId: null,
  })
}

export function addConsentListener(
  name: ConsentOptionName,
  listener: (value: ConsentOptionValue) => unknown,
) {
  return settings.on(Event.LocalSettingUpdated, (setting) => {
    if (setting.key === getConsentSettingKey(name)) {
      listener(setting.value as ConsentOptionValue)
    }
  })
}

export function useConsent(name: ConsentOptionName) {
  return useSetting<ConsentOptionValue>({
    key: getConsentSettingKey(name),
    strategy: "localOrRemote",
    settings,
    profileId: null,
    defaultValue: CONSENT_OPTION_DEFAULTS[name] ?? null,
  })
}
