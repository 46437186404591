import React, { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { Pressable } from "react-native"

import { useAuth } from "@treefort/lib/auth-provider"
import { useWillUnmount } from "@treefort/lib/use-will-unmount"

import useAppManifest from "../hooks/use-app-manifest"
import { useNavigate } from "../hooks/use-navigate"
import { useRoute } from "../hooks/use-route"
import authenticator from "../lib/authenticator"
import confirm from "../lib/confirm"
import { restoreAllInAppPurchases } from "../lib/in-app-purchases"
import Column from "./column"
import Text from "./text"

export default function RestorePurchasesLink(): JSX.Element {
  const auth = useAuth()
  const route = useRoute()
  const manifest = useAppManifest()
  const [restoringPurchases, setRestoringPurchases] = useState(false)
  const willUnmount = useWillUnmount()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const onPress = useCallback(async () => {
    if (auth.user === null) {
      const signIn = await confirm({
        title: t("Sign In Required"),
        message: t(
          "You must sign in before you can restore purchases. Would you like to sign in now?",
        ),
        confirmLabel: t("Sign In"),
        cancelLabel: t("Cancel"),
      })
      if (signIn) {
        authenticator.login()
      }
    } else {
      setRestoringPurchases(true)
      if (
        (await restoreAllInAppPurchases()) &&
        (!manifest.features.parentalGateway || route.path.startsWith("/menu"))
      ) {
        navigate("/menu/subscription")
      }

      if (!willUnmount.current) {
        setRestoringPurchases(false)
      }
    }
  }, [
    auth.user,
    manifest.features.parentalGateway,
    route.path,
    willUnmount,
    navigate,
    t,
  ])
  return (
    <Column alignItems="center">
      <Pressable onPress={onPress} disabled={restoringPurchases}>
        <Text textStyle="body" color="accent">
          {restoringPurchases ? t("Please wait...") : t("Restore Purchases")}
        </Text>
      </Pressable>
    </Column>
  )
}
