import { ProrationModesAndroid } from "react-native-iap"

import i18next from "i18next"

import { openStripeCheckout } from "../../stripe"
import { openWebPaymentCheckout } from "../../web-payment"
import { ensureRequestPaymentAllowed } from "../index"
import {
  CheckoutSessionPaidPlan,
  CheckoutSessionPaidPlanResubscription,
} from "../session"

export async function requestPayment({
  checkoutSession,
}: {
  checkoutSession:
    | CheckoutSessionPaidPlan
    | CheckoutSessionPaidPlanResubscription
  oldPurchaseTokenAndroid?: string
  replacementModeAndroid?: ProrationModesAndroid
}): Promise<void> {
  const {
    promoCode,
    plan: { id: subscriptionPlanId },
  } = checkoutSession

  switch (checkoutSession.plan.provider) {
    case "webPayment": {
      await ensureRequestPaymentAllowed("webPayment")
      await openWebPaymentCheckout({
        subscriptionPlanId,
        promoCode,
        i18n: i18next,
      })
      break
    }
    case "stripe": {
      await ensureRequestPaymentAllowed("stripe")
      await openStripeCheckout({ subscriptionPlanId, promoCode })
    }
  }
}
