"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const color_1 = require("@treefort/lib/color");
const helpers_1 = require("../../../lib/helpers");
const base_1 = require("../base");
const billing_interval_toggle_1 = require("./billing-interval-toggle");
const button_1 = require("./button");
const checkout_screen_1 = require("./checkout-screen");
const collection_1 = require("./collection");
const expandable_info_1 = require("./expandable-info");
const feature_1 = require("./feature");
const horizontal_list_view_with_arrows_1 = require("./horizontal-list-view-with-arrows");
const icon_1 = require("./icon");
const links_1 = require("./links");
const locked_content_button_1 = require("./locked-content-button");
const plan_card_1 = require("./plan-card");
const promo_code_input_1 = require("./promo-code-input");
const slider_1 = require("./slider");
const video_module_1 = require("./video-module");
const video_player_1 = require("./video-player");
const appHeaderMaxHeightMobile = 84;
const audioPlayerFloatingArtworkSize = 72;
const audioPlayerFloatingPadding = base_1.spacing.small;
exports.default = {
    slider: slider_1.slider,
    horizontalListViewWithArrows: horizontal_list_view_with_arrows_1.horizontalListViewWithArrows,
    feature: feature_1.feature,
    links: links_1.links,
    promoCodeInput: promo_code_input_1.promoCodeInput,
    button: button_1.button,
    videoModule: video_module_1.videoModule,
    icon: icon_1.icon,
    videoPlayer: video_player_1.videoPlayer,
    collection: collection_1.collection,
    expandableInfo: expandable_info_1.expandableInfo,
    lockedContentButton: locked_content_button_1.lockedContentButton,
    billingIntervalToggle: billing_interval_toggle_1.billingIntervalToggle,
    planCard: plan_card_1.planCard,
    checkoutScreen: checkout_screen_1.checkoutScreen,
    appHeader: {
        mode: (0, helpers_1.platformToken)({
            default: "mobile",
            web: (0, helpers_1.displayWidthToken)({
                0: "mobile",
                [base_1.breakpoints.desktop]: "desktop",
            }),
        }),
        minHeight: (0, helpers_1.platformToken)({
            default: 52,
            web: (0, helpers_1.displayWidthToken)({
                0: 52,
                [base_1.breakpoints.desktop]: 76,
            }),
        }),
        maxHeight: (0, helpers_1.platformToken)({
            default: appHeaderMaxHeightMobile,
            web: (0, helpers_1.displayWidthToken)({
                0: appHeaderMaxHeightMobile,
                [base_1.breakpoints.desktop]: 136,
            }),
        }),
        logoHeight: (0, helpers_1.platformToken)({
            default: 42,
            web: (0, helpers_1.displayWidthToken)({
                0: 42,
                [base_1.breakpoints.desktop]: 64,
            }),
        }),
        logoWidth: (0, helpers_1.platformToken)({
            default: 160,
            web: (0, helpers_1.displayWidthToken)({
                0: 160,
                [base_1.breakpoints.desktop]: 182,
            }),
        }),
        backgroundColor: base_1.colors.background.primary,
        backgroundGradientCoverage: 1.1,
        backgroundGradientColor: (0, color_1.colorWithAlpha)(base_1.colors.black, 0.8),
        dropShadowColor: (0, color_1.colorWithAlpha)(base_1.colors.black, 0.1),
        dropShadowHeight: 12,
        borderColor: (0, helpers_1.displayModeToken)({
            light: "transparent",
            dark: base_1.colors.border.shadowAdjacent.dark,
        }),
        itemColor: {
            opaque: base_1.colors.text.primary,
            transparent: base_1.colors.text.highContrast.dark,
        },
        desktopTabUnderline: {
            color: base_1.colors.accent,
            height: 2,
            // A negative number will stretch the underline wider than the tab text,
            // zero will cause the underline to align with the text, and a positive
            // number will squish the underline narrower than the tab text.
            offsetHorizontal: -6,
        },
    },
    appMenu: {
        // This is currently only applied explicitly on web. The native headers are
        // about the same height anyway, and applying this value on native would be
        // unnecessarily complicated due to platform differences, safe area insets,
        // etc. (react-navigation manages all this for us).
        headerHeight: 52,
        mode: (0, helpers_1.platformToken)({
            default: "mobile",
            web: (0, helpers_1.displayWidthToken)({
                0: "mobile",
                [base_1.breakpoints.desktop]: "desktop",
            }),
        }),
    },
    appFooter: {
        mode: (0, helpers_1.platformToken)({
            default: "mobile",
            web: (0, helpers_1.displayWidthToken)({
                0: "mobile",
                [base_1.breakpoints.desktop]: "desktop",
            }),
        }),
        borderColor: base_1.colors.border.primary,
    },
    lockedContentIndicator: {
        backgroundColor: (0, color_1.colorWithAlpha)(base_1.colors.black, 0.2),
        foregroundColor: base_1.colors.white,
    },
    activityIndicator: {
        // The activity indicator is often swapped in for icons to show a loading
        // state, but our icon images have a bit of padding built into them so the
        // indicator will appear too large if set to the exact same size. To attain
        // a visually similar size we have to scale the indicator down a bit within
        // its container.
        indicatorScale: 0.85,
        rotationsPerSecond: 1.33,
    },
    switch: {
        trackColor: {
            enabled: (0, helpers_1.displayModeToken)({
                light: base_1.colors.gray300,
                dark: base_1.colors.gray600,
            }),
            disabled: (0, helpers_1.displayModeToken)({
                light: base_1.colors.gray100,
                dark: base_1.colors.gray800,
            }),
        },
        thumbColor: {
            enabled: (0, helpers_1.displayModeToken)({
                light: base_1.colors.gray50,
                dark: base_1.colors.gray100,
            }),
            disabled: (0, helpers_1.displayModeToken)({
                light: base_1.colors.white,
                dark: base_1.colors.gray600,
            }),
        },
        accentColor: base_1.colors.accent,
    },
    iconButton: {
        labelMargin: base_1.spacing.xtiny,
    },
    trackPicker: {
        currentDot: {
            color: base_1.colors.accent,
            size: 8,
        },
    },
    toaster: {
        toast: {
            margin: base_1.spacing.medium,
            padding: base_1.spacing.medium,
            backgroundColor: {
                error: base_1.colors.danger,
                success: base_1.colors.success,
            },
            textColor: base_1.colors.white,
            duration: 7000,
            animationDuration: 250,
        },
    },
    audioPlayerFullscreen: {
        maxWidth: 375,
        dropShadowColor: (0, color_1.colorWithAlpha)(base_1.colors.black, 0.15),
        dropShadowHeight: 8,
        paddingVertical: base_1.spacing.small,
        paddingHorizontal: base_1.spacing.xlarge,
        controls: {
            paddingBottom: base_1.spacing.xlarge,
        },
        artwork: {
            paddingTop: base_1.spacing.xsmall,
            paddingBottom: base_1.spacing.xlarge,
        },
    },
    audioPlayerProgress: {
        trackHeight: 4,
        trackBackgroundColor: (0, helpers_1.displayModeToken)({
            light: (0, color_1.colorWithAlpha)(base_1.colors.gray900, 0.2),
            dark: (0, color_1.colorWithAlpha)(base_1.colors.gray100, 0.2),
        }),
        color: (0, helpers_1.displayModeToken)({
            light: (0, color_1.colorWithAlpha)(base_1.colors.gray900, 0.8),
            dark: (0, color_1.colorWithAlpha)(base_1.colors.gray100, 0.8),
        }),
        handleRadius: 7,
        handleColor: (0, helpers_1.displayModeToken)({
            light: base_1.colors.gray900,
            dark: base_1.colors.gray100,
        }),
    },
    audioPlayerInline: {
        height: 60,
        padding: 8,
    },
    audioPlayerFloating: {
        artworkSize: audioPlayerFloatingArtworkSize,
        dropShadow: {
            radius: base_1.spacing.medium,
            color: (0, color_1.colorWithAlpha)(base_1.colors.black, 0.2),
        },
        borderWidth: 1,
        borderColor: base_1.colors.border.shadowAdjacent,
        borderRadius: base_1.borderRadius.roundedLarge,
        padding: audioPlayerFloatingPadding,
        paddingRight: base_1.spacing.large,
        margin: audioPlayerFloatingPadding,
        maxWidth: 700,
        progressWidth: 320,
        controlsWidth: 240,
        closeButton: {
            size: 24,
            backgroundColor: base_1.colors.background.primary,
            borderRadius: base_1.borderRadius.roundedFull,
        },
    },
    artworkListItem: {
        // The maximum width/height of the artwork source. Used for optimization
        // only, does not affect the layout.
        maxSourceSize: 400,
        spacing: base_1.spacing.small,
        shape: {
            square: {
                aspectRatio: 1,
                borderRadius: base_1.borderRadius.rounded,
            },
            tall: {
                aspectRatio: base_1.aspectRatios.tall,
                borderRadius: base_1.borderRadius.rounded,
            },
            wide: {
                aspectRatio: base_1.aspectRatios.wide,
                borderRadius: base_1.borderRadius.rounded,
            },
            circle: {
                aspectRatio: 1,
                borderRadius: base_1.borderRadius.roundedFull,
            },
        },
    },
    artworkFullPage: {
        artworkMaxSize: 480,
        artworkMinSize: 128,
        artworkHeightPercentage: (0, helpers_1.displayWidthToken)({
            0: 0.33,
            [base_1.breakpoints.desktop]: 0.8,
        }),
        artworkWidthPercentage: (0, helpers_1.displayWidthToken)({
            0: 1,
            [base_1.breakpoints.desktop]: 0.45,
        }),
        layout: (0, helpers_1.displayWidthToken)({
            0: "portrait",
            [base_1.breakpoints.desktop]: "landscape",
        }),
    },
    thumbnailListItem: {
        paddingVertical: base_1.spacing.small,
        artwork: {
            height: 88,
            marginRight: 16,
        },
    },
    thumbnailArtwork: {
        // The maximum width/height of the artwork source. Used for optimization
        // only, does not affect the layout.
        maxSourceSize: 400,
        borderRadius: base_1.borderRadius.rounded,
    },
    searchScreen: {
        mode: (0, helpers_1.displayWidthToken)({
            0: "mobile",
            [base_1.breakpoints.desktop]: "desktop",
        }),
        textInput: {
            height: 64,
            paddingLeft: base_1.spacing.small,
            underlineHeight: 1,
        },
        grid: {
            itemsPerRow: 4,
        },
    },
    text: {
        maxWidth: {
            title: 460,
            description: 768,
        },
    },
    textInput: {
        borderRadius: base_1.borderRadius.rounded,
        backgroundColor: base_1.colors.background.tertiary,
        placeholderTextColor: (0, helpers_1.displayModeToken)({
            light: base_1.colors.gray600,
            dark: base_1.colors.gray300,
        }),
        paddingHorizontal: base_1.spacing.small,
        height: 42,
    },
    progressBar: {
        color: {
            fill: base_1.colors.accent,
            empty: base_1.colors.background.secondary,
        },
        thickness: 4,
        animationDuration: 100,
    },
    progressCircle: {
        color: {
            fill: base_1.colors.accent,
            empty: base_1.colors.background.secondary,
        },
        thickness: 2,
        animationDuration: 500,
    },
    library: {
        presentation: (0, helpers_1.displayWidthToken)({
            0: "narrow",
            [base_1.breakpoints.desktop]: "wide",
        }),
        tabs: {
            textStyle: {
                narrow: "headingMedium",
                wide: "headingLarge",
            },
            underlineHeight: 3,
        },
        listItemWide: {
            artworkHeight: 232,
            paddingVertical: base_1.spacing.large,
        },
        emptyMessageHeight: 200,
    },
    settingsRow: {
        padding: {
            vertical: base_1.spacing.small,
            horizontal: base_1.spacing.medium,
        },
        // The 32px here ensures enough space for a Switch component on any
        // platform. We lazy-load some settings that use switches and we don't want
        // the height of the settings row to change when the switch pops in.
        minHeight: 32 + base_1.spacing.small * 2,
    },
    /**
     * MODULES
     */
    bannerModule: {
        gradientCoverage: (0, helpers_1.displayWidthToken)({
            0: 1,
            800: 1.3,
        }),
        gradientOffset: 0,
        backgroundColor: base_1.colors.background.primary.dark,
        scrollIndicator: {
            container: {
                paddingHorizontal: base_1.spacing.large,
                height: 64,
            },
            pill: {
                borderRadius: base_1.borderRadius.rounded,
                height: 4,
                minWidth: 20,
                marginHorizontal: base_1.spacing.tiny,
                maxWidth: 64,
                backgroundColor: {
                    active: (0, color_1.colorWithAlpha)(base_1.colors.white, 0.5),
                    inactive: (0, color_1.colorWithAlpha)(base_1.colors.white, 0.15),
                },
            },
            arrows: {
                show: (0, helpers_1.platformToken)({
                    default: "never",
                    web: (0, helpers_1.displayWidthToken)({
                        0: "never",
                        [base_1.breakpoints.desktop]: "hover",
                    }),
                }),
                color: {
                    enabled: base_1.colors.text.highContrast,
                    disabled: base_1.colors.transparent,
                },
            },
        },
    },
    audiobookModule: {
        playButtonFlex: (0, helpers_1.displayWidthToken)({
            0: true,
            475: false,
        }),
    },
    ebookModule: {
        readButtonFlex: (0, helpers_1.displayWidthToken)({
            0: true,
            475: false,
        }),
    },
    videoSeriesModule: {
        layout: (0, helpers_1.displayWidthToken)({
            0: "portrait",
            800: "landscape",
        }),
        banner: {
            backgroundColor: base_1.colors.background.primary.dark,
            gradientCoverage: 1,
            gradientOffset: (0, helpers_1.displayWidthToken)({
                0: 0,
                800: 0.3,
            }),
        },
        playButtonFlex: (0, helpers_1.displayWidthToken)({
            0: true,
            475: false,
        }),
    },
    podcastModule: {
        artwork: {
            size: 120,
        },
        presentation: (0, helpers_1.displayWidthToken)({
            0: "narrow",
            1024: "wide",
        }),
    },
};
