"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAppManifestLocation = void 0;
/**
 * Returns the location of the app manifest for a particular
 * app/tenant/version/status combo.
 */
const getAppManifestLocation = ({ version, status, tenantId, appId, env, }) => (status === "draft"
    ? { status, apiPath: `/apps/${appId}/manifest/${version}` }
    : {
        status,
        cdnPath: `/public/${tenantId}/apps/${appId}/manifest/${env}-v${version}.json`,
    });
exports.getAppManifestLocation = getAppManifestLocation;
