import React, { useRef, useState, useCallback } from "react"
import { Animated, Easing } from "react-native"
import {
  HandlerStateChangeEvent,
  PanGestureHandlerEventPayload,
  State as PanState,
} from "react-native-gesture-handler"
import { useSafeAreaInsets } from "react-native-safe-area-context"

import styled from "styled-components/native"

import { useWillUnmount } from "@treefort/lib/use-will-unmount"
import tokens from "@treefort/tokens/app"

import config from "../../config"
import { useTracks } from "../../hooks/audio"
import useAppManifest from "../../hooks/use-app-manifest"
import { useBottomTabsHeight } from "../../hooks/use-bottom-tabs-height"
import { useNavigate } from "../../hooks/use-navigate"
import useWindowDimensions from "../../hooks/use-window-dimensions"
import analytics from "../../lib/analytics"
import { useNativeDriver } from "../../lib/animation-use-native-driver"
import { Track } from "../../lib/audio-player"
import { isConsumableContentTrack } from "../../lib/consumable-content"
import { shareUrl } from "../../lib/share-url"
import { startAnimation } from "../../lib/start-animation"
import {
  getPathFromConsumableContent,
  getRouteFromPath,
  NO_TAB,
  SEARCH_TAB,
} from "../../navigation/routes"
import { FullscreenAudioPlayer } from "../audio-player-fullscreen"
import InlineAudioPlayer from "../audio-player-inline"
import BottomTabBar from "../bottom-tab-bar"
import LinearGradient from "../linear-gradient"
import PanGestureHandler from "../pan-gesture-handler"

const Container = styled.View`
  background: ${({ theme }) => theme.colors.background.primary};
`

const FullscreenAudioPlayerContainer = styled(Animated.View)<{
  height: number
  paddingTop: number
  paddingBottom: number
}>`
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: ${(props) => props.height}px;
  padding-top: ${(props) => props.paddingTop}px;
  padding-bottom: ${(props) => props.paddingBottom}px;
  background: ${({ theme }) => theme.colors.background.primary};
`

const FullscreenAudioPlayerDropShadow = styled(LinearGradient)`
  position: absolute;
  top: ${(props) => -props.theme.audioPlayerFullscreen.dropShadowHeight}px;
  height: ${(props) => props.theme.audioPlayerFullscreen.dropShadowHeight}px;
  width: 100%;
`

export default function AppFooterMobile(
  props: Omit<Parameters<typeof BottomTabBar>[0], "height" | "safeAreaInsets">,
): JSX.Element | null {
  const tracks = useTracks()
  const showAudioPlayer = tracks.length > 0
  const safeAreaInsets = useSafeAreaInsets()
  const { height: windowHeight } = useWindowDimensions({
    updateOnHeightChange: true,
  })
  const toggleValue = useRef(new Animated.Value(0))
  const panValue = useRef(new Animated.Value(0))
  const [fullscreenAudioPlayerOpen, setFullscreenAudioPlayerOpen] =
    useState(false)
  const navigate = useNavigate()
  const willUnmount = useWillUnmount()
  const bottomTabsHeight = useBottomTabsHeight()
  const manifest = useAppManifest()

  // Attatch the seekX variable to the pan gesture
  const panGestureEvent = Animated.event(
    [{ nativeEvent: { translationY: panValue.current } }],
    { useNativeDriver },
  )

  // HACK: Add an extra pixel to fill a mysterious 1px gap at the top of the
  // viewport that is present on many devices. A pixel rounding issue? A sneaky
  // border throwing things off? A global consipiracy theory? Who knows.
  const fullscreenAudioPlayerHeight = windowHeight + 1

  // The total height that the full audio player will expand
  const expandHeight =
    fullscreenAudioPlayerHeight -
    tokens.audioPlayerInline.height -
    bottomTabsHeight -
    safeAreaInsets.bottom

  // The animated value that controls how much the player is expanded based on
  // a combination of the toggle value and any pan input from the user (a
  // percentage from 0 to 1).
  const expandValue = Animated.multiply(
    Animated.subtract(
      1,
      Animated.divide(panValue.current, new Animated.Value(expandHeight)),
    ),
    toggleValue.current,
  ).interpolate({
    inputRange: [0, 1],
    outputRange: [0, 1],
    extrapolate: "clamp",
  })

  const animateToggleValue = useCallback(
    (toValue: 1 | 0, callback?: () => void) => {
      const animation = Animated.timing(toggleValue.current, {
        useNativeDriver,
        toValue,
        duration: 700,
        easing: Easing.out(Easing.exp),
      })
      // If we're animating in then make sure the panValue is cleared out
      if (toValue === 1) {
        panValue.current.setValue(0)
      }
      return startAnimation(animation, callback)
    },
    [],
  )

  const onPressMiniPlayer = useCallback(() => {
    setFullscreenAudioPlayerOpen(true)
    animateToggleValue(1)
  }, [animateToggleValue])

  const onClose = useCallback(
    () => animateToggleValue(0, () => setFullscreenAudioPlayerOpen(false)),
    [animateToggleValue],
  )

  const onPressAudioPlayerTitle = useCallback(
    (track: Track) => {
      if (isConsumableContentTrack(track)) {
        const { consumableContent } = track.extra
        const path = getPathFromConsumableContent(consumableContent, NO_TAB)
        navigate(path, undefined, "push")
        onClose()
      }
    },
    [navigate, onClose],
  )

  // Do stuff when the pan gesture state changes (entering a pan, exiting a pan,
  // etc.)
  const onPanGestureChange = useCallback(
    (event: HandlerStateChangeEvent<PanGestureHandlerEventPayload>) => {
      const { state, translationY } = event.nativeEvent
      if (state === PanState.END) {
        // If the user dragged at least 10% of the way closed, finish collapsing
        // for them. Otherwise bounce back to the open state
        if (translationY / expandHeight > 0.1) {
          animateToggleValue(0, () => {
            panValue.current.setValue(0)
            if (!willUnmount.current) {
              setFullscreenAudioPlayerOpen(false)
            }
          })
        } else {
          Animated.timing(panValue.current, {
            useNativeDriver,
            toValue: 0,
            duration: 350,
            easing: Easing.out(Easing.exp),
          }).start()
        }
      }
    },
    [animateToggleValue, expandHeight, willUnmount],
  )

  return (
    <Container>
      {showAudioPlayer ? (
        <PanGestureHandler
          onGestureEvent={panGestureEvent}
          onHandlerStateChange={onPanGestureChange}
          activeOffsetY={[-10, 10]}
          enabled={fullscreenAudioPlayerOpen}
        >
          <FullscreenAudioPlayerContainer
            height={fullscreenAudioPlayerHeight}
            paddingTop={safeAreaInsets.top}
            paddingBottom={safeAreaInsets.bottom}
            style={{
              transform: [
                {
                  translateY: expandValue.interpolate({
                    inputRange: [0, 1],
                    outputRange: [
                      tokens.audioPlayerFullscreen.dropShadowHeight,
                      -expandHeight,
                    ],
                  }),
                },
              ],
            }}
            pointerEvents={!fullscreenAudioPlayerOpen ? "none" : undefined}
          >
            <FullscreenAudioPlayerDropShadow
              fadeTowards="top"
              color={tokens.audioPlayerFullscreen.dropShadowColor}
            />
            <FullscreenAudioPlayer
              open={fullscreenAudioPlayerOpen}
              onClose={onClose}
              onPressTitle={onPressAudioPlayerTitle}
              onShare={async (track: Track) => {
                if (isConsumableContentTrack(track)) {
                  const { consumableContent } = track.extra
                  const path = getPathFromConsumableContent(
                    consumableContent,
                    SEARCH_TAB,
                  )
                  const wasShareSuccessful = await shareUrl({
                    url: `https://${config.DOMAIN_NAME}${path}`,
                  })

                  if (wasShareSuccessful) {
                    const route = getRouteFromPath(path, manifest)
                    analytics.logShare(route, manifest)
                  }
                }
              }}
            />
          </FullscreenAudioPlayerContainer>
        </PanGestureHandler>
      ) : null}
      <Animated.View
        style={{
          transform: [
            {
              translateY: expandValue.interpolate({
                inputRange: [0, 1],
                outputRange: [
                  0,
                  bottomTabsHeight +
                    tokens.audioPlayerInline.height +
                    safeAreaInsets.bottom,
                ],
              }),
            },
          ],
        }}
      >
        {showAudioPlayer ? (
          <InlineAudioPlayer
            onPress={onPressMiniPlayer}
            safeAreaInsets={
              bottomTabsHeight > 0
                ? { ...safeAreaInsets, bottom: 0 }
                : safeAreaInsets
            }
          />
        ) : null}
        {bottomTabsHeight > 0 ? (
          <BottomTabBar
            {...props}
            height={bottomTabsHeight}
            safeAreaInsets={safeAreaInsets}
          />
        ) : null}
      </Animated.View>
    </Container>
  )
}
