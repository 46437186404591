import React, { ReactNode, useCallback, useContext } from "react"
import { Animated } from "react-native"
import { useSafeAreaInsets, EdgeInsets } from "react-native-safe-area-context"

import styled from "styled-components/native"

import useAppManifest from "../../hooks/use-app-manifest"
import { useAppTab } from "../../hooks/use-app-tab"
import { useHistory } from "../../hooks/use-history"
import { useNavigate } from "../../hooks/use-navigate"
import { useRoute } from "../../hooks/use-route"
import { useCurrentRouteScrollOffset } from "../../hooks/use-route-scroll-offset"
import { useSearch } from "../../hooks/use-search"
import { profilesOverlay } from "../../lib/profiles"
import {
  NO_TAB,
  getHomeTab,
  getPathFromAppLink,
  getPathFromTab,
  isLibraryTab,
} from "../../navigation/routes"
import { AsyncViewContext } from "../async-view"
import LinearGradient from "../linear-gradient"
import { NavigationBar } from "../system-bars/navigation-bar"
import TokensProvider, { DisplayMode, useTokens } from "../tokens-provider"
import { AppHeaderStatusBar } from "./status-bar"
import { AppHeaderPresentation, AppHeaderState } from "./types"

const Container = styled(Animated.View)<{
  safeAreaInsets: EdgeInsets
  extraChildrenHeight: number
  appHeaderMaxHeight: number
}>`
  height: ${(props) =>
    props.appHeaderMaxHeight +
    props.extraChildrenHeight +
    props.safeAreaInsets.top +
    props.theme.appHeader.dropShadowHeight}px;
  width: 100%;
  justify-content: flex-end;
`

const Background = styled(Animated.View)<{
  safeAreaInsets: EdgeInsets
  extraChildrenHeight: number
  appHeaderMaxHeight: number
  backgroundColor?: string
}>`
  position: absolute;
  height: ${(props) =>
    props.appHeaderMaxHeight +
    props.extraChildrenHeight +
    props.safeAreaInsets.top}px;
  bottom: ${(props) => props.theme.appHeader.dropShadowHeight}px;
  width: 100%;
  background-color: ${(props) =>
    props.backgroundColor || props.theme.appHeader.backgroundColor};
`

const DropShadowContainer = styled(Animated.View)`
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top-width: ${(props) =>
    props.theme.appHeader.borderColor === "transparent" ? 0 : 1}px;
  border-top-color: ${(props) => props.theme.appHeader.borderColor};
  height: ${(props) => props.theme.appHeader.dropShadowHeight}px;
  z-index: 1;
`

const Content = styled(Animated.View)<{
  safeAreaInsets: EdgeInsets
  extraChildrenHeight: number
}>`
  position: relative;
  flex-direction: row;
  align-items: center;
  height: ${(props) => props.theme.appHeader.minHeight}px;
  padding-left: ${(props) =>
    props.safeAreaInsets.left + props.theme.spacing.xsmall}px;
  padding-right: ${(props) =>
    props.safeAreaInsets.right + props.theme.spacing.xsmall}px;
  margin-bottom: ${(props) =>
    props.extraChildrenHeight + props.theme.appHeader.dropShadowHeight}px;
`

const GradientContainer = styled(Animated.View)`
  position: absolute;
  top: 0;
  height: ${(props) => props.theme.appHeader.backgroundGradientCoverage * 100}%;
  width: 100%;
`

const Gradient = styled(LinearGradient)`
  height: 100%;
  width: 100%;
`

const ExtraChildren = styled.View<{
  safeAreaInsets: EdgeInsets
  extraChildrenHeight: number
}>`
  position: absolute;
  bottom: ${(props) => props.theme.appHeader.dropShadowHeight}px;
  width: 100%;
  height: ${(props) => props.extraChildrenHeight}px;
  overflow: hidden;
  padding-left: ${(props) => props.safeAreaInsets.left}px;
  padding-right: ${(props) => props.safeAreaInsets.right}px;
  justify-content: flex-end;
`

export default function AppHeaderContainer({
  children,
  extraChildren,
  extraChildrenHeight = 0,
  dropShadow = true,
  presentation: presentationProp = "opaque",
  state,
  displayMode: displayModeProp,
  backgroundColor,
}: {
  children: (props: {
    goBack: () => void
    scrollInterpolation: Animated.AnimatedInterpolation<number>
    heightInterpolation: Animated.AnimatedInterpolation<number>
    presentation: AppHeaderPresentation
  }) => JSX.Element
  extraChildren?: ReactNode
  extraChildrenHeight?: number
  dropShadow?: boolean
  presentation?: AppHeaderPresentation
  state?: AppHeaderState
  displayMode?: DisplayMode
  backgroundColor?: string
}): JSX.Element {
  const safeAreaInsets = useSafeAreaInsets()
  const scrollOffset = useCurrentRouteScrollOffset()
  const { tokens } = useTokens()
  const manifest = useAppManifest()
  const tab = useAppTab()
  const route = useRoute()
  const navigate = useNavigate()
  const history = useHistory()
  const search = useSearch()
  const asyncViewContext = useContext(AsyncViewContext)
  const appHeaderMaxHeight =
    state === "collapsed"
      ? tokens.appHeader.minHeight
      : tokens.appHeader.maxHeight
  const appHeaderMinHeight =
    state === "expanded"
      ? tokens.appHeader.maxHeight
      : tokens.appHeader.minHeight

  // Apply any custom presentation or displayMode settings passed in to us, but
  // only when the asyncView is ready. This avoids awkward situations where we
  // might show a transparent header (see the video series page) or a dark mode
  // header (see the video page) over a loading screen.
  const asyncViewReady =
    asyncViewContext.props.state === undefined ||
    asyncViewContext.props.state === "success"
  const presentation = asyncViewReady ? presentationProp : "opaque"
  const displayMode = asyncViewReady ? displayModeProp : undefined

  // Base all our animations off of the scroll offset of the current route
  const heightDelta = appHeaderMaxHeight - appHeaderMinHeight
  const heightInterpolation = scrollOffset.interpolate({
    inputRange: [0, heightDelta * 2],
    outputRange: heightDelta > 0 ? [0, 1] : [1, 1],
    extrapolate: "clamp",
  })
  const containerTranslateY = heightInterpolation.interpolate({
    inputRange: [0, 1],
    outputRange: [0, -heightDelta],
    extrapolate: "clamp",
  })
  const contentTranslateY = heightInterpolation.interpolate({
    inputRange: [0, 1],
    outputRange: [-(heightDelta / 2), 0],
    extrapolate: "clamp",
  })
  // If we're animating the height of the header, match all other animations
  // with that animation. Otherwise time the animation so that it's complete
  // once the user has scrolled the same distance as the minimum header height.
  const scrollInterpolation =
    heightDelta > 0
      ? heightInterpolation
      : scrollOffset.interpolate({
          inputRange: [0, appHeaderMinHeight],
          outputRange: [0, 1],
          extrapolate: "clamp",
        })
  const gradientOpacity = scrollInterpolation.interpolate({
    inputRange: [0, 1],
    outputRange: [1, 0],
  })

  const goBack = useCallback(async () => {
    const libraryTab = manifest.tabs.find(isLibraryTab)
    if (profilesOverlay.getState().open) {
      profilesOverlay.requestBack()
    } else if (route.path === "/search") {
      search.close()
    } else if (route.params?.goBackTo === "search") {
      search.open(route.params.q)
    } else if (route.params?.goBackTo === "library" && libraryTab) {
      navigate(getPathFromTab(libraryTab))
    } else if (
      route.params?.goBackTo === "home" ||
      (tab === NO_TAB && !history.canGoBack())
    ) {
      const homeTab = getHomeTab(manifest)
      navigate(getPathFromAppLink(homeTab.link, homeTab, manifest))
    } else if (history.canGoBack()) {
      history.goBack()
    } else {
      navigate(getPathFromTab(tab))
    }
  }, [manifest, route, history, navigate, tab, search])

  return (
    <TokensProvider displayMode={displayMode}>
      <NavigationBar onPressBack={goBack} />
      <Container
        extraChildrenHeight={extraChildrenHeight}
        appHeaderMaxHeight={appHeaderMaxHeight}
        safeAreaInsets={safeAreaInsets}
        style={
          heightDelta > 0
            ? { transform: [{ translateY: containerTranslateY }] }
            : undefined
        }
        pointerEvents="box-none"
      >
        <AppHeaderStatusBar
          presentation={presentation}
          itemOpacity={scrollInterpolation}
        />
        {presentation === "transparent" ? (
          <GradientContainer
            style={{ opacity: gradientOpacity }}
            pointerEvents="none"
          >
            <Gradient
              fadeTowards="bottom"
              color={tokens.appHeader.backgroundGradientColor}
            />
          </GradientContainer>
        ) : null}
        <Background
          extraChildrenHeight={extraChildrenHeight}
          appHeaderMaxHeight={appHeaderMaxHeight}
          safeAreaInsets={safeAreaInsets}
          key={
            // Re-mounting an animated view when detaching or re-attaching an
            // animated value avoids odd rendering problems where styles get out
            // of sync with the animated value.
            `background-${presentation}`
          }
          style={{
            opacity:
              presentation === "transparent" ? scrollInterpolation : undefined,
          }}
          backgroundColor={backgroundColor}
        />
        {dropShadow ? (
          <DropShadowContainer
            pointerEvents="none"
            style={{ opacity: scrollInterpolation }}
          >
            <Gradient
              fadeTowards="bottom"
              color={tokens.appHeader.dropShadowColor}
            />
          </DropShadowContainer>
        ) : null}
        <Content
          safeAreaInsets={safeAreaInsets}
          extraChildrenHeight={extraChildrenHeight}
          style={
            heightDelta > 0
              ? { transform: [{ translateY: contentTranslateY }] }
              : undefined
          }
        >
          {children({
            goBack,
            heightInterpolation,
            scrollInterpolation,
            presentation,
          })}
        </Content>
        {extraChildren && extraChildrenHeight ? (
          <ExtraChildren
            safeAreaInsets={safeAreaInsets}
            extraChildrenHeight={extraChildrenHeight}
          >
            {extraChildren}
          </ExtraChildren>
        ) : null}
      </Container>
    </TokensProvider>
  )
}
