import { Model } from "@nozbe/watermelondb"
import { nochange, field, date } from "@nozbe/watermelondb/decorators"

import { SYNC_IGNORE_PREFIX } from "../stores/types"

export const CREATED_LOCALLY_FIELD_NAME = `${SYNC_IGNORE_PREFIX}createdLocally`

// This abstract class represents a Model that tracks whether it
// was created on this device by saving a boolean to a special
// column in the local watermelon database which will not be synced
// to the server. This column should only be set on model create.
export abstract class SourceAwareModel extends Model {
  @nochange
  @field(CREATED_LOCALLY_FIELD_NAME)
  syncIgnore_createdLocally!: boolean

  // HACK: For the following create/update date fields, we have to avoid
  // using "createdAt" and "updatedAt" because watermelon assumes the underlying
  // database fields are "created_at" and "updated_at" and currently has no
  // way to change those.
  @nochange
  @date("createdAtDate")
  createdAtDate?: number
  @date("updatedAtDate")
  updatedAtDate?: number
}
