import { t } from "i18next"
import throttle from "lodash/throttle"

import { DisplayableError } from "@treefort/lib/displayable-error"

import DownloadItem from "../lib/download-item"
import { logError } from "../lib/logging"
import { getNetworkState, isOfflineState } from "../lib/network-state"
import {
  PlayableProgressItemEvent,
  VideoProgressItem,
  fetchProgressItemFromSettings,
} from "../lib/progress-item"
import {
  Event,
  PlaybackState,
  Progress,
  VideoPlayer,
} from "../lib/video-player"
import analytics from "./analytics"
import {
  LOCAL_SYNC_INTERVAL,
  MILLISECONDS_FROM_END_TO_COUNT_AS_FINISHED,
  REMOTE_SYNC_INTERVAL,
} from "./av/constants"
import {
  getTracksFromConsumableContent,
  VideoConsumableContent,
} from "./consumable-content"
import { PlayProgressTracker } from "./play-progress-tracker"

/**
 * Adds listeners for changes in progress, playback rate, and download status
 * for a particular piece of content/progress item. returns A function to remove
 * the listeners.
 */
const addListeners = ({
  videoPlayer,
  consumableContent,
  progressItem,
}: {
  videoPlayer: VideoPlayer
  consumableContent: VideoConsumableContent
  progressItem: VideoProgressItem
}): (() => void) => {
  const playProgressTracker = new PlayProgressTracker({
    playbackState: PlaybackState.Idle,
    playbackRate: progressItem.getPlaybackRate(),
  })

  const saveLocalProgress = throttle(
    () => progressItem.saveLocal(),
    LOCAL_SYNC_INTERVAL,
    { leading: true, trailing: true },
  )

  const saveLocalAndRemoteProgress = throttle(
    () => {
      progressItem.saveLocalAndRemote()
      analytics.logPlayProgress({
        consumableContent,
        progressItem,
        playProgressTracker,
        maxLoggableEnagementTime: REMOTE_SYNC_INTERVAL * 1.5,
      })
    },
    REMOTE_SYNC_INTERVAL,
    { leading: true, trailing: true },
  )

  progressItem.on(
    PlayableProgressItemEvent.ProgressUpdated,
    ({ updatedFields }) => {
      // Call the throttled "save progress" functions
      saveLocalProgress()
      saveLocalAndRemoteProgress()

      // If something other than the position changed (e.g. a change in playback
      // rate, track, etc.) then save right away
      if (updatedFields.some((field) => field !== "position")) {
        saveLocalAndRemoteProgress.flush()
      }
    },
  )

  const removeProgressListener = videoPlayer.on(
    Event.Progress,
    ({ position, duration }: Progress) => {
      // Ignore events where duration = 0 (we can't save progress if duration
      // hasn't loaded yet) or where position = 0 (there's no progress to
      // save...)
      if (duration === 0 || position === 0) return

      playProgressTracker.logProgress()

      progressItem.updateProgress({ position })
      if (duration - position <= MILLISECONDS_FROM_END_TO_COUNT_AS_FINISHED) {
        if (!progressItem.getProgress().finished) {
          progressItem.updateProgress({ finished: true })
        }
      } else if (position > 0) {
        if (progressItem.getProgress().finished) {
          progressItem.updateProgress({ finished: false })
        }
      }
    },
  )

  const removePlaybackRateListener = videoPlayer.on(
    Event.PlaybackRate,
    (playbackRate) => {
      progressItem.setPlaybackRate(playbackRate)
      playProgressTracker.setPlaybackRate(playbackRate)
    },
  )

  const removeFinishedListener = videoPlayer.on(Event.Finished, () =>
    progressItem.updateProgress({ finished: true }),
  )

  // Reset the "save progress" functions after a seek so that the next reported
  // progress is saved immediately.
  const removeSeekedListener = videoPlayer.on(Event.Seeked, () => {
    saveLocalProgress.cancel()
    saveLocalAndRemoteProgress.cancel()
  })

  const removePlaybackStateListener = videoPlayer.on(
    Event.PlaybackState,
    (state) => {
      // Save progress right away when the user pauses
      if (state === PlaybackState.Paused) {
        saveLocalAndRemoteProgress.flush()
      }

      playProgressTracker.setPlaybackState(state)
    },
  )

  // Save progress when the user stops or suspends the video player
  const removeWillStopListener = videoPlayer.on(
    [Event.WillStop, Event.Suspended],
    () => saveLocalAndRemoteProgress.flush(),
  )

  return () => {
    saveLocalProgress.cancel()
    saveLocalAndRemoteProgress.flush()
    progressItem.removeAllListeners()
    removeProgressListener()
    removePlaybackRateListener()
    removeFinishedListener()
    removeSeekedListener()
    removePlaybackStateListener()
    removeWillStopListener()
  }
}

/**
 * This takes in a ConsumableContentVideo object and creates a VideoPlayer
 * instance that can be passed to the VideoPlayerInline component to render the
 * video.
 */
export async function playContentVideo({
  consumableContent,
  profileId,
}: {
  consumableContent: VideoConsumableContent
  profileId: string | null
}): Promise<VideoPlayer | undefined> {
  const videoPlayer = new VideoPlayer()
  try {
    videoPlayer.publishPlayIntent()

    // Make sure we're either online or the video is downloaded
    const downloadItem = new DownloadItem({ consumableContent })
    const [networkState, downloadItemState] = await Promise.all([
      getNetworkState(),
      downloadItem.getState(),
    ])
    if (
      isOfflineState(networkState) &&
      downloadItemState.type !== "downloaded"
    ) {
      throw new DisplayableError(
        t("Cannot play video - no network connection."),
      )
    }

    // Make sure we were given a video to play
    const tracks = getTracksFromConsumableContent({
      consumableContent: await downloadItem.getOfflineConsumableContent(),
      profileId,
    })
    if (tracks.length === 0) {
      throw new DisplayableError(
        t("Please update your app to access this content."),
        `[Content] No compatible tracks found for content "${consumableContent.content.id}". Client is likely outdated.`,
      )
    }

    // Load progress
    const { progressItem } = await fetchProgressItemFromSettings({
      consumableContent,
      profileId,
      strategy: "localOrRemote",
    })
    progressItem.setPlayMode("watch")

    // Start playback
    videoPlayer.setTrack(tracks[0], {
      initialPosition: progressItem.getProgress().finished
        ? 0
        : progressItem.getProgress().position,
      playbackRate: progressItem.getPlaybackRate(),
    })

    // Add progress syncing listeners _after_ we've loaded the new track to
    // avoid accidentally signing up for progress updates from an old track.
    // Remove the listeners the next time the video player track changes (or is
    // cleared out).
    addListeners({
      videoPlayer,
      consumableContent,
      progressItem,
    })

    // Log a play request but don't block playback while we do so
    analytics.logPlayRequest({ consumableContent, progressItem })

    return videoPlayer
  } catch (error) {
    logError(
      error instanceof DisplayableError
        ? error
        : new DisplayableError(
            t("An error occurred. Please contact us if the issue persists."),
            error,
          ),
    )
  }
}
