import React from "react"

import { Result } from "@treefort/lib/result"

import { AsyncViewOfflineProvider } from "../../components/async-view-offline-provider"
import { useAsyncViewPropsForQueries } from "../../hooks/use-async-view-props-for-queries"
import useContent from "../../hooks/use-content"
import { useRoute } from "../../hooks/use-route"
import { ModuleLayout } from "../layouts/module"
import { WebEmbedListViewModule } from "../layouts/module/list-view-modules/web-embed"
import { CardListViewModule } from "../layouts/module/list-view-modules/card"
import useAppManifest from "../../hooks/use-app-manifest"
import { SliderListViewModule } from "../layouts/module/list-view-modules/slider"
import { i18nKey } from "../../types/i18next"
import { useTranslation } from "react-i18next"

export function ContentWebEmbedScreen(): JSX.Element {
  const manifest = useAppManifest()
  const { t } = useTranslation()
  const { params } = useRoute<"contentId">()

  if (!params.contentId) {
    throw new Error(`Visited the webEmbed screen without a content id!`)
  }

  const contentId = parseInt(params.contentId)

  const content = useContent(contentId, "webEmbed")
  const asyncViewProps = useAsyncViewPropsForQueries(content)
  const key = `webEmbed-page-${contentId}`

  return (
    <AsyncViewOfflineProvider
      key={key}
      {...(content.isLoading
        ? asyncViewProps
        : // Pass async view state handling back to children once the content has
          // loaded
          null)}
    >
      <ModuleLayout
        pageKey={key}
        listViewModules={Result.mapSuccess(content, (data) => {
          const isInline =
            data.availability.status === "notAvailable" ||
            data.details.display === "inline"

          return Result.success([
            new WebEmbedListViewModule({
              id: 0,
              type: "webEmbed",
              config: {
                contentId,
                display: isInline ? "inline" : "fullscreen",
              },
              conditions: {},
            }),
            ...(isInline && data.links.length
              ? [
                  new CardListViewModule({
                    id: -1,
                    type: "card",
                    config: {
                      items: data.links,
                    },
                    conditions: {},
                  }),
                ]
              : []),
            ...(isInline && manifest.features.contentPageRecommendations
              ? [
                  new SliderListViewModule(
                    {
                      id: -2,
                      type: "slider",
                      config: {
                        title: t(
                          manifest.strings
                            .contentPageRecommendationsTitle as i18nKey,
                        ),
                        itemShape: "wide",
                        size: "medium",
                        showItemTitles: true,
                        items: {
                          type: "recombee",
                          recommendation: {
                            type: "itemsToItem",
                            contentId,
                            personalized:
                              manifest.features.contentPageRecommendations
                                .personalized,
                            scenario:
                              manifest.features.contentPageRecommendations
                                .scenario,
                          },
                        },
                      },
                      conditions: {},
                    },
                    { key: `content-page-recommendations-${contentId}` },
                  ),
                ]
              : []),
          ])
        })}
      />
    </AsyncViewOfflineProvider>
  )
}
