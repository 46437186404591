import React from "react"
import { useTranslation } from "react-i18next"
import { useWindowDimensions } from "react-native"
import { View } from "react-native"

import styled from "styled-components/native"

import {
  ContentBaseResponse,
  DateDisplayStrategy,
  Shape,
} from "@treefort/api-spec"

import ArtworkListItem, {
  TEXT_GAP_VERTICAL,
  TEXT_MARGIN_VERTICAL,
  TEXT_STYLE_SUBTITLE,
  TEXT_STYLE_TITLE,
} from "../../components/artwork-list-item"
import { AsyncViewOfflineProvider } from "../../components/async-view-offline-provider"
import CenteredContent from "../../components/centered-content"
import { Heading } from "../../components/heading"
import Row from "../../components/row"
import Spacer from "../../components/spacer"
import { ResolvedTokens, useTokens } from "../../components/tokens-provider"
import { useAsyncViewPropsForQueries } from "../../hooks/use-async-view-props-for-queries"
import useCollection from "../../hooks/use-collection"
import { useRoute } from "../../hooks/use-route"
import useSafeAreaInsetAppHeader from "../../hooks/use-safe-area-inset-app-header"
import { formatDate } from "../../lib/date"
import { getAbsoluteLineHeight } from "../../lib/text-style"
import PageLayout from "../layouts/page"
import { ListViewProps } from "../layouts/scrollable"

const RENDER_AHEAD_OFFSET = 240
const SINGLE_LINE_TITLE_BREAKPOINT = 320
const PLACEHOLDER_ITEM = "PLACEHOLDER_ITEM"

function getCollectionDimensions({
  tokens,
  itemShape,
  showTitles,
  showDates,
  windowWidth,
}: {
  tokens: ResolvedTokens
  itemShape: Shape
  showTitles: boolean
  showDates: DateDisplayStrategy
  windowWidth: number
}) {
  const rowWidth =
    windowWidth < tokens.maxPageWidth + tokens.spacing.pagePaddingHorizontal * 2
      ? windowWidth - tokens.spacing.pagePaddingHorizontal * 2
      : tokens.maxPageWidth
  const gap = tokens.artworkListItem.spacing
  const itemAspectRatio = tokens.aspectRatios[itemShape]
  const itemsPerRow = tokens.collection.shape[itemShape].perRow
  const itemWidth = (rowWidth - gap * (itemsPerRow - 1)) / itemsPerRow
  const itemTitleNumberOfLines = !showTitles
    ? 0
    : itemWidth < SINGLE_LINE_TITLE_BREAKPOINT
    ? 2
    : 1
  const itemTitleHeight =
    itemTitleNumberOfLines > 0
      ? getAbsoluteLineHeight(TEXT_STYLE_TITLE, tokens) * itemTitleNumberOfLines
      : 0
  const itemSubtitleNumberOfLines = showDates !== "hidden" ? 1 : 0
  const itemSubtitleHeight =
    itemSubtitleNumberOfLines > 0
      ? getAbsoluteLineHeight(TEXT_STYLE_SUBTITLE, tokens) *
        itemSubtitleNumberOfLines
      : 0
  const itemTextHeight =
    itemTitleHeight +
    itemSubtitleHeight +
    (itemTitleHeight && itemSubtitleHeight ? TEXT_GAP_VERTICAL : 0) +
    (itemTitleHeight || itemSubtitleHeight ? TEXT_MARGIN_VERTICAL * 2 : 0)
  const artworkHeight = itemWidth / itemAspectRatio
  const rowHeight = artworkHeight + itemTextHeight
  return {
    itemsPerRow,
    rowHeight,
    itemTitleNumberOfLines,
    itemSubtitleNumberOfLines,
  }
}

const PlaceholderItem = styled.View<{ withoutRightSpacing: boolean }>`
  flex: 1;
  align-self: stretch;
  ${({ withoutRightSpacing, theme }) =>
    withoutRightSpacing
      ? ""
      : `margin-right: ${theme.artworkListItem.spacing}px;`}
`

export function CollectionScreen(): JSX.Element {
  const appHeaderSafeAreaInset = useSafeAreaInsetAppHeader()
  const { tokens } = useTokens()
  const { i18n } = useTranslation()
  const { width: windowWidth } = useWindowDimensions()
  const { params } = useRoute<"collectionId">()
  const collectionId = params.collectionId
    ? parseInt(params.collectionId)
    : undefined

  if (!collectionId || Number.isNaN(collectionId)) {
    throw new Error(
      "Visited the collection screen without a valid collection id!",
    )
  }

  const collection = useCollection(collectionId)

  const dimensions = collection.data
    ? getCollectionDimensions({
        tokens,
        itemShape: collection.data.shape,
        showTitles: collection.data.showTitles,
        showDates: collection.data.showDates,
        windowWidth,
      })
    : undefined

  const rows =
    collection.data?.content.length && dimensions?.itemsPerRow
      ? collection.data?.content?.reduce((rows, item, i, content) => {
          const row =
            !rows.length ||
            rows[rows.length - 1].length === dimensions.itemsPerRow
              ? (rows[rows.length] = [])
              : rows[rows.length - 1]
          row.push(item)
          if (i === content.length - 1) {
            row.push(
              ...Array(dimensions.itemsPerRow - row.length).fill(
                PLACEHOLDER_ITEM,
              ),
            )
          }
          return rows
        }, [] as (ContentBaseResponse | typeof PLACEHOLDER_ITEM)[][]) ||
        ([] as (ContentBaseResponse | typeof PLACEHOLDER_ITEM)[][])
      : undefined

  const listViewProps:
    | ListViewProps<Array<typeof PLACEHOLDER_ITEM | ContentBaseResponse>>
    | undefined =
    collection.isSuccess && rows && dimensions
      ? {
          renderAheadOffset: RENDER_AHEAD_OFFSET,
          paddingEnd: tokens.spacing.large,
          items: rows,
          getItemKey: (row) =>
            row
              .map((item) =>
                item === PLACEHOLDER_ITEM ? "placeholder" : item.id,
              )
              .join(""),
          getItemSize: (_, i) =>
            (i === 0
              ? appHeaderSafeAreaInset +
                (tokens.appHeader.mode === "desktop"
                  ? getAbsoluteLineHeight("headingXLarge", tokens) +
                    tokens.spacing.xlarge
                  : 0)
              : 0) + dimensions.rowHeight,
          getGapSize: () => tokens.artworkListItem.spacing,
          renderItem: (row, i) => (
            <CenteredContent>
              {i === 0 ? <Spacer size={appHeaderSafeAreaInset} /> : null}
              <View>
                {tokens.appHeader.mode === "desktop" && i === 0 ? (
                  <>
                    <Heading level={2} textStyle="headingXLarge">
                      {collection.data.title}
                    </Heading>
                    <Spacer size="xlarge" />
                  </>
                ) : null}
                <Row alignItems="flex-start">
                  {row.map((item, j) =>
                    item === PLACEHOLDER_ITEM ? (
                      <PlaceholderItem
                        key={`${j}-placeholder-item`}
                        withoutRightSpacing={!(j < row.length - 1)}
                      />
                    ) : (
                      <ArtworkListItem
                        withoutRightSpacing={!(j < row.length - 1)}
                        withoutBottomSpacing
                        titleNumberOfLines={dimensions.itemTitleNumberOfLines}
                        subtitleNumberOfLines={
                          dimensions.itemSubtitleNumberOfLines
                        }
                        key={`${j}-${
                          item
                            ? item.title + "-" + item.id
                            : "loading-placeholder"
                        }`}
                        artworkShape={collection.data.shape}
                        data={
                          item
                            ? {
                                locked:
                                  item.availability.status === "notAvailable",
                                title: item.title,
                                subtitle: item.publishedAt
                                  ? formatDate(new Date(item.publishedAt), {
                                      strategy: collection.data.showDates,
                                      i18n,
                                    })
                                  : undefined,
                                artwork: item.artworkMedia?.original.url,
                                link: {
                                  type: "content",
                                  contentType: item.type,
                                  contentId: item.id,
                                },
                                isLoading: false,
                              }
                            : {
                                isLoading: true,
                              }
                        }
                      />
                    ),
                  )}
                </Row>
              </View>
            </CenteredContent>
          ),
        }
      : undefined

  const asyncViewProps = useAsyncViewPropsForQueries(collection)

  return (
    <AsyncViewOfflineProvider {...asyncViewProps}>
      <PageLayout listViewProps={listViewProps} />
    </AsyncViewOfflineProvider>
  )
}
