import React from "react"
import { AppManifest } from "../../lib/app-manifest"

export type AppManifestData =
  | { state: "loading" }
  | { state: "error"; error: unknown }
  | { state: "loaded"; manifest: AppManifest; forceRefreshCount?: number }

export const AppManifestContext = React.createContext<{
  manifest: AppManifest
  refreshManifest: () => Promise<void>
} | null>(null)
