import { Platform } from "react-native"

import type {
  AudiobookResponse,
  VideoResponse,
  PodcastResponse,
  VideoSeriesResponse,
  ContentType,
  EbookResponse,
  ContentResponse,
  AppModule,
  WebEmbedResponse,
} from "@treefort/api-spec"
import icons from "@treefort/tokens/app/icons"

import api from "./api"
import { stringKeyLookup } from "./i18n/string-key-lookup"

export type ContentResponseForType<T extends ContentType | undefined> =
  T extends undefined
    ? ContentResponse
    : T extends "video"
      ? VideoResponse
      : T extends "videoSeries"
        ? VideoSeriesResponse
        : T extends "podcast"
          ? PodcastResponse
          : T extends "book"
            ? AudiobookResponse
            : T extends "ebook"
              ? EbookResponse
              : T extends "webEmbed"
                ? WebEmbedResponse
                : void

const NO_PROGRESS_CONTENT_TYPES = [
  "videoSeries",
  "podcast",
  "webEmbed",
] satisfies ContentType[]

export const CONTENT_ACTIONS: Record<
  ContentType,
  keyof typeof stringKeyLookup.playAction
> = {
  book: "listen",
  podcast: "listen",
  ebook: "read",
  video: "watch",
  videoSeries: "watch",
  webEmbed: "view",
}

export const CONTENT_ACTION_ICONS: Record<
  keyof typeof stringKeyLookup.playAction,
  string | undefined
> = {
  listen: icons.headphones,
  read: icons.openBook,
  watch: icons.play,
  view: undefined,
}

export async function getContent<T extends ContentType | undefined>(
  id: number,
  type?: T,
): Promise<ContentResponseForType<T>> {
  const result = await api.get<ContentResponseForType<T>>(`/content/${id}`, {
    params: { platform: Platform.OS },
  })
  if (type && result.data.type !== type) {
    throw new Error(
      `[Content] Fetched ${id} expecting a ${type} but got a ${result.data.type}`,
    )
  }
  return result.data
}

export async function getModulesForContent(contentId: number) {
  const result = await api.get<{ modules: AppModule[] }>(
    `/content/${contentId}/modules`,
  )
  return result.data.modules
}

export function shouldShowProgress(
  contentType: ContentType,
): contentType is Exclude<
  ContentType,
  (typeof NO_PROGRESS_CONTENT_TYPES)[number]
> {
  return !(NO_PROGRESS_CONTENT_TYPES as string[]).includes(contentType)
}

/**
 * Returns a subtitle for a piece of content.
 */
function getSubtitleFromContent(content: ContentResponse) {
  switch (content.type) {
    case "video":
      return content.details.videoSeries.length === 1
        ? content.details.videoSeries[0].title
        : undefined
    case "book":
    case "ebook":
      return content.details.author
    case "podcast":
      return content.details.host
    default:
      return undefined
  }
}

/**
 * Returns normalized metadata for any type of content. Useful for display in
 * lists.
 */
export function getMetadataFromContent(content: ContentResponse) {
  return {
    title: content.title,
    displayTypeStringKey: stringKeyLookup.contentType[content.type],
    subtitle: getSubtitleFromContent(content),
    artwork: content.artworkMedia?.original.url,
    description: content.description,
  }
}
