import i18next, { i18n } from "i18next"

import {
  StripeBillingPortalSessionRequest,
  StripeCheckoutSessionRequest,
  StripeSessionResponse,
} from "@treefort/api-spec"
import { DisplayableError } from "@treefort/lib/displayable-error"

import config from "../config"
import api from "./api"
import { checkoutSessionManager } from "./checkout"
import { getTreefortMessageFromError } from "./get-treefort-message-from-error"
import { logError } from "./logging"

export async function openStripeCheckout({
  subscriptionPlanId,
  promoCode,
}: {
  subscriptionPlanId: number
  promoCode?: string
}): Promise<void> {
  try {
    const data: StripeCheckoutSessionRequest = {
      appId: config.APP_ID,
      subscriptionPlanId,
      promoCode,
      locale: i18next.language,
    }
    const res = await api.post<StripeSessionResponse>(
      "/integrations/stripe/checkout-session",
      data,
    )
    window.location.href = res.data.sessionUrl
  } catch (error) {
    await checkoutSessionManager.endSession({ complete: false })
    logError(new DisplayableError(getTreefortMessageFromError(error), error))
  }
}

export async function openStripeBillingPortal({
  i18n,
}: {
  i18n: i18n
}): Promise<void> {
  try {
    const data: StripeBillingPortalSessionRequest = {
      appId: config.APP_ID,
      locale: i18n.language,
    }
    const res = await api.post<StripeSessionResponse>(
      "/integrations/stripe/billing-portal-session",
      data,
    )
    window.location.href = res.data.sessionUrl
  } catch (error) {
    logError(
      new DisplayableError(
        i18n.t(
          "An error occurred opening the billing portal. Please try again.",
        ),
        error,
      ),
    )
  }
}
