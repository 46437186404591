import { Platform } from "react-native"

import { IntegrationProvider } from "@treefort/api-spec"

const PAYMENT_PROVIDERS = [
  "stripe",
  "appStore",
  "playStore",
  "webPayment",
] as const

export function canPurchasePlanFromProvider(
  provider: IntegrationProvider,
): boolean {
  switch (provider) {
    case "stripe":
    case "webPayment":
    case "groupMembership":
      return Platform.OS === "web"
    case "appStore":
      return Platform.OS === "ios"
    case "playStore":
      return Platform.OS === "android"
    default:
      return false
  }
}

export function canOpenBillingPortalForProvider(
  provider: IntegrationProvider,
): boolean {
  switch (provider) {
    case "stripe":
    case "webPayment":
      return Platform.OS === "web"
    case "appStore":
      return Platform.OS === "ios" || Platform.OS === "web"
    case "playStore":
      return Platform.OS === "android" || Platform.OS === "web"
    default:
      return false
  }
}

export function canCancelPlanFromProvider(
  provider: IntegrationProvider,
): boolean {
  switch (provider) {
    case "stripe":
    case "webPayment":
      // Only allow cancellation of web plans on the web. We don't want to
      // encourage users to shoot themselves in the foot by moving to App Store
      // or Play Store subscriptions which are usually more expensive, harder to
      // cancel, harder to refund, and are just generally customer service
      // nightmares
      return Platform.OS === "web"
    case "playStore":
    case "groupMembership":
      return true
    default:
      return false
  }
}

export function getPaymentProvidersForPlatform() {
  return PAYMENT_PROVIDERS.filter(canPurchasePlanFromProvider)
}
