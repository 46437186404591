import React, { useCallback, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"

import styled from "styled-components/native"

import { ContentResponse } from "@treefort/api-spec"
import { MediaImageFile, MediaUrl } from "@treefort/api-spec"
import { isAxiosError } from "@treefort/lib/is-axios-error"
import { ResolvedTokens } from "@treefort/tokens/app"
import icons from "@treefort/tokens/app/icons"

import { useActiveProfileId } from "../../hooks/use-active-profile-id"
import { useBooleanState } from "../../hooks/use-boolean-state"
import useContent from "../../hooks/use-content"
import { useNavigate } from "../../hooks/use-navigate"
import { useOpenCheckoutPage } from "../../hooks/use-open-checkout-page"
import {
  canDownloadConsumableContent,
  getMetadataFromConsumableContent,
  getConsumableContentFromContentResponse,
  ConsumableContent,
  getArtworkMediaFromConsumableContent,
} from "../../lib/consumable-content"
import {
  getMetadataFromContent,
  shouldShowProgress,
  CONTENT_ACTIONS,
  CONTENT_ACTION_ICONS,
} from "../../lib/content"
import { playContentAudio } from "../../lib/content-audio"
import { readContentEbook } from "../../lib/content-ebook"
import { stringKeyLookup } from "../../lib/i18n/string-key-lookup"
import { LibraryItem, getLibraryItemKey } from "../../lib/library"
import { logError } from "../../lib/logging"
import {
  PodcastEpisodeProgressItem,
  VideoProgressItem,
} from "../../lib/progress-item"
import settings from "../../lib/settings"
import { NO_TAB, getPathFromContent } from "../../navigation/routes"
import { i18nKey } from "../../types/i18next"
import { Button } from "../button"
import CenteredContent from "../centered-content"
import Column from "../column"
import DownloadButton from "../download-button"
import { EbookHighlightsSelect } from "../ebook-highlights-select"
import Icon, { IconProps } from "../icon"
import LockedContentButton from "../locked-content-button"
import Modal from "../modal"
import ProgressForConsumableContent from "../progress-for-consumable-content"
import Row from "../row"
import Spacer from "../spacer"
import Text from "../text"
import { SquareMediaThumbnail, SquareThumbnail } from "../thumbnail"
import ThumbnailListItem from "../thumbnail-list-item"
import { Tokens, useTokens } from "../tokens-provider"
import Touchable from "../touchable"

interface ListItemProps {
  content: ContentResponse
  consumableContent?: ConsumableContent
  artworkMedia?: MediaImageFile | MediaUrl
  libraryItem: LibraryItem
  removeLabel: i18nKey
  onPressOpen: (videoPlayMode?: "watch" | "listen") => void
  onPressMarkFinished?: () => void
  onPressRemove?: () => void
}

const WideContentContainer = styled(Column).attrs(() => ({
  gap: "medium",
  alignItems: "stretch",
}))`
  flex: 1;
  max-width: ${(props) => props.theme.text.maxWidth.description}px;
`

const DownloadButtonRow = styled(Row)`
  margin-left: -${(props) => props.theme.spacing.xsmall}px;
`

function WideHeading({
  title,
  subtitle,
}: {
  title: string
  subtitle?: string
}) {
  return (
    <Column gap="small" alignItems="stretch">
      <Text textStyle="headingLarge" numberOfLines={1}>
        {title}
      </Text>
      {subtitle ? (
        <Text textStyle="captionStrong" color="secondary">
          {subtitle}
        </Text>
      ) : null}
    </Column>
  )
}

function PlainActionButton({
  onPress,
  children,
  icon,
}: {
  onPress: () => void
  children: string
  icon: IconProps["source"]
}): JSX.Element {
  const { tokens } = useTokens()
  return (
    <Touchable onPress={onPress} feedback="opacity">
      <Row height={tokens.button.height.medium} gap="xsmall">
        <Icon source={icon} size="small" color="secondary" />
        <Text textStyle="caption" color="secondary">
          {children}
        </Text>
      </Row>
    </Touchable>
  )
}

/**
 * Show this in place of LibraryListItemWide while content is loading. This is
 * not designed to match the layout of a loaded item exactly (which is very hard
 * to know ahead of time). Instead this is intended as a reasonably aesthetic
 * placeholder while we get our act together.
 */
function LibraryListItemWidePlaceholder({
  title,
  subtitle,
}: {
  title?: string
  subtitle?: string
}): JSX.Element {
  const { tokens } = useTokens()
  return (
    <CenteredContent
      paddingVertical={tokens.library.listItemWide.paddingVertical}
    >
      <Row gap="xlarge">
        <SquareThumbnail size={tokens.library.listItemWide.artworkHeight} />
        <WideContentContainer>
          {title ? (
            <WideHeading title={title} subtitle={subtitle} />
          ) : (
            <>
              <Text textStyle="headingLarge" loading loadingWidth={560} />
              <Text
                textStyle="body"
                loading
                loadingWidth={360}
                loadingNumberOfLines={2}
              />
              <Text textStyle="body" loading loadingWidth={300} />
            </>
          )}
        </WideContentContainer>
      </Row>
    </CenteredContent>
  )
}

/**
 * Render the library list item in "wide" presentation mode (used on desktop and
 * tablets).
 */
function LibraryListItemWide({
  content,
  consumableContent,
  libraryItem,
  artworkMedia,
  removeLabel,
  onPressOpen,
  onPressMarkFinished,
  onPressRemove,
}: ListItemProps): JSX.Element {
  const { tokens } = useTokens()
  const { title, subtitle, description } = consumableContent
    ? getMetadataFromConsumableContent(consumableContent)
    : getMetadataFromContent(content)
  const showDownloadButton =
    consumableContent && canDownloadConsumableContent(consumableContent)
  const { t } = useTranslation()
  const [highlightsOpen, openHighlights, closeHighlights] =
    useBooleanState(false)

  const contentType = content.type
  const action = CONTENT_ACTIONS[content.type]
  const availability = content.availability
  const showHighlights = consumableContent?.type === "ebook"
  const isLocked = availability.status === "notAvailable"

  return (
    <>
      <CenteredContent
        paddingVertical={tokens.library.listItemWide.paddingVertical}
      >
        <Row gap="xlarge">
          <SquareMediaThumbnail
            size={tokens.library.listItemWide.artworkHeight}
            media={artworkMedia}
            showLock={isLocked}
          />
          <WideContentContainer>
            <WideHeading title={title} subtitle={subtitle} />
            <ProgressForConsumableContent
              consumableContent={consumableContent}
              includeFinishedBadge
              includeProgressLabel
              includeProgressBar
            />
            {description ? (
              <Text textStyle="body" numberOfLines={3}>
                {description}
              </Text>
            ) : null}
            <Row gap="medium">
              {isLocked ? (
                <LockedContentButton
                  openCheckoutPage={onPressOpen}
                  availability={availability}
                  action={action}
                />
              ) : (
                <Button
                  type="primary"
                  icon={CONTENT_ACTION_ICONS[action]}
                  onPress={onPressOpen}
                >
                  {t(stringKeyLookup.playAction[action])}
                </Button>
              )}
              <Row gap="large">
                {showDownloadButton ? (
                  <DownloadButton
                    consumableContent={consumableContent}
                    showLabel
                    key={
                      // See the comment for the DownloadButton key prop in
                      // LibraryListItemNarrow for an explanation of why this is
                      // necessary.
                      getLibraryItemKey(libraryItem)
                    }
                  />
                ) : null}
                {showHighlights ? (
                  <PlainActionButton
                    icon={icons.bookmark}
                    onPress={openHighlights}
                  >
                    {t("View highlights")}
                  </PlainActionButton>
                ) : null}
                {!isLocked && contentType === "video" ? (
                  <PlainActionButton
                    icon={icons.headphones}
                    onPress={() => onPressOpen("listen")}
                  >
                    {t("Listen")}
                  </PlainActionButton>
                ) : null}
                {onPressMarkFinished ? (
                  <PlainActionButton
                    icon={icons.checkCircle.outline}
                    onPress={onPressMarkFinished}
                  >
                    {t("Mark as Finished")}
                  </PlainActionButton>
                ) : null}
                {onPressRemove ? (
                  <PlainActionButton
                    icon={icons.closeCircle.outline}
                    onPress={onPressRemove}
                  >
                    {t(removeLabel)}
                  </PlainActionButton>
                ) : null}
              </Row>
            </Row>
          </WideContentContainer>
        </Row>
      </CenteredContent>
      {showHighlights ? (
        <EbookHighlightsSelect
          consumableContent={consumableContent}
          onClose={closeHighlights}
          open={highlightsOpen}
        />
      ) : null}
    </>
  )
}

/**
 * Show this in place of LibraryListItemWide while content is loading.
 */
function LibraryListItemNarrowPlaceholder({
  title,
  subtitle,
}: {
  title?: string
  subtitle?: string
}): JSX.Element {
  return <ThumbnailListItem data={title ? { title, subtitle } : undefined} />
}

/**
 * Render the library list item in "narrow" presentation mode (used on phones).
 */
function LibraryListItemNarrow({
  content,
  consumableContent,
  libraryItem,
  artworkMedia,
  removeLabel,
  onPressOpen: onPressOpenProp,
  onPressMarkFinished: onPressMarkFinishedProp,
  onPressRemove: onPressRemoveProp,
}: ListItemProps): JSX.Element {
  const [highlightsOpen, openHighlights, closeHighlights] =
    useBooleanState(false)
  const [actionModalOpen, openActionModal, closeActionModal] =
    useBooleanState(false)
  const metadata = consumableContent
    ? getMetadataFromConsumableContent(consumableContent)
    : getMetadataFromContent(content)
  const showDownloadButton =
    consumableContent && canDownloadConsumableContent(consumableContent)

  const action = CONTENT_ACTIONS[content.type]
  const availability = content.availability
  const isLocked = availability.status === "notAvailable"

  // Wait to handle these actions until the modal has animated out to avoid
  // prematurely yanking the modal from the UI.
  const pendingAction = useRef<"markFinished" | "remove" | null>(null)
  const executePendingAction = useCallback(() => {
    const action = pendingAction.current
    pendingAction.current = null
    if (action === "markFinished" && onPressMarkFinishedProp) {
      onPressMarkFinishedProp()
    } else if (action === "remove" && onPressRemoveProp) {
      onPressRemoveProp()
    }
  }, [onPressMarkFinishedProp, onPressRemoveProp])

  const onPressOpen = useCallback(
    (videoPlayMode?: "watch" | "listen") => {
      closeActionModal()
      onPressOpenProp(videoPlayMode)
    },
    [closeActionModal, onPressOpenProp],
  )

  const onPressMarkFinished = useCallback(() => {
    pendingAction.current = "markFinished"
    closeActionModal()
  }, [closeActionModal])

  const onPressRemove = useCallback(() => {
    pendingAction.current = "remove"
    closeActionModal()
  }, [closeActionModal])

  const { t } = useTranslation()

  return (
    <>
      <ThumbnailListItem
        showActionButton={Boolean(consumableContent)}
        data={{
          ...metadata,
          onPress: () =>
            onPressOpen(
              libraryItem.progressItem instanceof VideoProgressItem
                ? libraryItem.progressItem.getPlayMode()
                : undefined,
            ),
          showLock: isLocked,
          actionButton: {
            onPress: openActionModal,
            label: "Actions",
          },
          artworkMedia,
        }}
      >
        {showDownloadButton ? (
          <DownloadButtonRow>
            <DownloadButton
              consumableContent={consumableContent}
              height={28}
              key={
                // Re-mount the download button if the content it represents
                // is changing. This is necessary because RecycleListView will
                // re-use the list item component for new library items as you
                // scroll. This is a great optimization in most cases, but if
                // we don't remount the download button then we get brief
                // flashes of incorrect state the download state for the new
                // content is lazy-loaded in. When remounting we'll still see
                // a brief flash from "not downloaded" to whatever the current
                // state is, but that's better than seeing something like
                // "inProgress" to "notDownloaded" flash by.
                getLibraryItemKey(libraryItem)
              }
            />
            <ProgressForConsumableContent
              consumableContent={consumableContent}
              includeProgressLabel
              includeProgressBar
              includeFinishedBadge
              flex={1}
            />
          </DownloadButtonRow>
        ) : (
          <ProgressForConsumableContent
            consumableContent={consumableContent}
            includeProgressLabel
            includeProgressBar
            includeFinishedBadge
            childrenBefore={<Spacer size="tiny" />}
          />
        )}
      </ThumbnailListItem>
      <Modal
        title={metadata.title}
        open={actionModalOpen}
        onPressCloseButton={closeActionModal}
        onPressOutside={closeActionModal}
        maxWidth={400}
        onCloseComplete={executePendingAction}
      >
        <Column
          gap="medium"
          alignItems="stretch"
          paddingHorizontal="medium"
          paddingVertical="large"
        >
          {isLocked ? (
            <LockedContentButton
              openCheckoutPage={onPressOpen}
              action={action}
              availability={availability}
            />
          ) : (
            <>
              <Button
                type="primary"
                icon={CONTENT_ACTION_ICONS[action]}
                onPress={onPressOpen}
              >
                {t(stringKeyLookup.playAction[action])}
              </Button>
              {consumableContent?.type === "ebook" ? (
                <Button icon={icons.bookmark} onPress={openHighlights}>
                  {t("View highlights")}
                </Button>
              ) : null}
              {consumableContent?.type === "video" ? (
                <Button
                  icon={icons.headphones}
                  onPress={() => onPressOpen("listen")}
                >
                  {t("Listen")}
                </Button>
              ) : null}
            </>
          )}
          {onPressMarkFinishedProp ? (
            <Button
              icon={icons.checkCircle.outline}
              onPress={onPressMarkFinished}
            >
              {t("Mark as Finished")}
            </Button>
          ) : null}
          {onPressRemoveProp ? (
            <Button icon={icons.closeCircle.outline} onPress={onPressRemove}>
              {t(removeLabel as i18nKey)}
            </Button>
          ) : null}
        </Column>
      </Modal>
      {consumableContent?.type === "ebook" ? (
        <EbookHighlightsSelect
          consumableContent={consumableContent}
          open={highlightsOpen}
          onClose={closeHighlights}
          onWillChange={closeActionModal}
        />
      ) : null}
    </>
  )
}

/**
 * Render a "library list item" - an item in the user's library that represents
 * a consumable piece of content (an audiobook, video, podcast episode, etc.).
 * This handles fetching all the data necessary for the library list item and
 * switching between the narrow and wide UIs.
 */
export function LibraryListItem({
  isOffline,
  libraryItem,
  removeLabel = "Remove from Library",
  onPressRemove: onPressRemoveProp,
  onPressMarkFinished: onPressMarkFinishedProp,
  onPressPlayAudio,
}: {
  isOffline: boolean
  libraryItem: LibraryItem
  removeLabel?: i18nKey
  onPressRemove?: (libraryItem: LibraryItem) => void
  onPressMarkFinished?: (libraryItem: LibraryItem) => void
  onPressPlayAudio?: () => void
}): JSX.Element | null {
  const profileId = useActiveProfileId()
  const { tokens } = useTokens()
  const navigate = useNavigate()
  const contentId = libraryItem.contentId
  const remoteProgressCleared = useRef(false)
  const contentQuery = useContent(contentId, undefined, {
    enabled: !isOffline,
    // If we encountered missing content then remove it from the user's
    // library.
    onError: (error) => {
      if (
        isAxiosError(error) &&
        error.response?.status === 404 &&
        !remoteProgressCleared.current &&
        libraryItem.progressItem
      ) {
        remoteProgressCleared.current = true
        settings
          .clearRemote(libraryItem.progressItem.getKey(), { profileId })
          .catch((cause) => {
            remoteProgressCleared.current = false
            logError(
              new Error(
                `[Library] Failed to clear remote setting for missing content #${contentId}`,
                { cause },
              ),
            )
          })
      }
    },
  })

  // Attempt to generate a ConsumableContent item from either the contnent
  // response or from a saved download item.
  const consumableContentQuery = useQuery(
    [
      "library-consumable-content",
      isOffline,
      contentQuery.data?.id,
      libraryItem.progressItem?.getKey(),
    ],
    () => {
      // Return offline data if a) we don't have data from the server or b)
      // we're actually offline. In the case of "a" we want don't want to make
      // the user wait for a slow network connection if they have the content
      // downloaded. In the case of "b" we want to ignore cached data from the
      // server as that'll include URLs that won't resolve offline.
      if (!contentQuery?.data || isOffline) {
        return libraryItem.downloadItem?.getOfflineConsumableContent()
      }

      const podcastEpisodeNumber =
        libraryItem.progressItem instanceof PodcastEpisodeProgressItem
          ? libraryItem.progressItem.getPodcastEpisodeNumber()
          : undefined
      return (
        getConsumableContentFromContentResponse({
          content: contentQuery.data,
          podcastEpisodeNumber,
        }) ?? undefined
      )
    },
  )
  const consumableContent = consumableContentQuery.data
  const content = consumableContent?.content || contentQuery?.data

  const isLocked = content?.availability?.status === "notAvailable"
  const openCheckoutPage = useOpenCheckoutPage({
    availability: content?.availability,
  })

  const artworkMedia = getArtworkMediaFromConsumableContent(
    consumableContentQuery.data,
  )

  const onPressOpen = useCallback(
    (videoPlayMode?: "watch" | "listen") => {
      if (isLocked) {
        openCheckoutPage()
        return
      }

      if (content?.type === "videoSeries") {
        navigate(
          getPathFromContent(content.id, content.type, NO_TAB),
          { goBackTo: "library" },
          "push",
        )
      }

      if (!consumableContent) {
        return
      }

      switch (consumableContent.type) {
        case "video": {
          if (videoPlayMode === "listen") {
            playContentAudio({ consumableContent, profileId })
            onPressPlayAudio?.()
            break
          } else {
            navigate(
              getPathFromContent(
                consumableContent.content.id,
                consumableContent.content.type,
                NO_TAB,
              ),
              { goBackTo: "library" },
              "push",
            )
          }
          break
        }
        case "book":
        case "podcastEpisode":
          playContentAudio({ consumableContent, profileId })
          onPressPlayAudio?.()
          break
        case "ebook":
          readContentEbook({ consumableContent, profileId })
          break
        case "webEmbed":
          navigate(
            getPathFromContent(
              consumableContent.content.id,
              consumableContent.content.type,
              NO_TAB,
            ),
            { goBackTo: "library" },
            "push",
          )
          break
        default:
          consumableContent satisfies never
      }
    },
    [
      isLocked,
      content,
      consumableContent,
      openCheckoutPage,
      navigate,
      profileId,
      onPressPlayAudio,
    ],
  )

  const PlaceholderComponent =
    tokens.library.presentation === "wide"
      ? LibraryListItemWidePlaceholder
      : LibraryListItemNarrowPlaceholder

  const DisplayComponent =
    tokens.library.presentation === "wide"
      ? LibraryListItemWide
      : LibraryListItemNarrow

  const errorResponse = isAxiosError(contentQuery.error)
    ? contentQuery.error.response
    : undefined
  const isUnavailableEverywhere = errorResponse?.status === 404
  const isUnavailableHere = errorResponse?.status === 403
  const isUnavailable = isUnavailableEverywhere || isUnavailableHere
  const errorTitle =
    isUnavailable && typeof errorResponse.data?.title === "string"
      ? errorResponse.data.title
      : isUnavailable
        ? "Error"
        : undefined
  const errorSubtitle = isUnavailableEverywhere
    ? "This item is no longer available."
    : isUnavailableHere
      ? "This item is not available on this platform."
      : undefined

  return content ? (
    <DisplayComponent
      content={content}
      consumableContent={consumableContent}
      libraryItem={libraryItem}
      artworkMedia={artworkMedia}
      onPressOpen={onPressOpen}
      onPressMarkFinished={
        !isLocked &&
        onPressMarkFinishedProp &&
        (shouldShowProgress(content.type) ||
          // shouldShowProgress will return false for podcasts but in this case
          // "podcast" really means podcast episode
          content.type === "podcast")
          ? () => onPressMarkFinishedProp(libraryItem)
          : undefined
      }
      onPressRemove={
        // Disable this action when offline because settings cannot currently be
        // cleared when offline
        !isOffline && onPressRemoveProp
          ? () => onPressRemoveProp(libraryItem)
          : undefined
      }
      removeLabel={removeLabel}
    />
  ) : errorTitle ? (
    <PlaceholderComponent title={errorTitle} subtitle={errorSubtitle} />
  ) : (
    <PlaceholderComponent />
  )
}

export function getLibraryListItemPaddingVertical(
  tokens: ResolvedTokens<Tokens>,
): number {
  return tokens.library.presentation === "wide"
    ? tokens.library.listItemWide.paddingVertical
    : tokens.thumbnailListItem.paddingVertical
}

export function getLibraryListItemHeight(
  tokens: ResolvedTokens<Tokens>,
): number {
  const artworkHeight =
    tokens.library.presentation === "wide"
      ? tokens.library.listItemWide.artworkHeight
      : tokens.thumbnailListItem.artwork.height
  return artworkHeight + getLibraryListItemPaddingVertical(tokens) * 2
}
