import React, { useCallback, useMemo, useState } from "react"
import { LayoutChangeEvent, View } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"

import styled from "styled-components/native"

import { simpleHash } from "@treefort/lib/simple-hash"

import { getAbsoluteLineHeight } from "../lib/text-style"
import ListView from "./list-view"
import Modal, { MODAL_HEADER_HEIGHT_PX } from "./modal"
import Text from "./text"
import { useTokens } from "./tokens-provider"
import Touchable from "./touchable"

export type Option<T> = { label: string; value: T; depth?: number }

const OPTION_TEXT_STYLE = "headingSmall"

const OPTION_PADDING_VERTICAL = 16

// Unlocks a scroll view during body scroll locking on the web
const WEB_SCROLL_UNLOCK = { webScrollUnlock: true }

const OptionContainer = styled.View<{ height: number }>`
  padding-horizontal: ${(props) => props.theme.spacing.medium}px;
  height: ${(props) => props.height}px;
  flex-direction: row;
  align-items: center;
`

const OptionLabel = styled(Text)<{
  depth: number
  selected: boolean | undefined
}>`
  margin-left: ${(props) =>
    props.depth * 24 -
    (props.depth > 0 && props.selected
      ? props.theme.trackPicker.currentDot.size * 2
      : 0)}px;
`

const SelectedDot = styled.View`
  width: ${(props) => props.theme.trackPicker.currentDot.size}px;
  height: ${(props) => props.theme.trackPicker.currentDot.size}px;
  border-radius: ${(props) => props.theme.trackPicker.currentDot.size}px;
  margin-right: ${(props) => props.theme.trackPicker.currentDot.size}px;
  background-color: ${(props) => props.theme.trackPicker.currentDot.color};
`

const MeasureView = styled.View`
  position: absolute;
  top: ${MODAL_HEADER_HEIGHT_PX}px;
  bottom: 0;
  left: 0;
  right: 0;
`

function Option<T>({
  option,
  height,
  selected,
  onPress,
}: {
  option: Option<T>
  height: number
  selected?: boolean
  onPress?: (value: T) => void
}) {
  const label = (
    <OptionLabel
      textStyle={OPTION_TEXT_STYLE}
      numberOfLines={1}
      depth={option.depth || 0}
      selected={selected}
    >
      {option.label}
    </OptionLabel>
  )
  const item = (
    <OptionContainer height={height}>
      {selected ? (
        <>
          <SelectedDot />
          {label}
        </>
      ) : (
        label
      )}
    </OptionContainer>
  )
  return onPress ? (
    <Touchable
      onPress={() => onPress(option.value)}
      feedback="ripple-or-highlight"
      role="button"
      aria-label={option.label}
    >
      {item}
    </Touchable>
  ) : (
    item
  )
}

export default function Select<T>({
  open,
  options,
  value,
  label,
  onChange,
  onClose,
}: {
  open: boolean
  options: Option<T>[]
  value?: T
  label?: string
  onChange?: (value: T) => void
  onClose: () => void
}): JSX.Element {
  const { tokens } = useTokens()
  const [viewSize, setViewSize] = useState<{ width: number; height: number }>()
  const safeAreaInsets = useSafeAreaInsets()
  const handleLayout = useCallback(
    (event: LayoutChangeEvent) =>
      setViewSize({
        width: event.nativeEvent.layout.width,
        height: event.nativeEvent.layout.height,
      }),
    [],
  )
  const optionHeight = useMemo(
    () =>
      getAbsoluteLineHeight(OPTION_TEXT_STYLE, tokens) +
      OPTION_PADDING_VERTICAL * 2,
    [tokens],
  )
  return (
    <Modal
      title={label}
      open={open}
      onPressCloseButton={onClose}
      onPressOutside={onClose}
      type="sheet"
      backgroundColor="tertiary"
      portalHost="foreground"
    >
      {(modalType) => (
        <>
          <MeasureView onLayout={handleLayout} />
          {viewSize ? (
            <ListView
              items={options}
              viewSize={viewSize}
              style={[
                viewSize,
                { position: "absolute", top: MODAL_HEADER_HEIGHT_PX, left: 0 },
              ]}
              getItemKey={(option) =>
                typeof option.value === "string"
                  ? option.value
                  : typeof option.value === "number"
                    ? option.value.toString()
                    : simpleHash(option)
              }
              getItemSize={() => optionHeight}
              initialRenderIndex={options.findIndex((o) => o.value === value)}
              scrollEventThrottle={8}
              scrollViewDataSet={WEB_SCROLL_UNLOCK}
              paddingEnd={
                modalType === "sheet" ? safeAreaInsets.bottom : undefined
              }
              renderItem={(option) => (
                <Option
                  option={option}
                  selected={option.value === value}
                  height={optionHeight}
                  onPress={onChange}
                />
              )}
            />
          ) : null}
          <View
            style={{
              // This View is used to reserve the space that would normally be
              // taken up by the tracks in the absolutely positioned ListView.
              // This ensures that the modal expands vertically as necessary to
              // accomodate larger numbers of tracks.
              height: options.length * optionHeight,
            }}
            pointerEvents="none"
          />
        </>
      )}
    </Modal>
  )
}
