import { useCallback, useMemo } from "react"

import { LocalStorageStore } from "@treefort/lib/store/local-storage"

import { history } from "../../lib/history.web"
import { isParent } from "../../lib/parental-gateway"
import { useNavigate } from "../use-navigate"
import { useParentalGateway } from "../use-parental-gateway"

const PRE_MENU_LOCATION_KEY = "pre-menu-location"

const store = new LocalStorageStore({ key: "menu" })

/**
 * Save a location in the store in order to navigate back to it after the menu
 * is closed
 */
const saveLocation = (location: { pathname: string }): void => {
  // Ignore locations starting with /auth, /menu, or /checkout
  if (!/^\/(auth|menu|checkout)/.test(location.pathname)) {
    store.setSync(PRE_MENU_LOCATION_KEY, location)
  }
}

// Save the user's location when the page is loaded and any time the URL
// changes.
saveLocation(window.location)
history.listen(saveLocation)

export function useMenu(): {
  open: (path: string) => Promise<void>
  close: () => void
} {
  const navigate = useNavigate()
  const parentalGateway = useParentalGateway()

  // Before the user opens the menu, save the current page so we know where to
  // go back to.
  const open = useCallback(
    async (path = "") => {
      if (parentalGateway && !(await isParent())) return
      navigate("/menu" + path)
    },
    [parentalGateway, navigate],
  )

  return useMemo(() => ({ open, close: closeMenuWeb }), [open])
}

/**
 * Closes the menu. Only works on the web, hence the naming
 */
export function closeMenuWeb() {
  const preMenuLocation = store.getSync<Location>(PRE_MENU_LOCATION_KEY)
  if (preMenuLocation) {
    store.removeSync(PRE_MENU_LOCATION_KEY)
    history.push(preMenuLocation)
  } else {
    history.push("/")
  }
}
