import { useEffect, useRef } from "react"

import useAppState from "../hooks/use-app-state"
import { useInAppPurchases } from "../hooks/use-in-app-purchases"
import { useInitializeAndroidAudioPlayer } from "../hooks/use-initialize-android-audio-player"
import { useInitializeCheckout } from "../hooks/use-initialize-checkout"
import { useInitializeGoogleAnalyticsClient } from "../hooks/use-initialize-google-analytics-client"
import { useInitializeNotifications } from "../hooks/use-initialize-notifications"
import { useNetworkChecks } from "../hooks/use-network-checks"
import { useOfflineState } from "../hooks/use-offline-state"
import downloadManager, { Event } from "../lib/download-manager"
import { logError } from "../lib/logging"
import { getNetworkState } from "../lib/network-state"
import { queryClient } from "../lib/query-client"
import { toast } from "../lib/toaster"
import { syncManager } from "../watermelon/sync"

/**
 * Run initialization logic for the app. This should be rendered exactly once
 * directly below the app's auth context provider.
 */
export function InitializeApp(): null {
  const [offline] = useOfflineState()
  const prevOffline = useRef<boolean>(offline)
  const appState = useAppState()

  // Reset all queries and attempt to sync offline database when the device
  // comes online after having been offline
  useEffect(() => {
    if (prevOffline.current && !offline) {
      queryClient.resetQueries()
      syncManager.requestSync({ syncType: "periodic" })
    }
    prevOffline.current = offline
  }, [offline])

  // Notify the user of any failed downloads.
  useEffect(
    () =>
      downloadManager.on(Event.DownloadFailure, (download) => {
        logError(new Error("Download failed", { cause: download.error }))
        toast.error("Download failed. Please try again.")
      }),
    [],
  )

  // Refresh the network state any time the app comes out of the background
  useEffect(() => {
    if (appState === "active") {
      getNetworkState()
    }
  }, [appState])

  useInAppPurchases()

  useInitializeCheckout()

  useInitializeAndroidAudioPlayer()

  useInitializeNotifications()

  useInitializeGoogleAnalyticsClient()

  useNetworkChecks(offline)

  return null
}
