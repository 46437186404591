import {
  Image as ReactNativeImage,
  ImageProps,
  NativeSyntheticEvent,
  ImageLoadEventData,
} from "react-native"

type OnLoadEvent = NativeSyntheticEvent<ImageLoadEventData>

export default ReactNativeImage

export type { ImageProps, OnLoadEvent }
