import { Q } from "@nozbe/watermelondb"

import { getClient } from "../db"
import { Highlight, HighlightData } from "../models/highlight"
import { syncManager } from "../sync"
import { DatabaseFieldsOf, ISyncedDatastore } from "./types"

class HighlightStore implements ISyncedDatastore<Highlight> {
  async getAll() {
    return await getClient().get<Highlight>(Highlight.table).query().fetch()
  }

  async find(id: string) {
    const result = await getClient()
      .get<Highlight>(Highlight.table)
      .query(Q.where("id", id))
      .fetch()

    if (result.length === 0) {
      return null
    }

    if (result.length > 1) {
      throw new Error(
        `More than one model returned for id '${id}', but we expect only one`,
      )
    }

    return result[0]
  }

  findBy(fields: Partial<HighlightData>) {
    return getClient()
      .get<Highlight>(Highlight.table)
      .query(Object.entries(fields).map((field) => Q.where(field[0], field[1])))
  }

  async create(values: DatabaseFieldsOf<Highlight>) {
    const database = getClient()
    const result = await database.write(async () => {
      const createDate = new Date().getTime()
      return await database
        .get<Highlight>(Highlight.table)
        .create((highlight) => {
          Object.assign(highlight, values)
          highlight.createdAtDate = createDate
          highlight.updatedAtDate = createDate
          highlight.syncIgnore_createdLocally = true
        })
    })

    await syncManager.requestSync({ syncType: "user-initiated" })

    return result
  }

  async update(id: string, fields: Partial<DatabaseFieldsOf<Highlight>>) {
    const result = await getClient().write(async () => {
      const highlight = await this.find(id)

      if (!highlight) {
        throw new Error(`Highlight not found for id '${id}'`)
      }

      await highlight.update((highlight) => {
        Object.assign(highlight, fields)
        highlight.updatedAtDate = new Date().getTime()
      })
    })

    await syncManager.requestSync({ syncType: "user-initiated" })

    return result
  }

  async delete(id: string) {
    await getClient().write(async () => {
      const highlight = await this.find(id)

      if (highlight) {
        await highlight.markAsDeleted()
      }
    })

    await syncManager.requestSync({ syncType: "user-initiated" })
  }
}

export const highlightStore = new HighlightStore()
