import React from "react"

import { simpleHash } from "@treefort/lib/simple-hash"

import { useAppTab } from "../../hooks/use-app-tab"
import { isAppTab } from "../routes"
import { AppPageScreen } from "./app-page"
import { CollectionScreen } from "./collection"
import { ContentAudiobookScreen } from "./content-audiobook"
import { ContentEbookScreen } from "./content-ebook"
import { ContentPodcastScreen } from "./content-podcast"
import { ContentPodcastEpisodeScreen } from "./content-podcast-episode"
import { ContentVideoScreen } from "./content-video"
import { ContentVideoSeriesScreen } from "./content-video-series"
import { LibraryScreen } from "./library"
import { NotFoundScreen } from "./not-found"
import { UpdateScreen } from "./update"

export function AppTabScreen(): JSX.Element {
  const tab = useAppTab()

  // If we're not dealing with an app tab from the manifest then someone loaded
  // a bad tab URL (e.g. /tabs/asdfasdf)
  if (!isAppTab(tab)) {
    return <NotFoundScreen />
  }

  // Use a key that will ensure the screen re-mounts when switching between tabs
  // that render using the same screen component
  const key = simpleHash(tab.link)

  switch (tab.link.type) {
    case "path":
      switch (tab.link.path) {
        case "/tabs/library":
          return <LibraryScreen />
        default:
          return <UpdateScreen />
      }
    case "page":
      return <AppPageScreen key={key} />
    case "collection":
      return <CollectionScreen key={key} />
    case "content":
      switch (tab.link.contentType) {
        case "book":
          return <ContentAudiobookScreen key={key} />
        case "ebook":
          return <ContentEbookScreen key={key} />
        case "podcast":
          return <ContentPodcastScreen key={key} />
        case "video":
          return <ContentVideoScreen key={key} />
        case "videoSeries":
          return <ContentVideoSeriesScreen key={key} />
        default:
          return <UpdateScreen />
      }
    case "podcastEpisode":
      return <ContentPodcastEpisodeScreen key={key} />
    default:
      return <UpdateScreen />
  }
}
