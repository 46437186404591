import { i18n } from "i18next"

import {
  WebPaymentBillingPortalSessionRequest,
  WebPaymentSessionResponse,
} from "@treefort/api-spec"
import { DisplayableError } from "@treefort/lib/displayable-error"

import api from "./api"
import { checkoutSessionManager } from "./checkout"
import { getTreefortMessageFromError } from "./get-treefort-message-from-error"
import { logError } from "./logging"

export async function openWebPaymentCheckout({
  subscriptionPlanId,
  promoCode,
  i18n,
}: {
  subscriptionPlanId: number
  promoCode?: string
  i18n: i18n
}) {
  try {
    const res = await api.post<WebPaymentSessionResponse>(
      `/integrations/web-payment/checkout-session`,
      {
        subscriptionPlanId,
        promoCode,
        locale: i18n.language,
      },
    )
    window.location.href = res.data.sessionUrl
  } catch (cause) {
    checkoutSessionManager.endSession({ complete: false })
    logError(
      new DisplayableError(getTreefortMessageFromError(cause), { cause }),
    )
  }
}

export async function openWebPaymentBillingPortal({
  i18n,
}: {
  i18n: i18n
}): Promise<void> {
  try {
    const data: WebPaymentBillingPortalSessionRequest = {
      locale: i18n.language,
    }
    const res = await api.post<WebPaymentSessionResponse>(
      "/integrations/web-payment/billing-portal-session",
      data,
    )
    window.location.href = res.data.sessionUrl
  } catch (error) {
    logError(
      new DisplayableError(
        i18n.t(
          "An error occurred opening the billing portal. Please try again.",
        ),
        error,
      ),
    )
  }
}
