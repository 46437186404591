import {
  ConsumableContent,
  AudiobookConsumableContent,
  EbookConsumableContent,
  PodcastEpisodeConsumableContent,
  VideoConsumableContent,
} from "../consumable-content"
import settings from "../settings"
import {
  AudiobookProgressItem,
  PlayableProgressItem,
  PlayableProgressItemData,
  PodcastEpisodeProgressItem,
  VideoProgressItem,
  AudiobookProgressItemData,
  VideoProgressItemData,
  PodcastEpisodeProgressItemData,
} from "./playable"
import {
  EbookProgressItem,
  EbookProgressItemData,
  ReadableProgressItem,
  ReadableProgressItemData,
  WebEmbedProgressItem,
  WebEmbedProgressItemData,
} from "./readable"

export type ProgressItemForConsumableContent<T extends ConsumableContent> =
  T extends AudiobookConsumableContent
    ? AudiobookProgressItem
    : T extends EbookConsumableContent
      ? EbookProgressItem
      : T extends VideoConsumableContent
        ? VideoProgressItem
        : T extends PodcastEpisodeConsumableContent
          ? PodcastEpisodeProgressItem
          : ProgressItem

export type ProgressItemDataForConsumableContent<T extends ConsumableContent> =
  T extends AudiobookConsumableContent
    ? AudiobookProgressItemData
    : T extends EbookConsumableContent
      ? EbookProgressItemData
      : T extends VideoConsumableContent
        ? VideoProgressItemData
        : T extends PodcastEpisodeConsumableContent
          ? PodcastEpisodeProgressItemData
          : ProgressItemData

export type ProgressItemForData<T extends ProgressItemData> =
  T extends AudiobookProgressItemData
    ? AudiobookProgressItem
    : T extends EbookProgressItemData
      ? EbookProgressItem
      : T extends VideoProgressItemData
        ? VideoProgressItem
        : T extends PodcastEpisodeProgressItemData
          ? PodcastEpisodeProgressItem
          : T extends WebEmbedProgressItemData
            ? WebEmbedProgressItem
            : ProgressItem

export type ProgressItem = PlayableProgressItem | ReadableProgressItem

export type ProgressItemData =
  | PlayableProgressItemData
  | ReadableProgressItemData

export function getProgressItemFromConsumableContent<
  T extends ConsumableContent,
>({
  consumableContent,
  profileId,
}: {
  consumableContent: T
  profileId: string | null
}) {
  switch (consumableContent.type) {
    case "book":
      return new AudiobookProgressItem({
        data: {
          contentId: consumableContent.content.id,
          contentType: consumableContent.content.type,
        },
        profileId,
      }) as ProgressItemForConsumableContent<T>
    case "ebook":
      return new EbookProgressItem({
        data: {
          contentId: consumableContent.content.id,
          contentType: consumableContent.content.type,
        },
        profileId,
      }) as ProgressItemForConsumableContent<T>

    case "webEmbed":
      return new WebEmbedProgressItem({
        data: {
          contentId: consumableContent.content.id,
          contentType: consumableContent.content.type,
        },
        profileId,
      }) as ProgressItemForConsumableContent<T>
    case "video":
      return new VideoProgressItem({
        data: {
          contentId: consumableContent.content.id,
          contentType: consumableContent.content.type,
        },
        profileId,
      }) as ProgressItemForConsumableContent<T>
    case "podcastEpisode":
      return new PodcastEpisodeProgressItem({
        data: {
          contentId: consumableContent.content.id,
          contentType: consumableContent.content.type,
          podcastEpisode: consumableContent.podcastEpisode.episode,
        },
        profileId,
      }) as ProgressItemForConsumableContent<T>
  }
}

export function getProgressItemFromSettingValue<T extends ProgressItemData>({
  value: data,
  profileId,
}: {
  value: T
  profileId: string | null
}) {
  switch (data.contentType) {
    case "book":
      return new AudiobookProgressItem({
        data,
        profileId,
      }) as ProgressItemForData<T>
    case "ebook":
      return new EbookProgressItem({
        data,
        profileId,
      }) as ProgressItemForData<T>
    case "video":
      return new VideoProgressItem({
        data,
        profileId,
      }) as ProgressItemForData<T>
    case "podcast":
      return new PodcastEpisodeProgressItem({
        data,
        profileId,
      }) as ProgressItemForData<T>
    case "webEmbed":
      return new WebEmbedProgressItem({
        data,
        profileId,
      }) as ProgressItemForData<T>
  }
}

export async function fetchProgressItemFromSettings<
  T extends ConsumableContent,
>({
  consumableContent,
  profileId,
  strategy,
}: {
  consumableContent: T
  profileId: string | null
  strategy: "local" | "localOrRemote"
}) {
  // Create an "empty" progress item - i.e. a progress item with no progress set
  const emptyProgressItem = getProgressItemFromConsumableContent<T>({
    consumableContent,
    profileId,
  })

  // Look for progress data in settings
  const { value, timestamp } = await (
    strategy === "local" ? settings.getLocal : settings.getLocalOrRemote
  )<ProgressItemData>(emptyProgressItem.getKey(), {
    profileId,
  })

  // If we found a setting then create a progress from the setting's value,
  // otherwise use the empty progress item.
  const progressItem = (
    value
      ? getProgressItemFromSettingValue({ value, profileId })
      : emptyProgressItem
  ) as ProgressItemForConsumableContent<T>

  // If the progress item supports playback rates but doesn't have one set then
  // load the default playback rate from settings.
  if (
    progressItem instanceof PlayableProgressItem &&
    !progressItem.hasPlaybackRate()
  ) {
    const defaultPlaybackRate = await progressItem.getDefaultPlaybackRate()
    if (defaultPlaybackRate) {
      progressItem.setPlaybackRate(defaultPlaybackRate)
    }
  }

  return { progressItem, timestamp }
}

export * from "./playable"
export * from "./readable"
