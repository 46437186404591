import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { DimensionValue, Platform, View } from "react-native"

import { useAuth } from "@treefort/lib/auth-provider"

import { useUserSubscriptions } from "../hooks/subscriptions"
import useAppManifest from "../hooks/use-app-manifest"
import { useOpenCheckoutPage } from "../hooks/use-open-checkout-page"
import { useParentalGateway } from "../hooks/use-parental-gateway"
import { useSignUpOptionsData } from "../hooks/use-sign-up-options-data"
import authenticator from "../lib/authenticator"
import { isParent } from "../lib/parental-gateway"
import {
  getCurrentSubscription,
  subscriptionWillRenew,
} from "../lib/subscriptions"
import { i18nKey } from "../types/i18next"
import Box from "./box"
import { Button } from "./button"
import Column from "./column"
import { Heading } from "./heading"
import ImageContained from "./image-contained"
import RestorePurchasesLink from "./restore-purchases-link"
import Spacer from "./spacer"
import { useTenantLogo } from "./tenant-logo"
import Text from "./text"
import { useTokens } from "./tokens-provider"

const LOGO_MAX_WIDTH = 156
const LOGO_MAX_HEIGHT = 64

export type CallToSignUpState = "loading" | "visible" | "hidden"

export default function CallToSignUp({
  onStateChange = () => {},
  maxWidth = 360,
  showLogo = true,
  ...boxProps
}: {
  onStateChange: (state: CallToSignUpState) => void
  maxWidth?: DimensionValue
  showLogo?: boolean
} & Parameters<typeof Box>[0]): JSX.Element | null {
  const auth = useAuth()
  const manifest = useAppManifest()
  const enabled = manifest.features.userAccounts
  const tenantLogo = useTenantLogo()
  const { displayMode } = useTokens()
  const [logoReady, setLogoReady] = useState(!showLogo)
  const userSubscriptions = useUserSubscriptions({ enabled })
  const subscription = getCurrentSubscription(userSubscriptions.data)
  const parentalGateway = useParentalGateway()
  const openCheckoutPage = useOpenCheckoutPage()
  const signUpOptionsData = useSignUpOptionsData({ subscription }, { enabled })

  const showSubscribe =
    signUpOptionsData.data?.recommendedSubscriptionPlans.filter(
      (plan) => plan.provider !== "groupMembership",
    ).length && !subscriptionWillRenew(subscription)

  const loaded =
    !userSubscriptions.isError &&
    !userSubscriptions.isLoading &&
    !signUpOptionsData.isError &&
    !signUpOptionsData.isLoading &&
    !auth.loading

  const showCallToSignUp = enabled && loaded && (!auth.user || showSubscribe)

  // Let the caller know we're ready when the app logo is rendered and the
  // authenticator isn't loading
  useEffect(() => {
    onStateChange(
      !enabled
        ? "hidden"
        : !loaded
          ? "loading"
          : showCallToSignUp && !logoReady
            ? "loading"
            : showCallToSignUp
              ? "visible"
              : "hidden",
    )
  }, [onStateChange, enabled, loaded, showCallToSignUp, logoReady])

  const login = useCallback(async () => {
    if (parentalGateway && !(await isParent())) return
    authenticator.login()
  }, [parentalGateway])

  const createAccount = useCallback(async () => {
    if (parentalGateway && !(await isParent())) return
    authenticator.register()
  }, [parentalGateway])

  const { t } = useTranslation()

  return showCallToSignUp ? (
    <Column {...boxProps}>
      <View style={{ maxWidth }}>
        {
          // If we're not already showing the tenant's log in the desktop app
          // header then show it here
          showLogo ? (
            <Column
              paddingTop="large"
              paddingBottom="xlarge"
              alignItems="center"
            >
              <ImageContained
                containerSize={{
                  width: LOGO_MAX_WIDTH,
                  height: LOGO_MAX_HEIGHT,
                }}
                uri={tenantLogo[displayMode]}
                onReady={setLogoReady}
              />
            </Column>
          ) : (
            <Spacer size="large" />
          )
        }
        <Heading level={1} textStyle="headingLarge" alignment="center">
          {auth.user
            ? t(manifest.strings.callToSubscribeTitle as i18nKey)
            : t(manifest.strings.callToSignUpTitle as i18nKey)}
        </Heading>
        <Spacer size="medium" />
        <Text color="secondary" textStyle="body" alignment="center">
          {auth.user
            ? t(manifest.strings.callToSubscribeMessage as i18nKey)
            : t(manifest.strings.callToSignUpMessage as i18nKey)}
        </Text>
        {showSubscribe ? (
          <>
            <Spacer size="large" />
            <Button type="primary" onPress={openCheckoutPage} iconSize="medium">
              {t("Subscribe")}
            </Button>
          </>
        ) : null}
        {!auth.user ? (
          <>
            <Spacer size="large" />
            <Button
              type={showSubscribe ? "secondary" : "primary"}
              onPress={createAccount}
              iconSize="medium"
            >
              {t("Create FREE Account")}
            </Button>
            <Spacer size="large" />
            <Text textStyle="body" alignment="center" color="secondary">
              {t("Already have an account?")}{" "}
              <Text
                textStyle="body"
                color="accent"
                onPress={login}
                role="link"
                aria-label={t("Sign In")}
              >
                {t("Sign In")}
              </Text>
            </Text>
          </>
        ) : null}
        {Platform.OS !== "web" ? (
          <>
            <Spacer size="medium" />
            <RestorePurchasesLink />
          </>
        ) : null}
      </View>
    </Column>
  ) : null
}
