import { Platform } from "react-native"

import { t } from "i18next"

import { SubscriptionResponse } from "@treefort/api-spec"
import { Action } from "@treefort/lib/authenticator"
import { DisplayableError } from "@treefort/lib/displayable-error"
import { getMessageFromAxiosError } from "@treefort/lib/errors"

import { refetchUserSubscriptions } from "../../hooks/subscriptions"
import { fetchUserInfo } from "../../hooks/use-user-info"
import analytics from "../analytics"
import authenticator from "../authenticator"
import { checkGroupMembershipCode } from "../group-membership"
import { logError } from "../logging"
import { getSubscriptionPlan } from "../subscription-plans"
import { canPurchasePlanFromProvider } from "../subscription-plans"
import { getUserSubscriptions } from "../subscriptions"
import { toast } from "../toaster"
import { requestGroupMembership } from "./request-group-membership"
import { requestPayment } from "./request-payment"
import { CheckoutSession } from "./session"
import { CheckoutSessionManager, Event } from "./session-manager"
import { verifiedCheckout } from "./verified-checkout"

export type { CheckoutSession }
export { Event }

export const checkoutSessionManager = new CheckoutSessionManager()

/**
 * Log checkout analytics
 */
checkoutSessionManager.on(
  Event.CheckoutSessionStarted,
  analytics.logCheckoutSessionStart,
)
checkoutSessionManager.on(
  Event.CheckoutSessionEnded,
  ({ session, complete }) => {
    if (complete) {
      analytics.logCheckoutSessionComplete(session)
    }
  },
)

/**
 * Takes a checkout session and attempts to actually checkout (e.g. sign up for
 * a plan, create an account, etc.)
 */
export const checkout = async (checkoutSession: CheckoutSession) => {
  try {
    await verifyCheckoutSession(checkoutSession)

    await checkoutSessionManager.startSession(checkoutSession)

    const user = authenticator.getUser()

    if (user) {
      await verifiedCheckout({ checkoutSession })
      if (Platform.OS === "web" || checkoutSession.type === "groupMembership") {
        // On native refetching the subscription after a purchase is handled by
        // useInAppPurchases
        await refetchUserSubscriptions()
      }
    } else {
      await authenticator.register()
    }
  } catch (error) {
    handleError(error)
  }
}

/**
 * Takes a subscription response object from the api, checks if it matches the
 * current checkout session, if any, and ends the checkout session with a
 * success message if it does
 */
export const handleSubscriptionResponseForCheckout = async ({
  subscribed,
  willRenewWithSubscriptionPlanId,
}: SubscriptionResponse) => {
  await checkoutSessionManager.initialized
  const checkoutSession = checkoutSessionManager.getSession()
  switch (checkoutSession?.type) {
    case "paidPlan":
    case "paidPlanResubscription":
    case "paidPlanWithProrationPreview":
      if (
        (subscribed === checkoutSession.plan.id &&
          (willRenewWithSubscriptionPlanId === subscribed ||
            !willRenewWithSubscriptionPlanId)) ||
        willRenewWithSubscriptionPlanId === checkoutSession.plan.id
      ) {
        handleSubscriptionActivated()
      }
      break
    case "groupMembership": {
      const res = await checkGroupMembershipCode(
        checkoutSession.membershipCode,
      ).catch(logError)
      const subscriptionPlanId = res?.subscriptionPlanId
      if (
        subscriptionPlanId &&
        (subscriptionPlanId === subscribed ||
          (Array.isArray(subscribed) &&
            subscribed.includes(subscriptionPlanId)))
      ) {
        handleSubscriptionActivated()
      }
      break
    }
  }
}

/**
 * Takes an auth action event, checks it against the current checkout session,
 * if any, and ends the checkout session with a success message or moves the
 * user on to the next step of the checkout process
 */
export const handleCompletedAuthActionForCheckout = async (action: Action) => {
  await checkoutSessionManager.initialized
  const checkoutSession = checkoutSessionManager.getSession()
  const signedIn = action === "login" || action === "register"
  if (signedIn && checkoutSession) {
    try {
      switch (checkoutSession.type) {
        case "paidPlan":
        case "groupMembership":
          {
            const subscribed = (await fetchUserInfo())?.subscription.subscribed
            if (
              typeof subscribed === "number" ||
              (Array.isArray(subscribed) && subscribed.length > 0)
            ) {
              checkoutSessionManager.endSession({ complete: false })
              toast.success(t("You are already subscribed."))
            } else if (checkoutSession.type === "paidPlan") {
              await requestPayment({ checkoutSession })
            } else if (checkoutSession.type === "groupMembership") {
              await requestGroupMembership({ checkoutSession })
              await refetchUserSubscriptions()
            } else {
              checkoutSessionManager.endSession({ complete: false })
            }
          }
          break
      }
    } catch (error) {
      handleError(error)
    }
  } else if (signedIn) {
    toast.success(t("You are signed in!"))
  }
}

const handleError = async (error: unknown) => {
  await checkoutSessionManager.endSession({ complete: false })
  const message = getMessageFromAxiosError(error)
  logError(
    error instanceof DisplayableError
      ? error
      : new DisplayableError(
          message && message.toLowerCase().startsWith("invalid promo code")
            ? t("Invalid promo code.")
            : t("An error occurred. Please try again."),
          error,
        ),
  )
}

const handleSubscriptionActivated = () => {
  checkoutSessionManager.endSession({ complete: true })
  toast.success(t("Your subscription has been activated. Thank you!"))
}

const verifyCheckoutSession = async (checkoutSession: CheckoutSession) => {
  const provider =
    checkoutSession.type === "groupMembership"
      ? "groupMembership"
      : checkoutSession.plan.provider
  const subscriptionPlanId =
    checkoutSession.type === "groupMembership"
      ? checkoutSession.subscriptionPlanId
      : checkoutSession.plan.id

  if (!canPurchasePlanFromProvider(provider)) {
    throw new DisplayableError(
      t("Something went wrong. We've been notified and will take a look."),
      `Cannot initiate a checkout session for subscription plan with id "${subscriptionPlanId}" because the provider isn't supported on the platform`,
    )
  }
}

/**
 * Runs some last minute checks to make sure that completing the payment won't
 * put the user in an invalid subscripion state. Should be called inside the
 * requestPayment implimentation for each platform
 */
export async function ensureRequestPaymentAllowed(
  provider: "stripe" | "appStore" | "playStore" | "webPayment",
  oldPurchaseTokenAndroid?: string,
) {
  const { subscriptions, currentSubscriptionId, redundantSubscriptionIds } =
    await getUserSubscriptions()

  // If the user is in a bad state then stop now.
  if (redundantSubscriptionIds.length) {
    checkoutSessionManager.endSession({ complete: false })
    await refetchUserSubscriptions()
    throw new DisplayableError(
      t("You are already subscribed."),
      new Error(
        `[Checkout] User attempted payment while subscribed to multiple plans`,
      ),
    )
  }

  // Extract the user's current subscription and the plan that goes with it
  const subscription = subscriptions.find(
    (sub) => sub.id === currentSubscriptionId,
  )
  const currentPlan = subscription
    ? await getSubscriptionPlan(subscription.subscriptionPlanId)
    : undefined

  // If the user has an uncanceled paid plan from another provider then we ask
  // them to manually cancel it first. On a technical level we might be able to
  // cancel the plan automatically, but we can't prorate switches between
  // providers. The idea is that if we force the user to manually cancel the old
  // plan we will reduce the chance that they excpect the new plan to be
  // prorated when they sign up again. This is pretty subjective - implement
  // automatic cancelation and tear down this fence if you have good reason to.
  if (
    subscription &&
    currentPlan &&
    currentPlan.provider !== provider &&
    !subscription.canceledAt &&
    !subscription.deactivatedAt &&
    // Switching away from a group membership doesn't involve proration so we
    // allow the user to do this seamlessly.
    currentPlan.provider !== "groupMembership"
  ) {
    checkoutSessionManager.endSession({ complete: false })
    await refetchUserSubscriptions()
    throw new DisplayableError(
      t(
        "You are already subscribed. Please cancel your current subscription first.",
      ),
      new Error(
        `[Checkout] User with subscription id ${subscription.id} attempted payment on one platform, but they have an uncanceled subscription on another platform`,
      ),
    )
  }

  // We're switching plans on Android but we're missing the previous
  // subscription's purchase token.
  if (
    subscription &&
    provider === "playStore" &&
    currentPlan?.provider === "playStore" &&
    !subscription.canceledAt &&
    !subscription.deactivatedAt &&
    !oldPurchaseTokenAndroid
  ) {
    checkoutSessionManager.endSession({ complete: false })
    await refetchUserSubscriptions()
    throw new DisplayableError(
      t("Something went wrong, please try again."),
      new Error(
        `[Checkout] User with subscription id ${subscription.id} attempted payment on Android but the old purchase token wasn't provided when necessary`,
      ),
    )
  }

  // We can't use the request payment flow to switch Stripe plans (that must be
  // done via the Stripe billing portal or our built-in plan switching logic).
  if (
    subscription &&
    provider === "stripe" &&
    currentPlan?.provider === "stripe" &&
    !subscription.canceledAt &&
    !subscription.deactivatedAt
  ) {
    checkoutSessionManager.endSession({ complete: false })
    await refetchUserSubscriptions()
    throw new DisplayableError(
      t("You are already subscribed."),
      new Error(
        `[Checkout] User with subscription id ${subscription.id} attempted to open the Stripe checkout portal but they already have an uncanceled Stripe subscription`,
      ),
    )
  }
}
