import { Database } from "@nozbe/watermelondb"

import { adapter } from "./adapter"
import { Highlight } from "./models/highlight"

let offlineDb: Database

const getClient = () => {
  if (offlineDb) {
    return offlineDb
  }

  offlineDb = new Database({
    adapter,
    modelClasses: [Highlight],
  })

  return offlineDb
}

export { getClient }
