import { EventEmitter } from "@treefort/lib/event-emitter"
import { simpleHash } from "@treefort/lib/simple-hash"
import { logError } from "../logging"
import { Store } from "../store"
import { CheckoutSession } from "./session"

export enum Event {
  CheckoutSessionStarted = "CHECKOUT_SESSION_STARTED",
  CheckoutSessionChanged = "CHECKOUT_SESSION_CHANGED",
  CheckoutSessionEnded = "CHECKOUT_SESSION_ENDED",
}

interface EventMap {
  [Event.CheckoutSessionStarted]: CheckoutSession
  [Event.CheckoutSessionChanged]: CheckoutSession | null
  [Event.CheckoutSessionEnded]: {
    session: CheckoutSession
    complete: boolean
  }
}

/**
 * The checkout session manager is used to keep track of whether the user is in
 * the process of checking out a subscription (or free account).
 */
export class CheckoutSessionManager extends EventEmitter<EventMap> {
  private store: Store
  private session: CheckoutSession | null | undefined
  public readonly initialized: Promise<void>

  constructor() {
    super()
    this.store = new Store({ key: "checkoutSessionManager" })
    this.initialized = this.store
      .get<CheckoutSession>("session")
      .then(this.setSession)
      .catch(logError)
  }

  private setSession = (session: CheckoutSession | null): void => {
    if (simpleHash(session) !== simpleHash(this.session)) {
      this.session = session
      this.store.set("session", this.session).catch(logError)
      this.emitter.emit(Event.CheckoutSessionChanged, this.session)
    }
  }

  startSession = async (session: CheckoutSession): Promise<CheckoutSession> => {
    await this.initialized
    this.setSession(session)
    this.emitter.emit(Event.CheckoutSessionStarted, session)
    return session
  }

  endSession = async ({ complete }: { complete: boolean }): Promise<void> => {
    await this.initialized
    const session = this.session
    if (session) {
      this.setSession(null)
      this.emitter.emit(Event.CheckoutSessionEnded, { session, complete })
    }
  }

  getSession = (): CheckoutSession | null | undefined => this.session
}
