import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Text as RNText, View } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"

import { withObservables } from "@nozbe/watermelondb/react"
import { map, of } from "rxjs"
import styled from "styled-components/native"

import { MediaEpubSection } from "@treefort/api-spec"
import { useAuth } from "@treefort/lib/auth-provider"
import { colorWithAlpha } from "@treefort/lib/color"
import { compareCfis } from "@treefort/lib/compare-cfis"
import { triggerAndWaitForEvent } from "@treefort/lib/trigger-and-wait-for-event"
import rawTokens from "@treefort/tokens/app"

import { useActiveProfileId } from "../hooks/use-active-profile-id"
import { borderColorToString } from "../lib/border-color"
import { EbookConsumableContent } from "../lib/consumable-content"
import { readContentEbook } from "../lib/content-ebook"
import { formatDate } from "../lib/date"
import {
  EbookReaderEvent,
  EbookReaderEventMap,
  EbookReaderLocation,
  HIGHLIGHT_COLOR_PRESETS,
} from "../lib/ebook-reader"
import { ebookReader } from "../lib/ebook-reader"
import { getAbsoluteLineHeight } from "../lib/text-style"
import { Highlight, HighlightData } from "../watermelon/models/highlight"
import { highlightStore } from "../watermelon/stores/highlight"
import { syncManager } from "../watermelon/sync"
import Column from "./column"
import { highlightDetail } from "./ebook-reader-fullscreen/highlight-detail"
import ListView from "./list-view"
import Modal, { MODAL_HEADER_HEIGHT_PX } from "./modal"
import Row from "./row"
import Text from "./text"
import { useTokens } from "./tokens-provider"
import Touchable from "./touchable"

type Item =
  | { type: "header"; section: { id: string; title: string } }
  | { type: "highlight"; highlight: HighlightData }

// Constants for spacing and text styles so that item height can be calculated
// by ListView
const SECTION_HEADER_PADDING = rawTokens.spacing.medium
const SECTION_HEADER_TEXT_STYLE = "headingMedium"
const SECTION_GAP = rawTokens.spacing.small
const HIGHLIGHT_TIME_AND_PROGRESS_TEXT_STYLE = "strong"
const HIGHLIGHTED_TEXT_STYLE = "body"
const NOTES_TEXT_STYLE = "body"

const scrollOffsetByContentId = new Map<number, number>()

const MeasureView = styled.View`
  position: absolute;
  top: ${MODAL_HEADER_HEIGHT_PX}px;
  left: 0;
  bottom: 0;
  right: 0;
`

const SectionHeader = styled.View<{ width: number }>`
  width: ${({ width }) => width}px;
  padding: ${SECTION_HEADER_PADDING}px;
  background-color: ${({ theme }) => theme.colors.background.primary};
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${({ theme }) => borderColorToString(theme, "primary")};
`

const SectionItem = styled(Touchable)<{
  showBorder?: boolean
  bottomInset?: number
  width: number
}>`
  width: ${({ width }) => width}px;
  padding: ${({ theme, bottomInset = 0 }) =>
    `${SECTION_GAP}px ${theme.spacing.medium}px ${
      SECTION_GAP + bottomInset
    }px`};
  flex-direction: column;
  row-gap: ${SECTION_GAP}px;
  border-bottom-width: ${({ showBorder }) => (showBorder ? 1 : 0)}px;
  border-bottom-style: solid;
  border-bottom-color: ${({ theme }) => borderColorToString(theme, "primary")};
  background-color: ${({ theme }) => theme.colors.background.tertiary};
`

const HighlightedText = styled(Text)<{
  highlightColor: string | undefined
  highlightOpacity: number | undefined
}>`
  background-color: ${({ highlightColor, highlightOpacity: opacity }) =>
    highlightColor && opacity && colorWithAlpha(highlightColor, opacity)};
`

/**
 * Seperating the modal content into its own component allows WatermelonDB's HOC
 * for hooking React components up to observables to sit as low as possible in
 * the component hierarchy, avoiding unecessary observable subscriptions
 */
function ModalContent({
  consumableContent,
  onClose,
  onWillChange,
  highlights,
}: {
  consumableContent: EbookConsumableContent
  onClose: () => void
  onWillChange?: (highlight: HighlightData) => void
  highlights: HighlightData[]
}) {
  const { t, i18n } = useTranslation()
  const { tokens } = useTokens()
  const profileId = useActiveProfileId()
  const [viewSize, setViewSize] = useState<{ width: number; height: number }>()
  const { bottom: bottomInset } = useSafeAreaInsets()

  // Look up the last scroll offset on mount so that the user's position is
  // preserved between opening and closing the modal
  const initialOffset = useRef(
    scrollOffsetByContentId.get(consumableContent.content.id),
  )

  const goToHighlight = (highlight: HighlightData) => {
    const location: EbookReaderLocation = {
      type: "requested",
      location: {
        type: "epubCfi",
        epubCfi: highlight.startCfi,
      },
      metadata: {
        sectionId: highlight.sectionId,
      },
    }

    if (
      ebookReader.getEbook()?.extra.consumableContent.content.id ===
      consumableContent.content.id
    ) {
      ebookReader.setLocation(location)
    } else if (consumableContent) {
      readContentEbook({
        consumableContent,
        profileId,
        location,
      })
    }
  }

  const media =
    consumableContent.content.details.ebookMedia?.status === "available"
      ? consumableContent.content.details.ebookMedia.data
      : undefined

  // Use the media table of contents to build a flattened object of sections
  // keyed by id
  const sectionsById = useMemo(
    () =>
      Object.fromEntries(
        (function getEntries(
          sections?: MediaEpubSection[],
        ): [string, MediaEpubSection][] {
          return (
            sections?.flatMap((section) => [
              [section.id, section],
              ...getEntries(section?.subitems),
            ]) || []
          )
        })(media?.tableOfContents),
      ),
    [media?.tableOfContents],
  )

  // Group highlights into their respective sections. Highlights that don't
  // belong to a section are grouped with the previous section if there is one,
  // otherwise they're grouped in an untitled section at the beginning
  const sections = useMemo(
    () =>
      highlights.reduce(
        // Spread sections to prevent mutations to the array we're iterating over
        ([...sections], highlight, highlightIndex) => {
          let sectionId: string | undefined

          if (highlight.sectionId) {
            // Group the highlight by its section
            sectionId = highlight.sectionId
          } else {
            // If the highlight doesn't belong to a section, then starting with the
            // previous highlight iterate backwards until we find a highlight that
            // does belong to a section, and group the highlight under that section
            sectionId = highlights
              ?.slice(0, highlightIndex)
              .reverse()
              .find((highlight) => Boolean(highlight.sectionId))?.sectionId
          }

          // If a section for the highlight can't be found then put it in a section with
          // id "untitled-section" that will go at the beginning
          sectionId ||= "untitled-section"

          // If the highlight's section already exists in the sections array, then
          // use that, otherwise create a new section
          const section = sections.find(
            (section) => section.id === sectionId,
          ) || {
            id: sectionId,
            title: sectionId ? sectionsById[sectionId]?.label : undefined,
            highlights: [],
          }

          // If a new section was created for the highlight, add it to the sections
          // array (safe to push to sections because of the spread up above)
          if (!sections.some((section) => section.id === sectionId)) {
            sections.push(section)
          }

          // Add the highlight to the section
          section.highlights.push(highlight)

          return sections
        },
        [] as {
          id: string
          title?: string
          highlights: HighlightData[]
        }[],
      ),
    [highlights, sectionsById],
  )

  // Flatten the sections array to individual items for rendering
  const items = sections.flatMap(({ highlights, ...section }) => [
    ...(section.title
      ? [{ type: "header", section } as Extract<Item, { type: "header" }>]
      : []),
    ...highlights.map((highlight) => ({
      type: "highlight" as const,
      highlight,
    })),
  ])

  const getItemSize = useCallback(
    (item: Item, index: number) => {
      switch (item.type) {
        case "header":
          return (
            SECTION_HEADER_PADDING * 2 +
            getAbsoluteLineHeight(SECTION_HEADER_TEXT_STYLE, tokens) +
            1
          )
        case "highlight":
          return (
            SECTION_GAP +
            (item.highlight.updatedAtDate || item.highlight.progressPercent
              ? getAbsoluteLineHeight(
                  HIGHLIGHT_TIME_AND_PROGRESS_TEXT_STYLE,
                  tokens,
                ) + SECTION_GAP
              : 0) +
            getAbsoluteLineHeight(HIGHLIGHTED_TEXT_STYLE, tokens) +
            SECTION_GAP +
            (item.highlight.notes
              ? getAbsoluteLineHeight(NOTES_TEXT_STYLE, tokens) + SECTION_GAP
              : 0) +
            (index === items.length - 1 ? bottomInset : 1)
          )
      }
    },
    [tokens, items.length, bottomInset],
  )

  return (
    <>
      <MeasureView
        onLayout={(event) =>
          setViewSize({
            ...event.nativeEvent.layout,
            height: event.nativeEvent.layout.height,
          })
        }
      />
      <View
        style={{
          // This View is used to reserve the space that would normally be taken
          // up by the highlights in the absolutely positioned ListView. This
          // ensures that the modal expands vertically as necessary to
          // accomodate larger numbers of highlights.
          height: items.reduce(
            (height, item, i) => height + getItemSize(item, i),
            0,
          ),
        }}
      />
      {!items.length ? (
        <Column
          paddingVertical="large"
          paddingHorizontal="small"
          backgroundColor="tertiary"
          alignItems="center"
        >
          <Text textStyle="body" alignment="center" color="secondary">
            {t(
              "You can view your highlights and notes here. Select text in the book to get started.",
            )}
          </Text>
        </Column>
      ) : viewSize ? (
        <ListView
          scrollEventThrottle={8}
          scrollViewDataSet={{ webScrollUnlock: true }}
          initialOffset={initialOffset.current}
          items={items}
          getItemKey={(item) =>
            item.type === "header"
              ? `section-${item.section.id}`
              : `item-${item.highlight.id}`
          }
          onScroll={(event) => {
            scrollOffsetByContentId.set(
              consumableContent.content.id,
              event.nativeEvent.contentOffset.y,
            )
          }}
          getItemSize={getItemSize}
          viewSize={viewSize}
          style={[
            viewSize,
            { position: "absolute", top: MODAL_HEADER_HEIGHT_PX, left: 0 },
          ]}
          renderItem={(item, i) =>
            item.type === "header" ? (
              <SectionHeader
                key={"header-" + item.section.id}
                width={viewSize.width}
              >
                <Text numberOfLines={1} textStyle="headingMedium">
                  {item.section.title}
                </Text>
              </SectionHeader>
            ) : (
              <SectionItem
                width={viewSize.width}
                key={item.highlight.id}
                showBorder={i !== items.length - 1}
                bottomInset={i === items.length - 1 ? bottomInset : undefined}
                onPress={async () => {
                  onClose()
                  onWillChange?.(item.highlight)

                  // Navigate to the highlight in the ebook and wait for
                  // the ebook to finish rendering
                  await triggerAndWaitForEvent<
                    EbookReaderEventMap[EbookReaderEvent.LocationChanged]
                  >({
                    trigger: () => goToHighlight(item.highlight),
                    addEventListener: (listener) =>
                      ebookReader.on(
                        EbookReaderEvent.LocationChanged,
                        listener,
                      ),
                    shouldAcceptEvent: (event) =>
                      event.current?.type === "rendered",
                  })

                  highlightDetail.setHighlight(item.highlight)
                }}
              >
                {item.highlight.updatedAtDate ||
                typeof item.highlight.progressPercent === "number" ? (
                  <Row
                    justifyContent={
                      item.highlight.progressPercent
                        ? "space-between"
                        : "flex-start"
                    }
                  >
                    {item.highlight.updatedAtDate ? (
                      <Text textStyle="strong">
                        {formatDate(new Date(item.highlight.updatedAtDate), {
                          i18n,
                          strategy: "naturalDate",
                        })}
                      </Text>
                    ) : null}
                    {item.highlight.progressPercent ? (
                      // eslint-disable-next-line @shopify/jsx-no-hardcoded-content
                      <Text textStyle="strong">
                        {Math.round(item.highlight.progressPercent * 100)}%
                      </Text>
                    ) : null}
                  </Row>
                ) : null}
                <RNText
                  numberOfLines={1}
                  // Wrapping the inner text component in a RN Text
                  // component allows us to set the background color on the
                  // inner component to show the highlight
                  // - https://stackoverflow.com/a/46538612/14397313
                >
                  <HighlightedText
                    numberOfLines={1}
                    textStyle="body"
                    highlightColor={
                      HIGHLIGHT_COLOR_PRESETS.find(
                        (preset) => preset.id === item.highlight.colorPresetId,
                      )?.fill
                    }
                    highlightOpacity={
                      HIGHLIGHT_COLOR_PRESETS.find(
                        (preset) => preset.id === item.highlight.colorPresetId,
                      )?.fillOpacity
                    }
                  >
                    {item.highlight.selectedText}
                  </HighlightedText>
                </RNText>
                {item.highlight.notes ? (
                  <Text numberOfLines={1} textStyle="body">
                    {item.highlight.notes}
                  </Text>
                ) : null}
              </SectionItem>
            )
          }
        />
      ) : null}
    </>
  )
}

const ModalContentWithHighlights = withObservables(
  ["consumableContent", "userId", "profileId"],
  ({
    consumableContent,
    userId,
    profileId,
  }: {
    consumableContent: EbookConsumableContent
    userId: string | undefined
    profileId: string | null
  }) => ({
    highlights: userId
      ? highlightStore
          .findBy({
            contentId: consumableContent.content.id,
            userId,
            profileId,
          })
          .observeWithColumns(["updatedAtDate"])
          .pipe(
            // Sort the highlights by cfi
            map((highlights) =>
              highlights
                .map(Highlight.toPlainObject)
                .sort((a, b) => compareCfis(a.startCfi, b.startCfi)),
            ),
          )
      : of([] as HighlightData[]),
  }),
)(ModalContent)

export function EbookHighlightsSelect({
  consumableContent,
  open,
  onClose,
  onWillChange,
}: {
  consumableContent: EbookConsumableContent
  open: boolean
  onClose: () => void
  onWillChange?: (highlight: HighlightData) => void
}) {
  const { t } = useTranslation()
  const auth = useAuth()
  const profileId = useActiveProfileId()

  useEffect(() => {
    if (open) {
      syncManager.requestSync({ syncType: "user-initiated" })
    }
  }, [open])

  return (
    <Modal
      title={t("Highlights")}
      open={open}
      onPressCloseButton={onClose}
      onPressOutside={onClose}
      type="sheet"
      portalHost="foreground"
      backgroundColor="tertiary"
    >
      <ModalContentWithHighlights
        profileId={profileId}
        userId={auth.user?.id}
        onClose={onClose}
        onWillChange={onWillChange}
        consumableContent={consumableContent}
      />
    </Modal>
  )
}
