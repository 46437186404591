import {
  CheckoutParameters,
  ScreenViewParameters,
  Event,
  EventName,
  Parameter,
  AnalyticsPlugin,
  User,
} from "@treefort/lib/analytics"
import { getInvertedPromise } from "@treefort/lib/inverted-promise"
import { getMetaPixelSubscriptionLifecycleEventId } from "@treefort/lib/meta-pixel"

import config from "../../../../config"
import { fetchUserInfo } from "../../../../hooks/use-user-info"
import { addConsentListener, checkConsent } from "../../../consent"

// https://developers.facebook.com/docs/marketing-api/conversions-api/subscription-lifecycle-events
const getMetaPixelSubscriptionLifecycleParams = async () => {
  const userInfo = await fetchUserInfo()
  const subscription_id = userInfo?.subscription.id
  if (subscription_id) {
    return {
      subscription_id,
    }
  } else {
    return {} as Record<string, unknown>
  }
}

/**
 * Get the 4th argument to fbq (used to deduplicate events that are sent both
 * via the metapixel and via the conversions api)
 * - https://developers.facebook.com/docs/marketing-api/conversions-api/deduplicate-pixel-and-server-events/
 */
const getMetaPixelEventIdOptions = async (eventName: string) => {
  const userInfo = await fetchUserInfo()
  const subscribed = userInfo?.subscription.subscribed
  const subscriptionPlanId =
    typeof subscribed === "number"
      ? subscribed
      : Array.isArray(subscribed)
        ? subscribed[0]
        : undefined
  if (userInfo && subscriptionPlanId) {
    return {
      eventID: getMetaPixelSubscriptionLifecycleEventId({
        userId: userInfo.id,
        subscriptionPlanId,
        eventName,
      }),
    }
  } else {
    undefined
  }
}

const getMetaPixelCheckoutParams = (params: CheckoutParameters) => ({
  currency: params[Parameter.Currency],
  value: params[Parameter.Price],
  [Parameter.SubscriptionPlanId]: params[Parameter.SubscriptionPlanId],
  [Parameter.CheckoutCode]: params[Parameter.CheckoutCode],
})

const getMetaPixelScreenViewParams = (params: ScreenViewParameters) => ({
  [Parameter.ScreenPath]: params[Parameter.ScreenPath],
  [Parameter.ScreenPageId]: params[Parameter.ScreenPageId],
  [Parameter.ScreenTabId]: params[Parameter.ScreenTabId],
})

class MetaPixelAnalyticsPlugin implements AnalyticsPlugin {
  private fbqInitCalled = false
  private initialized = getInvertedPromise()

  constructor() {
    addConsentListener("advertisingCookies", (advertisingCookies) => {
      if (typeof fbq !== "undefined") {
        fbq("consent", advertisingCookies === "granted" ? "grant" : "revoke")
      }
    })
  }

  identifyUser = async (user: User) => {
    // Bail if we don't have a pixel id
    if (!config.META_PIXEL_ID) {
      return
    }

    // IMPORTANT: fbq can only be initialized once, otherwise terrible things
    // like duplicate logging of all events may start to happen. This shouldn't
    // be a problem as the web app is reloaded any time the user signs in or
    // out.
    if (typeof fbq !== "undefined" && !this.fbqInitCalled) {
      const [userInfo, advertisingCookies] = await Promise.all([
        fetchUserInfo(),
        checkConsent("advertisingCookies"),
      ])

      fbq("consent", advertisingCookies === "granted" ? "grant" : "revoke")

      const em = userInfo?.email.trim().toLowerCase()
      fbq("init", config.META_PIXEL_ID, {
        external_id: user[Parameter.UserId],
        // https://developers.facebook.com/docs/meta-pixel/advanced/advanced-matching/
        ...(em && { em }),
      })
      fbq.disablePushState = true
      this.fbqInitCalled = true
      this.initialized.resolveOutside()
    }
  }

  logEvent = async (event: Event) => {
    // Bail if we don't have a pixel id
    if (!config.META_PIXEL_ID) return

    // Wait for the pixel to be initiealized before logging the event
    await this.initialized

    switch (event.name) {
      case EventName.CheckoutStart:
        fbq?.(
          "track",
          "InitiateCheckout",
          getMetaPixelCheckoutParams(event.parameters),
        )
        break
      case EventName.CheckoutComplete: {
        const eventName = event.parameters[Parameter.SubscriptionFreeTrial]
          ? "StartTrial"
          : "Subscribe"
        fbq?.(
          "track",
          eventName,
          {
            ...getMetaPixelCheckoutParams(event.parameters),
            ...getMetaPixelSubscriptionLifecycleParams(),
          },
          await getMetaPixelEventIdOptions(eventName),
        )
        break
      }
      case EventName.UserRegistered:
        fbq?.("track", "CompleteRegistration")
        break
      case EventName.Contact:
        fbq?.("track", "Contact")
        break
      case EventName.Search:
        fbq?.("track", "Search", {
          search_string: event.parameters[Parameter.SearchQuery],
        })
        break
      case EventName.ScreenView:
        fbq?.(
          "track",
          "PageView",
          getMetaPixelScreenViewParams(event.parameters),
        )
        if (event.parameters[Parameter.ContentId]) {
          fbq?.("track", "ViewContent", {
            ...getMetaPixelScreenViewParams(event.parameters),
            content_ids: [event.parameters[Parameter.ContentId]],
          })
        }
        break
    }
  }
}

export const metaPixelAnalyticsPlugin = new MetaPixelAnalyticsPlugin()
