import React from "react"
import styled from "styled-components/native"
import { clamp } from "@treefort/lib/clamp"
import { getOptimizedImageSource } from "@treefort/lib/get-optimized-image-source"
import Row from "./row"
import Spacer from "./spacer"
import { Spacing, spacingToNumber } from "../lib/spacing"
import { useTokens } from "./tokens-provider"
import useWindowDimensions from "../hooks/use-window-dimensions"
import ImageContained from "./image-contained"
import LockedContentIndicator from "./locked-content-indicator"
import CenteredContent from "./centered-content"
import Column from "./column"
import { Shape } from "@treefort/api-spec"

const Artwork = styled.View`
  position: relative;
`

const Content = styled.View`
  ${(props) =>
    props.theme.artworkFullPage.layout === "portrait" ? "width: 100%" : ""};
  ${(props) =>
    props.theme.artworkFullPage.layout === "landscape" ? "flex: 1" : ""};
`

/**
 * Layout shared by the audiobook, ebook, and podcast episode modules
 */
export default function ModuleArtworkLayout({
  artwork,
  artworkShape,
  isLocked,
  maxWidth,
  paddingTop = "medium",
  onArtworkReady,
  children,
}: {
  artwork?: string
  artworkShape?: Shape
  isLocked?: boolean
  maxWidth?: number
  paddingTop?: Spacing
  onArtworkReady?: (ready: boolean) => unknown
  children:
    | React.ReactNode
    | ((props: { layout: "landscape" | "portrait" }) => JSX.Element)
}): JSX.Element {
  const { tokens } = useTokens()
  const {
    artworkFullPage: {
      layout,
      artworkMaxSize,
      artworkMinSize,
      artworkHeightPercentage,
      artworkWidthPercentage,
    },
    spacing: { pagePaddingHorizontal },
  } = tokens
  const windowDimensions = useWindowDimensions()

  // Get a rough estimate of the total space available in the viewport for the
  // artwork, multiply the space by the percentage of the space the artwork
  // should take up, and then constrain the result to the max size allowd.
  const artworkHeight = clamp(
    (windowDimensions.height - spacingToNumber(tokens, paddingTop)) *
      artworkHeightPercentage,
    artworkMinSize,
    artworkMaxSize,
  )
  const artworkWidth = clamp(
    (windowDimensions.width - pagePaddingHorizontal * 2) *
      artworkWidthPercentage,
    artworkMinSize,
    artworkMaxSize,
  )

  const artworkElement = artwork ? (
    <Artwork>
      <ImageContained
        uri={getOptimizedImageSource(artwork, artworkMaxSize)}
        borderRadius={
          artworkShape === "circle" ? "roundedFull" : "roundedLarge"
        }
        containerSize={{ height: artworkHeight, width: artworkWidth }}
        onReady={onArtworkReady}
        aspectRatio={
          artworkShape ? tokens.aspectRatios[artworkShape] : undefined
        }
      />
      {isLocked ? <LockedContentIndicator /> : null}
    </Artwork>
  ) : undefined

  return (
    <CenteredContent paddingTop={paddingTop} maxWidth={maxWidth}>
      {layout === "portrait" ? (
        <Column>
          {artworkElement}
          <Spacer size="large" />
          <Content>
            {typeof children === "function"
              ? children({ layout: "portrait" })
              : children}
          </Content>
        </Column>
      ) : (
        <Row alignItems="flex-start" paddingTop="xlarge">
          {artworkElement}
          <Spacer size="jumbo" horizontal />
          <Content>
            {typeof children === "function"
              ? children({ layout: "landscape" })
              : children}
          </Content>
        </Row>
      )}
    </CenteredContent>
  )
}
