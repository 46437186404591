import { Platform } from "react-native"

import { Event, EventName, Parameter } from "@treefort/lib/analytics"

export function transformValue(value: unknown) {
  return typeof value !== "boolean" ? value : value ? "TRUE" : "FALSE"
}

export function transformParameters(
  parameters: Record<string, unknown> | undefined,
) {
  return parameters
    ? Object.fromEntries(
        Object.entries(parameters).flatMap(([key, value]) => {
          switch (key) {
            // Include the deprecated tf_subscribed parameter
            case Parameter.SubscriptionPlanId:
              return [
                [Parameter.SubscriptionPlanId, transformValue(value)],
                ["tf_subscribed", transformValue(Boolean(value))],
              ]
            // Ignore these parameters as they're handled out of the box
            case Parameter.Platform:
            case Parameter.ClientId:
            case Parameter.UserId:
            case Parameter.TenantId:
            case Parameter.UserAuthenticated:
              return []
            default:
              return [[key, transformValue(value)]]
          }
        }),
      )
    : {}
}

export function transformEvents(events: Array<Event>) {
  return events.flatMap((event) => {
    switch (event.name) {
      // Log GA's built-in screen_view event for screen views
      case EventName.ScreenView: {
        const parameters = transformParameters(event.parameters)
        return [
          {
            name: "screen_view",
            parameters: {
              ...parameters,
              ...(Platform.OS === "web"
                ? {
                    firebase_screen: parameters[Parameter.ScreenPath],
                    firebase_screen_class: parameters[Parameter.ScreenPath],
                  }
                : {
                    screen_name: parameters[Parameter.ScreenPath],
                    screen_class: parameters[Parameter.ScreenPath],
                  }),
            },
          },
        ]
      }
      // Include deprecated "start play audio/video" events for play requests
      case EventName.PlayRequest: {
        return [
          {
            name: event.name,
            parameters: transformParameters(event.parameters),
          },
          {
            name:
              event.parameters[Parameter.PlayMode] === "watch"
                ? "treefort_start_play_video"
                : "treefort_start_play_audio",
            parameters: transformParameters({
              treefort_content_id: event.parameters[Parameter.ContentId],
              treefort_content_title: event.parameters[Parameter.ContentTitle],
              treefort_content_type: event.parameters[Parameter.ContentType],
              treefort_podcast_episode:
                event.parameters[Parameter.ContentPodcastEpisode],
            }),
          },
        ]
      }
      default:
        return [
          {
            name: event.name,
            parameters: transformParameters(event.parameters),
          },
        ]
    }
  })
}
