"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatDuration = exports.getDuration = void 0;
function getDuration(milliseconds) {
    const seconds = milliseconds / 1000;
    return {
        hours: Math.floor(seconds / (60 * 60)),
        minutes: Math.floor((seconds % (60 * 60)) / 60),
        seconds: Math.floor(seconds % 60),
    };
}
exports.getDuration = getDuration;
function formatDuration({ units, countdown, duration: { hours = 0, minutes = 0, seconds = 0 }, }) {
    let formattedDuration = countdown ? "-" : "";
    // Display the time with units (e.g. "1h 10m")
    if (units) {
        formattedDuration += [
            hours && `${hours}h`,
            minutes && `${minutes}min`,
            !hours && (!minutes || seconds) && `${seconds}s`,
        ]
            .filter(Boolean)
            .join(" ");
    }
    // Display the time without units (e.g. "1:10:00")
    else {
        formattedDuration += [
            hours ? hours.toString() : "",
            hours ? padDuration(minutes) : minutes.toString(),
            padDuration(seconds),
        ]
            .filter(Boolean)
            .join(":");
    }
    return formattedDuration;
}
exports.formatDuration = formatDuration;
function padDuration(duration) {
    const str = duration.toString();
    return str.length < 2 ? "0" + str : str;
}
