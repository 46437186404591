import React, { useEffect, useState } from "react"
import { Linking } from "react-native"
import { GestureHandlerRootView } from "react-native-gesture-handler"

import { PortalHost, PortalProvider } from "@gorhom/portal"
import * as Sentry from "@sentry/react-native"
import { t } from "i18next"

import AuthProvider from "@treefort/lib/auth-provider"

import { ActiveProfileProvider } from "./components/active-profile-provider"
import AppManifestProvider from "./components/app-manifest-provider"
import AppPreviewProvider from "./components/app-preview-provider/index"
import { ConsentPopup } from "./components/consent-popup"
import { EbookReaderFullscreen } from "./components/ebook-reader-fullscreen"
import { InitializeApp } from "./components/initialize-app"
import { InitializeAppPreview } from "./components/initialize-app-preview"
import { InitializeAudioPlayer } from "./components/initialize-audio-player"
import { InitializeI18n } from "./components/initialize-i18n"
import Onboarding from "./components/onboarding"
import { ProfilesOverlay } from "./components/profiles-overlay"
import SafeAreaProviderSync from "./components/safe-area-provider-sync"
import Toaster from "./components/toaster"
import { RootTokensProvider } from "./components/tokens-provider"
import { RouteScrollOffsetProvider } from "./hooks/use-route-scroll-offset"
import authenticator from "./lib/authenticator"
import { logError, logMessage } from "./lib/logging"
import { queryClient, QueryClientProvider } from "./lib/query-client"
import Navigation from "./navigation"
import { SplashScreen } from "./navigation/screens/splash"

const onAuthSessionExpired = () => {
  logMessage("User session expired")
  authenticator.login()
}

const beforeCaptureError = (scope: Sentry.Scope) => {
  scope.setTag("boundary", "root")
}

const renderErrorFallback = () => (
  <SplashScreen
    message={{
      title: t("App Error"),
      description: t(
        "An error occurred. Please contact us if the issue persists.",
      ),
    }}
  />
)

const gestureHandlerRootViewStyle = { flex: 1 }

export default function App(): JSX.Element | null {
  const [initialUrl, setInitialUrl] = useState<{
    url: string | null
    loading: boolean
  }>({ url: null, loading: true })

  // Load the URL that triggered the opening of the app (if that's how the app
  // was opened)
  useEffect(() => {
    if (initialUrl.loading) {
      Linking.getInitialURL().then((url) =>
        setInitialUrl({ url, loading: false }),
      )
    }
  }, [initialUrl])

  /**
   * The reason for order of providers below is:
   * - SafeAreaProviderSync: The SplashScreen passed to Sentry.ErrorBoundary's
   *   renderErrorFallback requires safe area insets, so SafeAreaProviderSync
   *   has to exist above Sentry.ErrorBoundary.
   * - GestureHandlerRootView: This should wrap everything that renders a
   *   touchable. Required by react-native-gesture-handler.
   * - Sentry.ErrorBoundary: This is our final catch-all for runtime errors.
   *   Everything that can go inside this component should go inside it.
   * - QueryClientProvider: Everything that requires react-query must go inside
   *   this.
   * - AuthProvider: Almost everything in the app depends on the user's auth
   *   state, so this provider is placed as high as possible.
   * - AppInit: This needs to exist above AppManifestProvider so that manifest
   *   refreshes don't re-run app initialization logic but inside the
   *   AuthProvider so we can access the user's identity during initialization.
   * - RouteScrollOffsetProvider: This also needs to exist outside
   *   AppManifestProvider so that a manifest refresh doesn't necessarily blow
   *   away cached scroll positions throughout the app.
   * - AppPreviewProvider: This needs to exist outside AppManifestProvider so we
   *   can determine whether to use the production manifest loading strategy
   *   (lotsa caching) or the preview manifest loading strategy (realtime
   *   updates).
   * - AppManifestProvider: Everything that depends on the app's theme, design,
   *   etc. needs to go inside this.
   * - RootTokensProvider: This must go inside the AppManifestProvider and
   *   outside everything that requires tokens.
   * - Toaster: This should be rendered outside the portal provider so that
   *   toasts are rendered above modals.
   * - PortalProvider: Everything that requires modals must go inside this.
   *   Everything that should be rendered above modals (e.g. toasts) must go
   *   outside this.
   * - ActiveProfileProvider: Must be above the ProfilesOverlayProvider and any
   *   other code that depends on the active profile.
   * - ProfilesOverlayProvider: This needs to go inside Portal because it
   *   renders the profiles screens with a portal.
   */
  return initialUrl.loading === false ? (
    <SafeAreaProviderSync>
      <GestureHandlerRootView style={gestureHandlerRootViewStyle}>
        <Sentry.ErrorBoundary
          beforeCapture={beforeCaptureError}
          fallback={renderErrorFallback}
        >
          <QueryClientProvider client={queryClient}>
            <AuthProvider
              authenticator={authenticator}
              initialUrl={initialUrl.url}
              onError={logError}
              onSessionExpired={onAuthSessionExpired}
            >
              <InitializeApp />
              <RouteScrollOffsetProvider>
                <AppPreviewProvider>
                  <AppManifestProvider>
                    <InitializeAppPreview />
                    <InitializeI18n>
                      <RootTokensProvider>
                        <Toaster />
                        <ActiveProfileProvider>
                          <PortalProvider>
                            <Navigation />
                            <ConsentPopup />
                            <InitializeAudioPlayer />
                            <EbookReaderFullscreen />
                            <Onboarding />
                            <ProfilesOverlay />
                            <PortalHost name="middleground" />
                            <PortalHost name="foreground" />
                          </PortalProvider>
                        </ActiveProfileProvider>
                      </RootTokensProvider>
                    </InitializeI18n>
                  </AppManifestProvider>
                </AppPreviewProvider>
              </RouteScrollOffsetProvider>
            </AuthProvider>
          </QueryClientProvider>
        </Sentry.ErrorBoundary>
      </GestureHandlerRootView>
    </SafeAreaProviderSync>
  ) : null
}
