import React, { useState } from "react"

import styled from "styled-components/native"

import { MediaImageFile, MediaUrl } from "@treefort/api-spec"
import { getOptimizedImageSource } from "@treefort/lib/get-optimized-image-source"

import { Blurhash } from "./blurhash"
import Image from "./image"
import LockedContentIndicator from "./locked-content-indicator"
import { useTokens } from "./tokens-provider"

const BLURHASH_RESOLUTION = 8

const Container = styled.View<{
  size?: number
  backgroundColor: string
}>`
  position: relative;
  border-radius: ${(props) => props.theme.thumbnailArtwork.borderRadius}px;
  overflow: hidden;
  background-color: ${(props) => props.backgroundColor};
  width: ${(props) => (props.size ? `${props.size}px` : "100%")};
  aspect-ratio: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const BlurhashContainer = styled.View`
  position: absolute;
  width: 100%;
  height: 100%;
`

const BlurhashOverlay = styled.View`
  position: absolute;
  background-color: #000000;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.6;
`

const GridImage = styled(Image)`
  width: 50%;
  height: 50%;
`

const SingleImage = styled(Image)`
  width: 100%;
  height: 100%;
`

/**
 * Renders a thumbnail. Can take a single piece of artwork to fill the thumbnail
 * or multiple pieces to render in a 4x4 grid.
 */
export function SquareThumbnail({
  source,
  size,
  showLock,
  blurhash,
  alpha,
}: {
  /**
   * If an array of images are provided then a grid of repeating images will be
   * rendered for the thumbnail.
   */
  source?: string | Array<string | undefined>
  /**
   * The width/height of the thumbnail. If omitted the thumbnail will fill 100%
   * of its parent's width.
   */
  size?: number
  /**
   * Show a locked content indicator over the thumbnail
   */
  showLock?: boolean
  /**
   * If the thumbnail has a blurhash then provide that here. We'll render the
   * blurhash in the background and contain the actual thumbnail to avoid
   * cropping.
   */
  blurhash?: string
  /**
   * If the thumbnail has transparency then set this to true. We'll render a
   * transparent background once the image has loaded so the transparency works
   * as expected.
   */
  alpha?: boolean
}): JSX.Element {
  const { tokens } = useTokens()
  const [loaded, setLoaded] = useState(false)
  const sourceArray = (Array.isArray(source) ? source : [source]).filter(
    (source): source is string => typeof source === "string",
  )
  const sourceLength = sourceArray.length
  const optimizedImageSize = size ?? tokens.thumbnailArtwork.maxSourceSize
  return (
    <Container
      size={size}
      backgroundColor={
        (alpha || blurhash) && loaded
          ? "transparent"
          : tokens.colors.loading.image
      }
    >
      {
        // Show a blurhash in the background. Only do this when the image is
        // loaded - we use blurhashes as a nice fill behind contained images, not
        // as placeholders for loading images.
        !alpha && blurhash && loaded ? (
          <BlurhashContainer>
            <Blurhash
              hash={blurhash}
              width="100%"
              height="100%"
              resolutionX={BLURHASH_RESOLUTION}
              resolutionY={BLURHASH_RESOLUTION}
            />
            <BlurhashOverlay />
          </BlurhashContainer>
        ) : null
      }
      {sourceLength > 1 ? (
        // Expand the array to 4 items and ensure that the same item is never
        // shown next to itself.
        [
          sourceArray[0],
          sourceArray[1 % sourceLength],
          sourceArray[(sourceLength === 2 ? 3 : 2) % sourceLength],
          sourceArray[(sourceLength === 2 ? 2 : 3) % sourceLength],
        ].map((source, i) => (
          <GridImage
            key={`${source}-${i}`}
            source={{
              uri: getOptimizedImageSource(source, optimizedImageSize),
            }}
            resizeMode="cover"
          />
        ))
      ) : sourceLength === 1 ? (
        <SingleImage
          source={{
            uri: getOptimizedImageSource(sourceArray[0], optimizedImageSize),
          }}
          resizeMode={
            // Contain the image if we're rendering a blurhash or a transparent
            // background behind it.
            blurhash || alpha ? "contain" : "cover"
          }
          onLoad={() => setLoaded(true)}
        />
      ) : null}
      {showLock ? <LockedContentIndicator /> : null}
    </Container>
  )
}

/**
 * A square thumbnail that accepts media as an input.
 */
export function SquareMediaThumbnail({
  media,
  size,
  showLock,
}: {
  /**
   * If an array of images are provided then a grid of repeating images will be
   * rendered for the thumbnail.
   */
  media?:
    | MediaImageFile
    | MediaUrl
    | Array<MediaImageFile | MediaUrl | undefined>
  /**
   * The width/height of the thumbnail. If omitted the thumbnail will fill 100%
   * of its parent's width.
   */
  size?: number
  /**
   * Show a locked content indicator over the thumbnail
   */
  showLock?: boolean
}) {
  const mediaArray = Array.isArray(media) ? media : [media]

  const urls = mediaArray.map((media) => {
    switch (media?.type) {
      case "imageFile":
        return media.original.url
      case "url":
        return media.url
    }
  })

  // If we're dealing with a single image file then we will do some fun tricks
  // with transparency and blurhash
  const onlyImageFile =
    mediaArray.length === 1
      ? mediaArray.find(
          (media): media is MediaImageFile => media?.type === "imageFile",
        )?.original
      : undefined
  const { alpha, blurhash } = onlyImageFile || {}

  return (
    <SquareThumbnail
      source={urls}
      size={size}
      showLock={showLock}
      alpha={alpha}
      blurhash={blurhash}
    />
  )
}
