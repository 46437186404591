import { RudderAnalytics } from "@rudderstack/analytics-js"

import {
  AnalyticsPlugin,
  Client,
  Event,
  Parameter,
  User,
} from "@treefort/lib/analytics"

import config from "../../../../config"
import {
  logRudderStackError,
  transformEvents,
  transformParameters,
  transformError,
} from "./utils"

const rudder = new RudderAnalytics()

class RudderStackAnalyticsPlugin implements AnalyticsPlugin {
  private client: Client | undefined
  private initialized: Promise<unknown>

  constructor() {
    this.initialized = new Promise((resolve, reject) => {
      try {
        rudder.load(
          config.RUDDERSTACK_WRITE_KEY,
          config.RUDDERSTACK_DATA_PLANE_URL,
          {
            configUrl: config.RUDDERSTACK_CONTROL_PLANE_URL,
            pluginsSDKBaseURL: config.RUDDERSTACK_PLUGINS_SDK_BASE_URL,
            storage: { type: "localStorage" },
            onLoaded: resolve,
          },
        )
      } catch (cause) {
        reject(cause)
      }
    }).catch(logRudderStackError("Failed to initialze the SDK"))
  }

  identifyUser = async (user: User) => {
    await this.initialized
    const userId = user[Parameter.UserId] ?? undefined
    if (!userId && rudder.getUserTraits()?.[Parameter.UserAuthenticated]) {
      rudder.reset()
    }
    const userParameters = transformParameters(user)
    const clientParameters = transformParameters(this.client)
    rudder.identify(userId || "", userParameters, clientParameters)
  }

  identifyClient = async (client: Client) => {
    this.client = client
  }

  getAnonymousId = async () => {
    await this.initialized
    const anonymousId = await rudder.getAnonymousId()
    if (!anonymousId) {
      logRudderStackError("Failed to obtain an anonymous ID")
    }
    return anonymousId
  }

  logEvent = async (event: Event): Promise<void> => {
    await this.initialized
    const clientParameters = transformParameters(this.client)
    await Promise.all(
      transformEvents([event]).map((event) => {
        switch (event.type) {
          case "screen":
            return rudder.page(event.title, event.parameters, clientParameters)
          case "track":
            return rudder.track(event.name, event.parameters, clientParameters)
        }
      }),
    )
  }

  logError = async (error: unknown): Promise<void> => {
    try {
      await this.initialized
      await rudder.track(
        "tf_error",
        transformError(error),
        transformParameters(this.client),
      )
    } catch (_) {
      // Make sure that failing to log errors doesn't spawn errors exponentially
    }
  }
}

export const rudderstackAnalyticsPlugin = new RudderStackAnalyticsPlugin()
