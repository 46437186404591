import { useEffect, useRef, useState } from "react"

import { useAuth } from "@treefort/lib/auth-provider"
import { isAxiosError } from "@treefort/lib/is-axios-error"

import { logError } from "../lib/logging"
import notifications from "../lib/notifications"
import { Store } from "../lib/store"
import { useOfflineState } from "./use-offline-state"

const notificationStore = new Store({ key: "notifications" })

const APP_HAS_BEEN_OPENED_KEY = "appHasBeenOpened"

export function useInitializeNotifications() {
  const auth = useAuth()
  const [appHasBeenOpened, setAppHasBeenOpened] = useState(false)
  const [offline] = useOfflineState()
  const initialized = useRef(false)

  // Check the store to see if the app has already been opened, and mark the app
  // as having been opened for the next time this component mounts.
  useEffect(() => {
    notificationStore.get(APP_HAS_BEEN_OPENED_KEY).then((value) => {
      setAppHasBeenOpened(Boolean(value))
      if (!value) {
        notificationStore.set(APP_HAS_BEEN_OPENED_KEY, true)
      }
    })
  }, [])

  // Post the notification token any time auth changes. This will ensure the
  // token is fresh and is associated with the correct user (or no user). We can
  // also take this opportunity to ask for permission to send notifications if
  // this is the not the first time the user has opened the app (we want to
  // build a little trust before bugging them).
  useEffect(() => {
    if (
      appHasBeenOpened &&
      auth.initialized &&
      !offline &&
      !initialized.current
    ) {
      initialized.current = true
      notifications
        .getToken({ requestPermission: appHasBeenOpened })
        // Don't worry about notification setup errors. These happen all the
        // time due to permissions issues. If the user isn't actively trying to
        // enable notifications then these errors are not worth bothering about.
        .catch(() => null)
        .then((token) => {
          if (token !== null) {
            return notifications.postToken({ token })
          }
        })
        .catch((cause) => {
          // Only log the error if we actually got a response from API. If we
          // can't reach the API at all then the AppInit component will log an
          // error.
          if (!isAxiosError(cause) || cause.response) {
            logError(
              new Error("[Notifications] Failed to post token", { cause }),
            )
          }
          initialized.current = false
        })
    }
  }, [auth, appHasBeenOpened, offline])
}
