import {
  schemaMigrations,
  createTable,
} from "@nozbe/watermelondb/Schema/migrations"

import { highlightTable } from "./migrations/02-highlight-table"

// When adding a new migration, follow the steps at
// https://watermelondb.dev/docs/Advanced/Migrations
//
// Brief summary of the above instructions:
// 1. Define the migration in a new file in the `migrations` directory
//    - name the file starting with the new schema number, e.g. 03-your-migration-name.ts
//    - Don't change schema.ts yet
// 2. Add the new migration here with an appropriate `toVersion` value
// 3. Verify the app shows an error "Migrations can't be newer than schema"
// 4. Make matching changes in schema.ts, importing the newly-defined migration there
// 5. Refresh the app, verify same "Migrations can't be newer" error is shown
// 6. Bump the `version` in schema to version + 1
// 7. Test migrations:
//   - install previous version of app, upgrade, ensure migrations run successfully
//   - verify fresh install of app works correctly
export default schemaMigrations({
  migrations: [
    {
      toVersion: 2,
      steps: [createTable(highlightTable)],
    },
  ],
})
