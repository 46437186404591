import { Platform } from "react-native"
import rnuc from "react-native-ultimate-config"

import { TREEFORT_API_LATEST_VERSION } from "@treefort/lib/api"
import ensureEnv from "@treefort/lib/ensure-env"

/**
 * This can be used to fetch the correct variable for the current platform if
 * the variable follows the format <NAME>_<PLATFORM>. This is especially helpful
 * because it keeps TypeScript from yelling at us when
 * react-native-ultimate-config only contains variables for the a single
 * platform (e.g. the `rnuc:web` npm script was run instead of `rnuc`).
 */
const getPlatformEnvVariable = (prefix: string): string | undefined => {
  const name = `${prefix}_${Platform.OS.toUpperCase()}`
  return name in rnuc ? rnuc[name as keyof typeof rnuc] : undefined
}

const NAVIGATION_PATH_PREFIX = Platform.select({
  native: getPlatformEnvVariable("PUBLIC_URL_SCHEME") + "://",
  web: Platform.OS === "web" ? location.origin + "/" : undefined,
})

const HTTP_PROTOCOL = rnuc.PUBLIC_ENV === "development" ? "http://" : "https://"

const env = ensureEnv({
  DEBUG: rnuc.PUBLIC_DEBUG,
  ENV: rnuc.PUBLIC_ENV,
  API_ENDPOINT: HTTP_PROTOCOL + rnuc.PUBLIC_API_DOMAIN_NAME + "/v1",
  APP_ID: rnuc.PUBLIC_APP_ID,
  APP_NAME: rnuc.PUBLIC_APP_NAME,
  AUTH_ENDPOINT: rnuc.PUBLIC_AUTH_ENDPOINT,
  AUTH_REDIRECT_URL: NAVIGATION_PATH_PREFIX + "auth/redirect",
  NAVIGATION_PATH_PREFIX,
  CLIENT_ID: getPlatformEnvVariable("PUBLIC_CLIENT_ID"),
  SPLASH_SCREEN_BACKGROUND_COLOR: rnuc.PUBLIC_SPLASH_SCREEN_BACKGROUND_COLOR,
  SPLASH_SCREEN_FOREGROUND_IMAGE_WIDTH:
    rnuc.PUBLIC_SPLASH_SCREEN_FOREGROUND_IMAGE_WIDTH,
  SPLASH_SCREEN_FOREGROUND_IMAGE_URL:
    rnuc.PUBLIC_SPLASH_SCREEN_FOREGROUND_IMAGE_URL,
  SPLASH_SCREEN_DISPLAY_MODE: rnuc.PUBLIC_SPLASH_SCREEN_DISPLAY_MODE,
  TENANT_ID: rnuc.PUBLIC_TENANT_ID,
  SENTRY_DSN: getPlatformEnvVariable("PUBLIC_SENTRY_DSN"),
  SENTRY_TRACES_SAMPLE_RATE: rnuc.PUBLIC_SENTRY_TRACES_SAMPLE_RATE,
  SENTRY_ENABLE_EVENTS: rnuc.PUBLIC_SENTRY_ENABLE_EVENTS,
  SENTRY_RELEASE: getPlatformEnvVariable("PUBLIC_SENTRY_RELEASE"),
  SENTRY_DIST: rnuc.PUBLIC_SENTRY_DIST,
  APP_MAJOR_VERSION: rnuc.PUBLIC_MAJOR_VERSION,
  APP_MINOR_VERSION: rnuc.PUBLIC_MINOR_VERSION,
  APP_PATCH_VERSION: rnuc.PUBLIC_PATCH_VERSION,
  DOMAIN_NAME: rnuc.PUBLIC_DOMAIN_NAME,
  CDN_ENDPOINT: rnuc.PUBLIC_CDN_ENDPOINT,
  RUDDERSTACK_CONTROL_PLANE_URL:
    HTTP_PROTOCOL + rnuc.PUBLIC_API_DOMAIN_NAME + "/rudderstack/control-plane",
  RUDDERSTACK_DATA_PLANE_URL:
    HTTP_PROTOCOL + rnuc.PUBLIC_API_DOMAIN_NAME + "/rudderstack/data-plane",
  RUDDERSTACK_PLUGINS_SDK_BASE_URL:
    HTTP_PROTOCOL + rnuc.PUBLIC_API_DOMAIN_NAME + "/rudderstack/plugins",
  RUDDERSTACK_WRITE_KEY: getPlatformEnvVariable("PUBLIC_RUDDERSTACK_WRITE_KEY"),
})

const config = {
  ...env,
  ANDROID_APP_ID: rnuc.PUBLIC_ANDROID_APP_ID ?? undefined,
  IOS_BUNDLE_ID: rnuc.PUBLIC_IOS_BUNDLE_ID ?? undefined,
  APPLE_ID: rnuc.PUBLIC_APPLE_ID ?? undefined,
  META_PIXEL_ID: rnuc.PUBLIC_META_PIXEL_ID ?? undefined,
  SINGULAR_SDK_KEY: rnuc.PUBLIC_SINGULAR_SDK_KEY ?? undefined,
  SINGULAR_SDK_SECRET: rnuc.PUBLIC_SINGULAR_SDK_SECRET ?? undefined,
  SINGULAR_APP_ID: getPlatformEnvVariable("PUBLIC_SINGULAR_APP_ID"),
  FIREBASE_CONFIG: getPlatformEnvVariable("PUBLIC_FIREBASE_CONFIG"),
  FIREBASE_MESSAGING_VAPID_PUBLIC_KEY:
    rnuc.PUBLIC_FIREBASE_MESSAGING_VAPID_PUBLIC_KEY ?? undefined,
  APP_ID: parseInt(env.APP_ID),
  SPLASH_SCREEN_FOREGROUND_IMAGE_WIDTH: parseInt(
    env.SPLASH_SCREEN_FOREGROUND_IMAGE_WIDTH,
  ),
  APP_VERSION: [
    env.APP_MAJOR_VERSION,
    env.APP_MINOR_VERSION,
    env.APP_PATCH_VERSION,
  ].join("."),
  SENTRY_TRACES_SAMPLE_RATE: parseFloat(env.SENTRY_TRACES_SAMPLE_RATE),
  SENTRY_ENABLE_EVENTS: env.SENTRY_ENABLE_EVENTS === "true",
  API_VERSION: TREEFORT_API_LATEST_VERSION,
  TREEFORT_SITE_URL: "https://treefortsystems.com",
  DOWNLOADS_SUPPORTED: Platform.OS !== "web",
  // This indicates if notifications are theoretically supported. If this is
  // false then everything related to notifications should be suppressed. If
  // this is true then notification functionality should be attempted, but we
  // may still find out that support is lacking in the current environment due
  // to reasons beyond our control.
  NOTIFICATIONS_SUPPORTED:
    Platform.OS === "web"
      ? Boolean(rnuc.PUBLIC_FIREBASE_MESSAGING_VAPID_PUBLIC_KEY)
      : // The tenant does not currently have the option to disable notifications
        // on native.
        true,
  PLAYBACK_RATES: [
    0.8, 0.9, 1, 1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9, 2, 2.1, 2.2, 2.3,
    2.4, 2.5,
  ],
  PLAYBACK_RATE_DECIMALS: 1,
}

export default config
