import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Linking, Platform } from "react-native"
import { useQuery } from "react-query"

import queryString from "query-string"

import { FirebaseMessagingTokenGetResponse } from "@treefort/api-spec"
import { DisplayableError } from "@treefort/lib/displayable-error"

import useAppState from "../hooks/use-app-state"
import { useIsFocused } from "../hooks/use-is-focused"
import useQueryKey from "../hooks/use-query-key"
import api from "../lib/api"
import confirm from "../lib/confirm"
import { logError } from "../lib/logging"
import notifications, {
  PermissionStatus,
  PermissionError,
} from "../lib/notifications"
import openUrl from "../lib/open-url"
import ActivityIndicator from "./activity-indicator"
import SettingsFieldset from "./settings-fieldset"
import SettingsRow from "./settings-row"
import Switch from "./switch"
import Text from "./text"
import Touchable from "./touchable"

/**
 * This returns a URL to instructions for the user to enable push notifications
 * in the browser they appear to be using.
 * TODO: Setup better instruction URLs: #865.
 */
async function getBrowserInstructionsUrl(): Promise<string | null> {
  const { default: parseUserAgent } = await import("ua-parser-js")
  const userAgent = parseUserAgent()
  const browser = userAgent.browser.name || ""
  if (/chrome|brave/i.test(browser)) {
    return "https://support.google.com/chrome/answer/3220216"
  } else if (/firefox/i.test(browser)) {
    return "https://support.mozilla.org/en-US/kb/push-notifications-firefox#w_upgraded-notifications"
  } else if (/edge/i.test(browser)) {
    return "https://techswift.org/2021/03/26/how-to-manage-notifications-in-microsoft-edge/"
  } else {
    return null
  }
}

export default function SettingsNotifications(): JSX.Element {
  const isFocused = useIsFocused()
  const appState = useAppState({ inactiveStateiOS: "background" })
  const [enabledOptimistic, setEnabledOptimistic] = useState<boolean>()
  const [browserInstructionsUrl, setBrowserInstructionsUrl] = useState<
    string | null
  >()
  const { t } = useTranslation()

  // Figure out what our current permission status is. Refetch this every 2000
  // milliseconds while the page is focused and in the foreground in case the
  // user grants/denies permissions.
  const permissionStatus = useQuery<PermissionStatus>(
    useQueryKey("settings-notification-permission-status"),
    async () => notifications.getPermissionStatus(),
    {
      enabled: isFocused && appState === "active",
      refetchInterval: 2000,
    },
  )

  // Ask the API if the user has notifications enabled or disabled.
  const enabled = useQuery<boolean>(
    useQueryKey("settings-notification-enabled"),
    async () => {
      const token = await notifications
        .getToken({ requestPermission: false })
        // Don't worry about notification setup errors. These happen all the
        // time due to permissions issues. If the user isn't actively trying to
        // enable notifications then these errors are not worth bothering about.
        .catch(() => null)
      if (token === null) {
        return false
      } else {
        const tokenQueryParam = queryString.stringify({ token })
        const res = await api.get<FirebaseMessagingTokenGetResponse>(
          `/integrations/firebase-messaging/tokens?${tokenQueryParam}`,
        )
        return res.data.optedOutAt === null
      }
    },
    {
      cacheTime: 0,
      enabled:
        permissionStatus.isSuccess &&
        permissionStatus.data !== "unavailable" &&
        permissionStatus.data !== "blocked",
    },
  )

  const openSettings = useCallback(async () => {
    // Web
    if (
      Platform.OS === "web" &&
      (await confirm({
        title: t("Permission required"),
        message: t(
          "Permission is required to receive push notifications. You can grant permission via your browser settings.",
        ),
        confirmLabel: t("Ok"),
        cancelLabel: t("Cancel"),
      })) &&
      browserInstructionsUrl
    ) {
      openUrl(browserInstructionsUrl, {
        openInApp: false,
        parentalGateway: false,
      })
    }

    // iOS and Android
    if (
      Platform.OS !== "web" &&
      (await confirm({
        title: t("Permission required"),
        message: t(
          "Permission is required to receive push notifications. You can grant permission from the app settings page.",
        ),
        confirmLabel: t("Open settings"),
        cancelLabel: t("Cancel"),
      }))
    ) {
      Linking.openSettings()
    }
  }, [browserInstructionsUrl, t])

  // This is called when the user has already granted us permissions at a system
  // level but wants to toggle notifications on/off.
  const toggleNotifications = useCallback(
    async (enabled: boolean) => {
      setEnabledOptimistic(enabled)
      try {
        // If the user wants to enable notifications, request permissions and post
        // the token to our server.
        if (enabled) {
          const token = await notifications.getToken({
            requestPermission: true,
          })

          await notifications.postToken({ token, optOut: false })
        }
        // If the user wants to disable notifications and they have a token then
        // opt it out of notifications, otherwise do nothing (they've either
        // blocked our access anyway or have never enabled notifications).
        else {
          const token = await notifications
            .getToken({ requestPermission: false })
            // Don't worry about notification setup errors when the user is
            // disabling them anyway.
            .catch(() => null)
          if (token) {
            await notifications.postToken({ token, optOut: true })
          }
        }
      } catch (error) {
        // One limitation of Android is that you can't know if notifications are
        // blocked (not just denied) until you try to enable them. If we get
        // this far (we've presented a push notification toggle and the user has
        // attempted to turn it on) and then realize notifications are blocked
        // then instruct the user to open the settings page where they can
        // enable notifications.
        if (error instanceof PermissionError && error.status === "blocked") {
          openSettings()
        } else {
          logError(
            new DisplayableError(
              enabled
                ? t(
                    "An error occurred enabling push notifications. Please try again.",
                  )
                : t(
                    "An error occurred disabling push notifications. Please try again.",
                  ),
              error,
            ),
          )
        }

        setEnabledOptimistic(!enabled)
      }
    },
    [t, openSettings],
  )

  // Figure out the best URL to point users to if they have notifications
  // disabled in their browser
  useEffect(() => {
    if (Platform.OS === "web" && permissionStatus?.data === "blocked") {
      getBrowserInstructionsUrl()
        .then((url) => setBrowserInstructionsUrl(url))
        .catch(logError)
    }
  }, [permissionStatus?.data])

  let control: JSX.Element = <ActivityIndicator size="medium" />
  switch (permissionStatus?.data) {
    case "unavailable":
      control = <Text textStyle="body">{t("Unavailable")}</Text>
      break
    case "blocked":
      control =
        browserInstructionsUrl || Platform.OS === "ios" ? (
          <Touchable feedback="opacity" onPress={openSettings}>
            <Text textStyle="body" color="accent">
              {t("Enable")}
            </Text>
          </Touchable>
        ) : (
          <Text textStyle="body">{t("Disabled")}</Text>
        )
      break
    case "denied":
      control = (
        <Switch value={enabledOptimistic} onValueChange={toggleNotifications} />
      )
      break
    case "granted":
    case "limited":
      if (!enabled.isFetching) {
        control = (
          <Switch
            value={enabledOptimistic ?? enabled.data}
            onValueChange={toggleNotifications}
          />
        )
      }
      break
  }

  return (
    <SettingsFieldset title={t("Notifications")}>
      <SettingsRow label={t("Receive push notifications")}>
        {control}
      </SettingsRow>
    </SettingsFieldset>
  )
}
