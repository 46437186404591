import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"

import { ErrorBoundary } from "@sentry/react"

import { useCancellable } from "@treefort/lib/cancellable"
import isPlainObject from "@treefort/lib/is-plain-object"
import { useWillUnmount } from "@treefort/lib/use-will-unmount"

import useAppState from "../../hooks/use-app-state"
import {
  getLocalManifest,
  getQueuedManifest,
  setQueuedManifest,
  getRemoteAppManifest,
  AppManifest,
} from "../../lib/app-manifest"
import { logError } from "../../lib/logging"
import { SplashScreen } from "../../navigation/screens/splash"
import { AppManifestData, AppManifestContext } from "./base"

/**
 * This component handles fetching and loading app manifests.
 *
 * There are a few terms that are helpful in understanding the logic here:
 * - *manifest*: a json object containing all the info about the app theme,
 *   navigation, and structure that is necessary to render the app
 * - *local manifest*: a locally cached copy of the manifest that is known to
 *   work (at least at some point in the past)
 * - *remote manifest*: a remote copy of the manifest served via a CDN (this is
 *   where new updates are pushed to)
 * - *queued manifest*: a locally cached copy of the remote manifest that we
 *   plan to load into the app but haven't had a chance to yet (we want to avoid
 *   disrupting users with manifest changes)
 *
 * The primary objectives of this component are to:
 * - Return a working manifest _as quickly as possible_. This means always
 *   prioritizing local or queued manifests if they exist.
 * - Keep the manifest up to date. This means queing manifests every time the
 *   app is loaded, refreshing the manifest every time the app moves into the
 *   background, etc.
 * - Allow the user to recover from a bad manifest. This means waiting to cache
 *   a manifest locally until we've verified that it renders without error, and
 *   allowing the app to fall back to a previous version of the manifest if
 *   things go south.
 */
export default function AppManifestProviderProduction(props: {
  children?: ReactNode
}): JSX.Element {
  const appState = useAppState()
  const willUnmount = useWillUnmount()

  const [data, setData] = useState<AppManifestData>({
    state: "loading",
  })

  // Set the manifest _unless_ we've already got the same manifest (or a newer
  // one) in memory. This this an unnecessary re-mount of the entire app for the
  // vast majority of calls where the manifest hasn't been updated. This
  // behavior can be overridden by setting the "forceRefresh" param to true.
  const setManifest = useCallback(
    (manifest: AppManifest | null | undefined | void, forceRefresh = false) => {
      if (manifest && !willUnmount.current) {
        setData((data) =>
          !forceRefresh &&
          data.state === "loaded" &&
          data.manifest.created >= manifest.created
            ? data
            : {
                state: "loaded",
                manifest,
                forceRefreshCount: forceRefresh
                  ? ((data.state === "loaded" && data.forceRefreshCount) || 0) +
                    1
                  : undefined,
              },
        )
      }
    },
    [willUnmount],
  )

  // Display a manifest error to the user. To be used as a last resort (when we
  // can't get our hands on a valid manifest).
  const displayFailedToFetchManifestError = useCallback(
    (error: unknown) => {
      logError(error)
      if (!willUnmount.current) {
        // Re-use the old data object if it's already in an error state. This avoids
        // infinite re-renders if we get stuck in an error state.
        setData((data) =>
          data.state === "error" ? data : { state: "error", error },
        )
      }
    },
    [willUnmount],
  )

  // A cancellable task that's run when the app mounts
  const [onMount, onMountRunning] = useCancellable(
    async function* () {
      try {
        // Start fetching the latest remote manifest immediately, but don't wait
        // for it just yet.
        const remoteRequest = getRemoteAppManifest()

        // Fetch the local and queued manifests
        const [local, queued] = await Promise.all([
          getLocalManifest(),
          getQueuedManifest(),
        ])

        yield

        // IF we have a queued manifest that's newer than the local one, use it
        // and queue the remote manifest in the background
        if (queued && (!local || queued.created > local.created)) {
          setManifest(queued)
          try {
            const remote = await remoteRequest
            yield
            setQueuedManifest(remote)
          } catch (error) {
            logError(error)
          }
        }
        // ELSE IF we have a local manifest, use it and queue the remote
        // manifest in the background
        else if (local) {
          setManifest(local)
          try {
            const remote = await remoteRequest
            yield
            setQueuedManifest(remote)
          } catch (error) {
            logError(error)
          }
        }
        // ELSE wait for the remote manifest to load and use that. If we fail to
        // load the manifest then show an error screen to the user.
        else {
          try {
            const remote = await remoteRequest
            yield
            setManifest(remote)
          } catch (error) {
            displayFailedToFetchManifestError(error)
          }
        }
      } catch (error) {
        logError(error)
      }
    },
    [displayFailedToFetchManifestError, setManifest],
  )

  // A cancellable task that's run when the app moves to the background. Fetches
  // the remote manifest and attempts to load it. If that fails we attempt to
  // load the queued manifest. If that fails we do nothing.
  const [onMoveToBackground, onMoveToBackgroundRunning] = useCancellable(
    async function* () {
      try {
        const remoteOrQueuedManifest = await getRemoteAppManifest().catch(
          getQueuedManifest,
        )
        yield
        setManifest(remoteOrQueuedManifest)
      } catch (error) {
        logError(error)
      }
    },
    [setManifest],
  )

  // When the user hits "Refresh" after a catastrophic meltdown:
  // - IF a local manifest exists and it's different than the manifest we
  //   already tried to render, use it
  // - ELSE fetch the remote manifest and use that. If the network call fails
  //   then show an error screen to the user.
  const onRetry = useCallback(async () => {
    const local = await getLocalManifest().catch(logError)
    if (
      local &&
      (data.state !== "loaded" ||
        !(isPlainObject(data.manifest) && "created" in data.manifest) ||
        data.manifest.created !== local.created)
    ) {
      setManifest(local, true)
    } else {
      try {
        const remote = await getRemoteAppManifest()
        setManifest(remote, true)
      } catch (error) {
        displayFailedToFetchManifestError(error)
      }
    }
  }, [data, setManifest, displayFailedToFetchManifestError])

  // This powers the "Refresh" button on the error screens
  const errorAction = useMemo(
    () => ({
      // NOTE: These strings are not translated because locale data is stored in
      // the manifest, but we don't have the manifest here...
      title: "Refresh",
      waitingTitle: "Refreshing...",
      callback: onRetry,
    }),
    [onRetry],
  )

  // Render a generic error screen with a retry button that will refresh the
  // manifest. This will be shown if a bad manifest is loaded and the app
  // crashes (as long as it's a JavaScript-land crash).
  const renderError = useCallback(
    () => (
      <SplashScreen
        message={{
          // NOTE: These strings are not translated because locale data is
          // stored in the manifest, but we don't have the manifest here...
          title: "App Error",
          description:
            "An error occurred. Please contact us if the issue persists.",
          action: errorAction,
        }}
      />
    ),
    [errorAction],
  )

  // Fetch the remote manifest and load it
  const refreshManifest = useCallback(async () => {
    const manifest = await getRemoteAppManifest()
    setManifest(manifest)
  }, [setManifest])

  const contextValue = useMemo(
    () =>
      data.state === "loaded"
        ? { manifest: data.manifest, refreshManifest }
        : null,
    [data, refreshManifest],
  )

  // Refresh the manifest when the app moves into the background. If we fail to
  // fetch the manifest in the background, log the error but allow the user to
  // continue with the existing manifest uninterrupted.
  useEffect(() => {
    if (appState === "background") {
      onMoveToBackground()
      return onMoveToBackgroundRunning.current?.cancel
    }
  }, [appState, onMoveToBackground, onMoveToBackgroundRunning])

  // Load the manifest when the app loads. First check for a local manifest,
  // otherwise attempt to fetch a remote manifest.
  useEffect(() => {
    onMount()
    return onMountRunning.current?.cancel
  }, [onMount, onMountRunning])

  return (
    <AppManifestContext.Provider
      value={contextValue}
      key={
        // Key by the state of our manifest data. This will ensure that the app
        // is completely remounted when moving from the "error" to the "loaded"
        // state, kicking the user back to the home page and allowing them to
        // temporarily recover from any errors that were triggered by a
        // particular page they navigated to.
        data.state + (data.state === "loaded" ? data.forceRefreshCount : "")
      }
    >
      <ErrorBoundary
        fallback={renderError}
        beforeCapture={(scope) => scope.setTag("boundary", "manifest")}
      >
        {data.state === "loaded" ? (
          props.children
        ) : data.state === "error" ? (
          <SplashScreen
            message={{
              title: "Network Error",
              description: "Please check your connection and try again.",
              action: errorAction,
            }}
          />
        ) : (
          <SplashScreen />
        )}
      </ErrorBoundary>
    </AppManifestContext.Provider>
  )
}
