import {
  setUserId,
  setUserProperties,
  logEvent,
  Analytics as FirebaseAnalytics,
  setAnalyticsCollectionEnabled,
  isSupported,
  initializeAnalytics,
  getGoogleAnalyticsClientId,
  setConsent,
} from "firebase/analytics"

import {
  Event,
  Parameter,
  AnalyticsPlugin,
  User,
  Client,
} from "@treefort/lib/analytics"

import config from "../../../../config"
import { addConsentListener, checkConsent } from "../../../consent"
import { getFirebaseApp } from "../../../firebase/index.web"
import { transformEvents, transformParameters, transformValue } from "./utils"

class FirebaseWebAnalyticsPlugin implements AnalyticsPlugin {
  private analytics: Promise<FirebaseAnalytics | null>

  constructor() {
    this.analytics = Promise.all([
      isSupported(),
      checkConsent("advertisingCookies"),
      checkConsent("analyticsCookies"),
    ]).then(([supported, advertisingCookies, analyticsCookies]) => {
      const app = supported && getFirebaseApp()

      setConsent({
        ad_storage: advertisingCookies,
        analytics_storage: analyticsCookies,
      })

      return app
        ? initializeAnalytics(app, { config: { send_page_view: false } })
        : null
    })

    addConsentListener("advertisingCookies", (ad_storage) =>
      setConsent({ ad_storage }),
    )

    addConsentListener("analyticsCookies", (analytics_storage) =>
      setConsent({ analytics_storage }),
    )
  }

  getGoogleAnalyticsClient = async () => {
    const analytics = await this.analytics
    const measurementId =
      config.FIREBASE_CONFIG &&
      JSON.parse(atob(config.FIREBASE_CONFIG)).measurementId
    const id =
      measurementId && analytics
        ? await getGoogleAnalyticsClientId(analytics)
        : null
    return id && measurementId ? { type: "gtag", id, measurementId } : null
  }

  setEnabled = async (enabled: boolean) => {
    const analytics = await this.analytics
    if (analytics) {
      setAnalyticsCollectionEnabled(analytics, enabled)
    }
  }

  identifyUser = async (user: User) => {
    const analytics = await this.analytics
    if (!analytics) return

    await setUserId(
      analytics,
      // HACK: This function is typed incorrectly. It's valid (recommended
      // even) to pass null to clear out the user id, but the types only allow
      // for a string. See:
      // https://github.com/firebase/firebase-js-sdk/pull/6370
      transformValue(user[Parameter.UserId]) as string,
    )
    await setUserProperties(analytics, transformParameters(user))
  }

  identifyClient = async (client: Client) => {
    const analytics = await this.analytics
    if (!analytics) return
    await setUserProperties(analytics, transformParameters(client))
  }

  logEvent = async (event: Event): Promise<void> => {
    const analytics = await this.analytics
    if (!analytics) return
    await Promise.all(
      transformEvents([event]).map((event) =>
        logEvent(analytics, event.name, event.parameters),
      ),
    )
  }
}

export const firebaseAnalyticsPlugin = new FirebaseWebAnalyticsPlugin()
