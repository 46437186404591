import { useCallback } from "react"
import { Platform, GestureResponderEvent } from "react-native"

import { stringify } from "query-string"

import { AppLink } from "@treefort/api-spec"

import { getAppLinkFromUrl } from "../lib/app-link"
import openUrl from "../lib/open-url"
import { isParent } from "../lib/parental-gateway"
import {
  SEARCH_TAB,
  getPathFromAppLink,
  getPathFromTab,
} from "../navigation/routes"
import useAppManifest from "./use-app-manifest"
import { useAppTab } from "./use-app-tab"
import { useMenu } from "./use-menu"
import { useNavigate } from "./use-navigate"
import { useParentalGateway } from "./use-parental-gateway"
import { useSearch } from "./use-search"

type PressEvent =
  | React.MouseEvent<HTMLAnchorElement, MouseEvent>
  | GestureResponderEvent
  | null

const noop = () => {}

/**
 * Returns helpers for working with app links in the current context.
 */
export function useAppLinkHelpers() {
  const tab = useAppTab()
  const manifest = useAppManifest()
  const parentalGateway = useParentalGateway()
  const menu = useMenu()
  const search = useSearch()
  const navigate = useNavigate()

  const open = useCallback(
    ({
      appLink,
      params,
    }: {
      appLink: AppLink
      params?: Record<string, string>
    }) => {
      // Convert external "url" links that point back to the app to "path" links
      // so that they are handled like internal navigation instead of opening a
      // second instance of the app
      const link =
        appLink.type === "url" ? getAppLinkFromUrl(appLink.url) : appLink

      // Our "url" link type needs some special attention. On the web we eschew
      // a click/press handler to get the benefits of vanilla html links. We
      // also set rel="noopener noreferrer" for security and target="_blank" to
      // ensure that the app can keep playing media in the background when the
      // user visits the link.
      if (link.type === "url") {
        const url = new URL(link.url)
        if (params) {
          Object.entries(params).forEach(([name, value]) =>
            url.searchParams.set(name, value),
          )
        }
        openUrl(url.toString(), { parentalGateway })
      } else {
        const path = getPathFromAppLink(link, tab, manifest)

        // If the path opens the menu things are a bit more complicated and
        // need to be delegated to the menu helper, otherwise we can call
        // `navigate` directly.
        if (path.startsWith("/menu")) {
          menu.open(path.replace(/^\/menu/, ""))
        } else if (path === getPathFromTab(SEARCH_TAB)) {
          search.open()
        } else {
          navigate(path, params)
        }
      }
    },
    [tab, manifest, parentalGateway, menu, search, navigate],
  )

  const getProps = useCallback(
    ({
      appLink,
      label,
      onPress: onPressProp = noop,
      params,
    }: {
      appLink: AppLink
      label: string
      onPress?: (event?: PressEvent) => unknown
      params?: Record<string, string>
    }) => {
      // Convert external "url" links that point back to the app to "path" links
      // so that they are handled like internal navigation instead of opening a
      // second instance of the app
      const link =
        appLink.type === "url" ? getAppLinkFromUrl(appLink.url) : appLink

      // Our "url" link type needs some special attention. On the web we eschew
      // a click/press handler to get the benefits of vanilla html links. We
      // also set rel="noopener noreferrer" for security and target="_blank" to
      // ensure that the app can keep playing media in the background when the
      // user visits the link.
      if (link.type === "url") {
        const href = link.url
        const webOnlyProps =
          Platform.OS === "web"
            ? { hrefAttrs: { rel: "noopener noreferrer", target: "_blank" } }
            : {}
        const onPress =
          Platform.OS === "web" && parentalGateway
            ? async (event?: PressEvent) => {
                if (await isParent()) {
                  onPressProp(event)
                } else {
                  event?.preventDefault()
                }
              }
            : Platform.OS === "web"
              ? onPressProp
              : (event?: PressEvent) => {
                  open({ appLink: link, params })
                  onPressProp(event)
                }
        return {
          href,
          onPress,
          role: "link" as const,
          "aria-label": label,
          ...webOnlyProps,
        }
      } else {
        const path = getPathFromAppLink(link, tab, manifest)
        return {
          onPress: (event?: PressEvent) => {
            // Prevent the default link behavior on web (we'll handle it with
            // HTML5 history)
            if (Platform.OS === "web") {
              event?.preventDefault()
            }

            open({ appLink: link, params })
            onPressProp(event)
          },
          href:
            params && Object.values(params).length
              ? `${path}?${stringify(params)}`
              : path,
          role: "link" as const,
          "aria-label": label,
        }
      }
    },
    [open, tab, manifest, parentalGateway],
  )

  return { open, getProps }
}
