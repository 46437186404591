import { field, text } from "@nozbe/watermelondb/decorators"

import { DatabaseFieldsOf } from "../stores/types"
import { SourceAwareModel } from "./source-aware-model"

export type HighlightData = DatabaseFieldsOf<Highlight> & { id: string }

export class Highlight extends SourceAwareModel {
  static table = "Highlight"

  static toPlainObject = (highlight: Highlight) => ({
    id: highlight.id,
    userId: highlight.userId,
    profileId: highlight.profileId,
    contentId: highlight.contentId,
    selectedText: highlight.selectedText,
    notes: highlight.notes,
    colorPresetId: highlight.colorPresetId,
    startCfi: highlight.startCfi,
    endCfi: highlight.endCfi,
    sectionId: highlight.sectionId,
    progressPercent: highlight.progressPercent,
    createdAtDate: highlight.createdAtDate,
    updatedAtDate: highlight.updatedAtDate,
  })

  @field("userId")
  userId!: string
  @field("profileId")
  profileId!: string | null
  @field("contentId")
  contentId!: number
  @field("selectedText")
  selectedText!: string
  @text("notes")
  notes!: string | null
  @field("colorPresetId")
  colorPresetId!: number
  @field("startCfi")
  startCfi!: string
  @field("endCfi")
  endCfi!: string
  @field("sectionId")
  sectionId?: string
  @field("progressPercent")
  progressPercent?: number
}
