import React, { useCallback } from "react"
import styled from "styled-components/native"
import icons from "@treefort/tokens/app/icons"
import RatePicker, { playbackRateToString } from "./audio-player-rate-picker"
import Touchable from "./touchable"
import Text from "./text"
import IconButton from "./icon-button"
import audioPlayer, {
  SKIP_BACKWARD_INTERVAL,
  SKIP_FORWARD_INTERVAL,
} from "../lib/audio-player"
import { useTracks, usePlaybackRate } from "../hooks/audio"
import { useBooleanState } from "../hooks/use-boolean-state"
import AudioPlayerPlayButton from "./audio-player-play-button"
import Select from "./select"
import { useTranslation } from "react-i18next"

const RATE_BUTTON_HIT_SLOP = { top: 8, right: 8, bottom: 8, left: 8 }

const Container = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const OuterControlContainer = styled.View`
  width: ${(props) => props.theme.minTapTarget}px;
  align-items: center;
  justify-content: center;
`

export default function AudioPlayerControls({
  compact,
}: {
  compact?: boolean
}): JSX.Element {
  const [trackPickerOpen, openTrackPicker, closeTrackPicker] =
    useBooleanState(false)
  const [ratePickerOpen, openRatePicker, closeRatePicker] =
    useBooleanState(false)
  const tracks = useTracks()
  const playbackRate = usePlaybackRate()
  const skipBackward = useCallback(
    () => audioPlayer.skipInterval(-SKIP_BACKWARD_INTERVAL),
    [],
  )
  const skipForward = useCallback(
    () => audioPlayer.skipInterval(SKIP_FORWARD_INTERVAL),
    [],
  )

  const onPickTrack = useCallback(
    async (index: number) => {
      closeTrackPicker()
      await audioPlayer.skipToTrack(index, { autoPlay: true })
    },
    [closeTrackPicker],
  )

  const includeTrackPicker = tracks.length > 1

  const { t } = useTranslation()

  return (
    <Container>
      <OuterControlContainer>
        <Touchable
          onPress={openRatePicker}
          feedback="opacity"
          hitSlop={RATE_BUTTON_HIT_SLOP}
        >
          <Text textStyle="headingSmall">
            {playbackRateToString(playbackRate)}
          </Text>
        </Touchable>
      </OuterControlContainer>
      <IconButton
        onPress={skipBackward}
        iconSize={compact ? "large" : "xlarge"}
        source={SKIP_BACKWARD_INTERVAL === 10000 ? icons.back10 : icons.back30}
        // label={`Skip backward ${SKIP_BACKWARD_INTERVAL / 1000} seconds`}
        label={t("Skip backward {{number}} seconds", {
          number: SKIP_BACKWARD_INTERVAL / 1000,
        })}
      />
      <AudioPlayerPlayButton iconSize={compact ? "large" : "jumbo"} />
      <IconButton
        onPress={skipForward}
        iconSize={compact ? "large" : "xlarge"}
        source={
          SKIP_FORWARD_INTERVAL === 10000 ? icons.forward10 : icons.forward30
        }
        label={t("Skip forward {{number}} seconds", {
          number: SKIP_FORWARD_INTERVAL / 1000,
        })}
      />
      <OuterControlContainer>
        {includeTrackPicker ? (
          <IconButton
            onPress={openTrackPicker}
            source={icons.menuStaggered}
            iconSize="medium"
            label={t("Open track picker")}
          />
        ) : null}
      </OuterControlContainer>
      {includeTrackPicker ? (
        <Select
          label={tracks[0]?.album || "Table of Contents"}
          open={trackPickerOpen}
          onClose={closeTrackPicker}
          value={audioPlayer.getIndex()}
          onChange={onPickTrack}
          options={tracks.map((track, index) => ({
            label: track.title,
            value: index,
          }))}
        />
      ) : null}
      <RatePicker open={ratePickerOpen} onRequestClose={closeRatePicker} />
    </Container>
  )
}
