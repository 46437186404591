import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet } from "react-native"
import type { EdgeInsets } from "react-native-safe-area-context"

import styled from "styled-components/native"

import { getOptimizedImageSource } from "@treefort/lib/get-optimized-image-source"
import icons from "@treefort/tokens/app/icons"

import { useTrack } from "../hooks/audio"
import audioPlayer, { SKIP_BACKWARD_INTERVAL } from "../lib/audio-player"
import AudioPlayerPlayButton from "./audio-player-play-button"
import IconButton from "./icon-button"
import { ImageWithFade } from "./image-with-fade"
import Row from "./row"
import Spacer from "./spacer"
import Text from "./text"
import { useTokens } from "./tokens-provider"
import Touchable from "./touchable"

const { backgroundContainerStyle } = StyleSheet.create({
  backgroundContainerStyle: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
})

const Container = styled.View<{ safeAreaInsets: EdgeInsets }>`
  width: 100%;
  background: ${({ theme }) => theme.colors.background.primary};
  border-color: ${({ theme }) => theme.colors.border.primary};
  border-top-width: 1px;
  height: ${({ theme, safeAreaInsets }) =>
    theme.audioPlayerInline.height + safeAreaInsets.bottom}px;
  padding-left: ${(props) =>
    props.safeAreaInsets.left + props.theme.audioPlayerInline.padding}px;
  padding-right: ${(props) =>
    props.safeAreaInsets.right + props.theme.audioPlayerInline.padding}px;
  padding-bottom: ${(props) => props.safeAreaInsets.bottom}px;
`

// This touchable triggers the expand/collapse action. It's absolutely
// positioned so that it can extend behind the other touchables (play/skip) and
// display ripple or highlight feedback accross the entire audio player. It does
// _not_ wrap the other touchables (nesting touchables causes all sorts of
// problems).
const BackgroundTouchable = styled(Touchable)<{ safeAreaInsets: EdgeInsets }>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding-left: ${(props) =>
    props.theme.audioPlayerInline.padding + props.safeAreaInsets.left}px;
  padding-right: ${(props) =>
    props.safeAreaInsets.right +
    props.theme.audioPlayerInline.padding +
    props.theme.minTapTarget * 2}px;
`

const Metadata = styled.View`
  width: 100%;
  height: 100%;
  flex-direction: row;
  align-items: center;
`

const Artwork = styled(ImageWithFade)<{ size: number }>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: ${({ theme }) => theme.borderRadius.rounded}px;
  background-color: ${(props) => props.theme.colors.loading.image};
`

const Title = styled(Text)`
  flex: 1;
`

export default function AudioPlayerInline({
  onPress,
  safeAreaInsets,
}: {
  onPress: () => void
  safeAreaInsets: EdgeInsets
}): JSX.Element {
  const track = useTrack()
  const { tokens } = useTokens()
  const skipBackward = useCallback(
    () => audioPlayer.skipInterval(-SKIP_BACKWARD_INTERVAL),
    [],
  )

  const { t } = useTranslation()

  return (
    <Container safeAreaInsets={safeAreaInsets}>
      <Row
        alignItems="center"
        justifyContent="flex-end"
        height={tokens.audioPlayerInline.height}
      >
        <BackgroundTouchable
          safeAreaInsets={safeAreaInsets}
          onPressIn={onPress}
          feedback="ripple-or-highlight"
          containerStyle={backgroundContainerStyle}
        >
          <Metadata>
            <Artwork
              size={
                tokens.audioPlayerInline.height -
                2 * tokens.audioPlayerInline.padding
              }
              source={getOptimizedImageSource(
                { uri: track?.artwork },
                // Use the same source image size as the fullscreen player so that
                // we only have to load the image once for both players
                tokens.audioPlayerFullscreen.maxWidth,
              )}
              resizeMode="cover"
            />
            <Spacer size="medium" horizontal={true} />
            <Title textStyle="strong" numberOfLines={1}>
              {track?.title}
            </Title>
          </Metadata>
        </BackgroundTouchable>
        <IconButton
          onPress={skipBackward}
          source={
            SKIP_BACKWARD_INTERVAL === 10000 ? icons.back10 : icons.back30
          }
          label={t("Skip backward {{number}} seconds", {
            number: SKIP_BACKWARD_INTERVAL / 1000,
          })}
          iconSize="large"
        />
        <AudioPlayerPlayButton iconSize="large" />
      </Row>
    </Container>
  )
}
