import React from "react"

import { AppModule, ContentType } from "@treefort/api-spec"
import { Result } from "@treefort/lib/result"

import { useRoute } from "../../hooks/use-route"
import { UpdateScreen } from "../screens/update"
import { ModuleLayout } from "./module"
import { ListViewModule } from "./module/list-view-module"
import { AudiobookListViewModule } from "./module/list-view-modules/audiobook"
import { EbookListViewModule } from "./module/list-view-modules/ebook"
import { PodcastEpisodeListViewModule } from "./module/list-view-modules/podcast-episode"
import { VideoListViewModule } from "./module/list-view-modules/video"
import { VideoSeriesListViewModule } from "./module/list-view-modules/video-series"
import { WebEmbedListViewModule } from "./module/list-view-modules/web-embed"

type SupportedContentType = Exclude<ContentType, "podcast">

/**
 * Create an array of list view module(s) from a content ID. The array will be
 * empty if there is no list view module that corresponds to the provided
 * content type.
 */
function contentToListViewModule({
  contentId,
  contentType,
}: {
  contentId: number
  contentType: SupportedContentType
}): ListViewModule<AppModule, unknown>[] {
  const contentModule: AppModule = {
    id: 0,
    type: contentType,
    config: { contentId },
    conditions: {},
  }
  switch (contentModule.type) {
    case "book":
      return [new AudiobookListViewModule(contentModule)]
    case "ebook":
      return [new EbookListViewModule(contentModule)]
    case "video":
      return [new VideoListViewModule(contentModule)]
    case "videoSeries":
      return [new VideoSeriesListViewModule(contentModule)]
    case "podcastEpisode":
      return [new PodcastEpisodeListViewModule(contentModule)]
    case "webEmbed":
      return [new WebEmbedListViewModule(contentModule)]
    default:
      return []
  }
}

/**
 * Basic layout for content modules including custom modules below the content.
 * Does not support virtualized content modules.
 */
export function ContentLayout({
  contentType,
}: {
  contentType: SupportedContentType
}): JSX.Element {
  const { params } = useRoute<"contentId">()

  if (!params.contentId) {
    throw new Error(`Visited the ${contentType} screen without a content id!`)
  }

  const contentId = parseInt(params.contentId)

  const contentModule = contentToListViewModule({ contentId, contentType })

  return contentModule.length ? (
    <ModuleLayout
      pageKey={`${contentType}-page-${contentId}`}
      listViewModules={Result.success(contentModule)}
    />
  ) : (
    <UpdateScreen />
  )
}
