import React, { ReactNode } from "react"
import { View, ViewStyle, StyleProp, Role, StyleSheet } from "react-native"

import styled from "styled-components/native"

import { MediaImageFile, MediaUrl } from "@treefort/api-spec"
import tokens from "@treefort/tokens/app"
import icons from "@treefort/tokens/app/icons"

import { i18nKey } from "../types/i18next"
import IconButton from "./icon-button"
import Spacer from "./spacer"
import { SquareMediaThumbnail } from "./thumbnail"
import ThumbnailWithTitle from "./thumbnail-with-title"
import Touchable from "./touchable"

const actionButtonSize = tokens.minTapTarget

const { actionButtonContainerStyle, actionButtonInnerStyle } =
  StyleSheet.create({
    actionButtonContainerStyle: {
      position: "absolute",
      right: 0,
      top: "50%",
      transform: [{ translateY: -actionButtonSize / 2 }],
      height: actionButtonSize,
      width: actionButtonSize,
    },
    actionButtonInnerStyle: {
      position: "relative",
      top: 0,
      right: 0,
      transform: [],
    },
  })

export interface ThumbnailListItemProps {
  children?: React.ReactNode
  showActionButton?: boolean
  role?: Role
  data?: {
    artworkMedia?:
      | MediaImageFile
      | MediaUrl
      | Array<MediaImageFile | MediaUrl | undefined>
    showLock?: boolean
    title: string
    subtitle?: string
    displayTypeStringKey?: i18nKey
    onPress?: () => void
    actionButton?: {
      label: string
      onPress: () => void
    }
  }
}

const Container = styled.View`
  position: relative;
`

const ContentWrapper = styled.View`
  flex: 1;
`

const ActionButton = styled(IconButton)`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-${actionButtonSize / 2}px);
  width: ${actionButtonSize}px;
  height: ${actionButtonSize}px;
`

const ArtworkAndContentWrapper = styled(ConditionalTouchable)`
  flex-direction: row;
  align-items: center;
  padding-left: ${(props) => props.theme.spacing.pagePaddingHorizontal}px;
  padding-vertical: ${(props) =>
    props.theme.thumbnailListItem.paddingVertical}px;
  margin-right: ${actionButtonSize}px;
`

/**
 * This is so that if no actionButton and onPress props are passed to
 * ThumbnailListItem than it can be wrapped in our Touchable without us having
 * to worry about nesting any touchables (although I'm not sure if that's
 * actually a problem if the nested touchables don't have onPress handlers).
 */
function ConditionalTouchable({
  children,
  onPress,
  style,
}: {
  children: ReactNode
  onPress?: () => void
  style?: StyleProp<ViewStyle>
}): JSX.Element {
  if (onPress) {
    return (
      <Touchable onPress={onPress} feedback="ripple-or-highlight">
        <View style={style}>{children}</View>
      </Touchable>
    )
  } else {
    return <View style={style}>{children}</View>
  }
}

/**
 * Renders a list item with a thumbnail on the left and metadata to the right
 * with an optional menu button. Can take arbitrary children which will be
 * rendered as the last row of metadata.
 */
export default function ThumbnailListItem({
  data,
  showActionButton,
  role,
  children = null,
}: ThumbnailListItemProps): JSX.Element {
  return (
    <Container role={data ? role : undefined} aria-label={data?.title}>
      <ArtworkAndContentWrapper onPress={data?.onPress}>
        <SquareMediaThumbnail
          media={data?.artworkMedia}
          size={tokens.thumbnailListItem.artwork.height}
          showLock={data?.showLock}
        />
        <Spacer
          horizontal
          size={tokens.thumbnailListItem.artwork.marginRight}
        />
        <ContentWrapper>
          <ThumbnailWithTitle
            titleNumberOfLines={children ? 1 : 2}
            data={
              data
                ? {
                    title: data.title,
                    subtitle: data.subtitle,
                    displayTypeStringKey: data.displayTypeStringKey,
                  }
                : undefined
            }
          />
          {children}
        </ContentWrapper>
      </ArtworkAndContentWrapper>
      {showActionButton && (
        <ActionButton
          source={icons.meatballs}
          label={data?.actionButton?.label || "menu"}
          onPress={data?.actionButton?.onPress}
          containerStyle={actionButtonContainerStyle}
          innerStyle={actionButtonInnerStyle}
          {...(!data ? { state: "inactive" } : null)}
        />
      )}
    </Container>
  )
}
