import { Platform } from "react-native"

import Authenticator, { Event } from "@treefort/lib/authenticator"

import config from "../config"
import addDeepLinkListener from "./add-deep-link-listener"
import store from "./auth-store"
import generatePkceChallenge from "./generate-pkce-challenge"
import { setCurrentUserId } from "./logging"
import openUrl from "./open-url"

const redirectTo = (url: string) =>
  openUrl(url, {
    openInApp: true,
    parentalGateway: false,
  })

const authenticator = new Authenticator({
  // Cookies are much more secure on the web
  type: Platform.OS === "web" ? "cookie" : "bearer",
  authorizeEndpoint: `${config.AUTH_ENDPOINT}/oauth2/authorize`,
  registerEndpoint: `${config.AUTH_ENDPOINT}/oauth2/register`,
  logoutEndpoint: `${config.AUTH_ENDPOINT}/oauth2/logout`,
  tokenEndpoint: `${config.API_ENDPOINT}/auth/token`,
  revokeTokenEndpoint: `${config.API_ENDPOINT}/auth/token`,
  redirectUrl: config.AUTH_REDIRECT_URL,
  clientId: config.CLIENT_ID,
  tenantId: config.TENANT_ID,
  store,
  generatePkceChallenge,
  redirectTo,
  addDeepLinkListener,
})

// When the user changes, update Sentry context
authenticator.on(Event.User, (user) => setCurrentUserId(user?.id ?? null))

export default authenticator
